import React from "react";
import {
    Container,
    Box,
    Grid,
    Typography,
    Button,
    SvgIcon
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { COLORS } from "core";

const Contact = props => {
    return(
        <>

        <Container maxWidth="md" sx={{ marginTop: '120px'}}>
            <Typography variant="h2" color="secondary">
                Contact Me
            </Typography>
            <Typography variant="h4" color="primary">
                <Box mt={5} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',
                    transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.1, 1.1, 1) translate(4%, 0%)"}}}
                >
                    <a href="mailto:ksondjaja@gmail.com">
                        <SvgIcon component={EmailIcon} sx={{ marginRight: '15px', fontSize: '20px', color: COLORS.LAVENDER }} />
                        Email
                    </a>
                </Box>

                <Box mt={3} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',
                    transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.1, 1.1, 1) translate(4%, 0%)"}}}
                >
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ksondjaja/">
                        <SvgIcon component={LinkedInIcon} sx={{ marginRight: '15px', fontSize: '20px', color: COLORS.LAVENDER }} />
                        LinkedIn
                    </a>
                </Box>

                <Box mt={3} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap',
                    transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.1, 1.1, 1) translate(4%, 0%)"}}}
                >
                    <a target="_blank" rel="noreferrer" href="https://github.com/ksondjaja/">
                        <SvgIcon component={GitHubIcon} sx={{ marginRight: '15px', fontSize: '20px', color: COLORS.LAVENDER }} />
                        GitHub
                    </a>
                </Box>

            </Typography>

            <Box mt={10} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    href="/work"
                    display="flex" alignItems="center"
                    sx={{ padding:'15px 30px', marginLeft: '0px', borderRadius: '10px', border: `2px solid ${COLORS.LAVENDER}`,
                    boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                    '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)' } }}>
                        <Typography variant="h4" color="secondary">
                            See my work
                        </Typography>
                </Button>
            </Box>
        </Container>

        <Box width="100%" mt={16} py={6} sx={{backgroundColor: COLORS.DARK_GRAY }}>
            <Container>
                <Grid container>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <Typography variant="body1" color="primary">
                            <b>© 2023 Lala Sondjaja.</b> I built this website with React and MUI.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>

        </>
      );
};

export default Contact;
