import { styled } from "@mui/system";
import { COLORS } from "core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionSummaryWrapper = styled(AccordionSummary)({
  width: "100% !important",
  border: "none !important",
  fontSize: 18,
  fontWeight: "700",
  padding: "0 12px !important",
  ".MuiAccordionSummary-expandIconWrapper": { order: -1 } 
  // ".Mui-expanded & > .MuiAccordionSummary-expandIconWrapper": {
  //   transform: "rotate(90deg)",
  // },
  // color: ({ color }) => (color ? `${color}` : COLORS.LAVENDER),
//   padding: "0 16px !important",
//   a: {
//     color: COLORS.GREY,
//     "&:active, &:visited": {
//       color: COLORS.GREY,
//     },
//   },
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  backgroundColor: `${COLORS.DARK_GRAY} !important`,
  color: COLORS.OFF_WHITE
});

export const StyledAccordion = (props) => (
  <Accordion
    disableGutters
    square
    elevation={0}
    {...props}
    sx={{ margin: "0", padding:"0", border: "none" }}
  />
);

export const StyledAccordionSummary = (props) => {
  return (
    <AccordionSummaryWrapper
      expandIcon={<ExpandMoreIcon sx={{ color: (props.color? props.color : COLORS.LAVENDER) }} />}
      sx={{ backgroundColor: COLORS.DARK_GRAY, color: (props.color? props.color : COLORS.LAVENDER)}}
      {...props}
    />
  );
};