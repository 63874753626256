import React from "react";
import { 
    COLORS } from "core";
import {
    Grid,
    Typography,
    Button,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';

const Construction = props => {

    const { setStep } = props;

    return(
        <Grid container spacing={3} mt={8} mb={20} display="flex">
            <Grid item xs={12} display='flex' flexDirection="column" alignItems='center' justifyContent='center' textAlign="center">     
                <Typography variant="h3" color={props.color}>
                    <p>
                        Under Construction
                    </p>
                </Typography>
                <Typography variant="body2" color="primary">
                    <p>
                        I am still working on this project page, but please see overview and key screens below.
                    </p>
                </Typography>
            </Grid>
        </Grid>
    );
}


export default Construction;