import { COLORS } from "core";
import { createTheme } from '@mui/material/styles';
import WebFont from 'webfontloader';

WebFont.load({
    google: {
      families: ['Quicksand:500,700', 'sans-serif']
    }
});
  

const theme = createTheme({
  palette: {
    primary: {
        main: COLORS.OFF_WHITE,
        contrastText: COLORS.BLACK
    },
    secondary:{
        main: COLORS.LAVENDER,
        contrastText: COLORS.BLACK
    },
    background:{
        default: COLORS.BLACK,
        paper: COLORS.DARK_GRAY,
    },
  },
});

theme.typography.h1 = {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '58px',
    fontWeight: '700',
    lineHeight: '77px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '48px',
      lineHeight: '70px'
    },
};

theme.typography.h2 = {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '42px',
    fontWeight: '700',
    lineHeight: '62px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '38px',
      lineHeight: '52px'
    },
};

theme.typography.h3 = {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '38px',
    fontWeight: '700',
    lineHeight: '50px',
    a:{
      color: COLORS.OFF_WHITE,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
      lineHeight: '40px'
    },
};

theme.typography.h4 = {
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '26px',
  fontWeight: '700',
  lineHeight: '40px',
  a:{
    color: COLORS.OFF_WHITE,
    textDecoration: 'none',
    '&:hover':{
      color: COLORS.LAVENDER
    }
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '36px'
  },
};

theme.typography.h5 = {
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '22px',
  fontWeight: '700',
  lineHeight: '32px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '30px'
  },
};

theme.typography.h6 = {
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '26px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '24px'
  },
};

theme.typography.body1 = {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '26px',
    color: COLORS.BODY,
    b:{
      color: COLORS.LAVENDER,
      fontWeight: '700',
    },
    a: {
      color: COLORS.LAVENDER,
      fontWeight: '700',
      transition: "transform 0.15s ease-in-out",
      "&:hover":{
        transform: "scale3d(1.2, 1.2, 1)"
      },
      "&:active":{
          transform: "scale3d(1.2, 1.2, 1)"
      }
    },
    ul: {
      marginLeft: '-15px',
      li: {
        marginBottom: '20px'
      }
    },
    ol: {
      marginLeft: '-15px',
      li: {
        marginBottom: '20px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px'
    },
};

theme.typography.body2 = {
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '22px',
  fontWeight: '500',
  lineHeight: '32px',
  a: {
    color: COLORS.LAVENDER,
    fontWeight: '700'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '30px'
  },
};

theme.typography.subtitle1 = {
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '500',
  [(theme.breakpoints.down('sm'))]: {
    fontSize: '14px',
    lineHeight: '22px',
  }
}

theme.typography.button = {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: '22px',
    fontWeight: '700',
    margin: '0px 20px 0px 20px',
    [theme.breakpoints.down('md')]: {
        fontSize: '18px',
        margin: '0px 10px 0px 10px'
      },
}

theme.typography.caption = {
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '700',
  [theme.breakpoints.between('sm', 'md')]: {
    fontSize: '12px',
    lineHeight: '18px',
  },
  [(theme.breakpoints.down('500'))]: {
    fontSize: '12px',
    lineHeight: '18px',
  }
}

theme.typography.overline = {
  fontFamily: "'Quicksand', sans-serif",
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '700',
  [(theme.breakpoints.down('952'))]: {
    fontSize: '12px',
    lineHeight: '18px',
  }
}

export default theme;