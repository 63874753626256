import styled from "styled-components";
import Profile from "./profile.jpg";

import ShopifyStoreThumbnail from "./shopifystore/thumbnail.png";
import ShopifyStoreP1Before from "./shopifystore/problem1 - before.png";
import ShopifyStoreP1BeforeFlow from "./shopifystore/problem1 - before - user flow.png";
import ShopifyStoreP1Then from "./shopifystore/problem1 - then.png";
import ShopifyStoreP1ThenBottom from "./shopifystore/problem1 - then - bottom.png";
import ShopifyStoreP1After from "./shopifystore/problem1 - after.png";
import ShopifyStoreP1AfterBottom from "./shopifystore/problem1 - after - bottom.png";
import ShopifyStoreP1AfterFlow from "./shopifystore/problem1 - after - user flow.png";
import ShopifyStoreP2Before from "./shopifystore/problem2 - before.png";
import ShopifyStoreP2BeforeFlow from "./shopifystore/problem2 - before - user flow.png";
import ShopifyStoreP2ThenTop from "./shopifystore/problem2 - then - top.png";
import ShopifyStoreP2ThenMiddle from "./shopifystore/problem2 - then - middle.png";
import ShopifyStoreP2ThenBottom from "./shopifystore/problem2 - then - bottom.png";
import ShopifyStoreP2AfterBasic from "./shopifystore/problem2 - after - basic.png";
import ShopifyStoreP2AfterPremium from "./shopifystore/problem2 - after - premium.png";
import ShopifyStoreP2AfterFlow from "./shopifystore/problem2 - after - user flow.png";
import ShopifyStoreP3Before from "./shopifystore/problem3 - before.png";
import ShopifyStoreP3BeforeSignUp from "./shopifystore/problem3 - before - sign up.png";
import ShopifyStoreP3BeforeFlow from "./shopifystore/problem3 - before - user flow.png";
import ShopifyStoreP3ThenSelf from "./shopifystore/problem3 - then - self.png";
import ShopifyStoreP3ThenGift from "./shopifystore/problem3 - then - gift.png";
import ShopifyStoreP3ThenCheckout from "./shopifystore/problem3 - then - checkout.png";
import ShopifyStoreP3PushbackEmail from "./shopifystore/problem3 - pushback - signup email.png";
import ShopifyStoreP3PushbackForm from "./shopifystore/problem3 - pushback - signup form.png";
import ShopifyStoreP3AfterSelf from "./shopifystore/problem3 - after - self.png";
import ShopifyStoreP3AfterGift from "./shopifystore/problem3 - after - gift.png";
import ShopifyStoreP3AfterFlow from "./shopifystore/problem3 - after - user flow.png";
import ShopifyStoreP4BeforeMenu from "./shopifystore/problem4 - before - menu.png";
import ShopifyStoreP4BeforeCategories from "./shopifystore/problem4 - before - categories.png";
import ShopifyStoreP4BeforeShop from "./shopifystore/problem4 - before - shop.png";
import ShopifyStoreP4ThenMenu from "./shopifystore/problem4 - then - menu.png";
import ShopifyStoreP4ThenCategories from "./shopifystore/problem4 - then - categories.png";
import ShopifyStoreP4ThenCardSort from "./shopifystore/problem4 - then - card sort.png";
import ShopifyStoreP4ThenMenuRevised from "./shopifystore/problem4 - then - menu revised.png";
import ShopifyStoreP4ThenCategoriesRevised from "./shopifystore/problem4 - then - categories revised.png";
import ShopifyStoreP4AfterMenu from "./shopifystore/problem4 - after - menu.png";
import ShopifyStoreP4AfterCategories from "./shopifystore/problem4 - after - categories.png";
import ShopifyStoreP4AfterShop from "./shopifystore/problem4 - after - shop.png";
import ShopifyStoreDemoSiteBeforeHome from "./shopifystore/demo site - before - home.png";
import ShopifyStoreDemoSiteBeforeMenu from "./shopifystore/demo site - before - menu.png";
import ShopifyStoreDemoSiteBeforeEnd from "./shopifystore/demo site - before - end dialog.png";
import ShopifyStoreDemoSiteAfterHome from "./shopifystore/demo site - after - home.png";
import ShopifyStoreDemoSiteAfterHomeMarked from "./shopifystore/demo site - after - home - marked.png";
import ShopifyStoreDemoSiteAfterMenu from "./shopifystore/demo site - after - menu.png";
import ShopifyStoreDemoSiteAfterEnd1 from "./shopifystore/demo site - after - end dialog 1.png";
import ShopifyStoreDemoSiteAfterEnd2 from "./shopifystore/demo site - after - end dialog 2.png";
import ShopifyStoreFlowBeforePurchase from "./shopifystore/user flow - before - purchase.png";
import ShopifyStoreFlowAfterPurchase from "./shopifystore/user flow - after - purchase.png";
import ShopifyStoreFlowAfterUpgrade from "./shopifystore/user flow - after - upgrade.png";
import ShopifyStoreFlowGiftCard from "./shopifystore/user flow - gift card.png";
import ShopifyStoreFlowGiftRecipient from "./shopifystore/user flow - gift recipient.png";
import ShopifyStoreHiFiHome from "./shopifystore/hifi - home.png";
import ShopifyStoreHiFiMind from "./shopifystore/hifi - mind.png";
import ShopifyStoreHiFiBrainTagger from "./shopifystore/hifi - braintagger.png";
import ShopifyStoreHiFiCheckout from "./shopifystore/hifi - checkout.png";
import ShopifyStoreHiFiShipping from "./shopifystore/hifi - shipping.png";
import ShopifyStoreDesignSystem from "./shopifystore/design system.png";

import PlayerDashboardThumbnail from "./playerdashboard/thumbnail.png";
import PlayerDashboardBeforeSignup from "./playerdashboard/before - sign up.png";
import PlayerDashboardBeforeHome from "./playerdashboard/before - home.png";
import PlayerDashboardBeforeProfile from "./playerdashboard/before - profile.png";
import PlayerDashboardBeforeDataLeaderboard from "./playerdashboard/before - data - leaderboard.png";
import PlayerDashboardBeforeDataPerformance from "./playerdashboard/before - data - your performance.png";
import PlayerDashboardBeforeDataHighScore from "./playerdashboard/before - data - high score.png";
import PlayerDashboardBeforeDataReport from "./playerdashboard/before - data - reports.png";
import PlayerDashboardAfterSignup from "./playerdashboard/after - sign up.png";
import PlayerDashboardAfterHome from "./playerdashboard/after - home.png";
import PlayerDashboardAfterMenuExpanded from "./playerdashboard/after - menu expanded.png";
import PlayerDashboardAfterReportsPerformance from "./playerdashboard/after - reports - performance full.png";
import PlayerDashboardAfterReportsPoints from "./playerdashboard/after - reports - points.png";
import PlayerDashboardAfterProfile from "./playerdashboard/after - profile.png";
import PlayerDashboardAfterRelatives from "./playerdashboard/after - relatives - can view you.png";
import PlayerDashbaordAfterFriends from "./playerdashboard/after - friends.png";
import PlayerDashboardAfterSettings from "./playerdashboard/after - settings.png";
import PlayerDashboardAfterSubscription from "./playerdashboard/after - settings - subscription.png";

import BarterbyThumbnail from "./barterby/00-01-screens.png";
import BarterbyRoadmap from "./barterby/00-02-roadmap.png";
import BarterbyIdeation from "./barterby/01-ideation.png";
import BarterbyAffinity from "./barterby/02-affinity.png";
import BarterbyPainPointOne from "./barterby/02-affinity-pain-point-1.png";
import BarterbyPainPointTwo from "./barterby/02-affinity-pain-point-2.png";
import BarterbyPainPointThree from "./barterby/02-affinity-pain-point-3.png";
import BarterbyPainPointFour from "./barterby/02-affinity-pain-point-4.png";
import BarterbyPainPointFive from "./barterby/02-affinity-kiosk.png";
import BarterbyDesktop from "./barterby/03-01-desktop.png";
import BarterbyLofi from "./barterby/03-02-mobile-sketches.png";
import BarterbyMedFiOne from "./barterby/04-mobile-med-fi-01.png";
import BarterbyMedFiTwo from "./barterby/04-mobile-med-fi-02.png";
import BarterbyMedFiThree from "./barterby/04-mobile-med-fi-03.png";
import BarterbyMedFiFour from "./barterby/04-mobile-med-fi-04.png";
import BarterbyMedFiFive from "./barterby/04-mobile-med-fi-05.png";
import BarterbyMedFiSix from "./barterby/04-mobile-med-fi-06.png";
import BarterbyHomeBefore from "./barterby/05-integration-01-home-page.png";
import BarterbyHomeAfterOne from "./barterby/05-integration-01-home-page-after1.png";
import BarterbyHomeAfterTwo from "./barterby/05-integration-01-home-page-after2.png";
import BarterbyMenuBefore from "./barterby/05-integration-02-profile-menu.png";
import BarterbyMenuAfter from "./barterby/05-integration-02-profile-menu-after.png"
import BarterbyBartersBefore from "./barterby/05-integration-03-barters-list.png";
import BarterbyBartersAfterOne from "./barterby/05-integration-03-barters-list-after1.png";
import BarterbyBartersAfterTwo from "./barterby/05-integration-03-barters-list-after2.png";
import BarterbyDetailsBefore from "./barterby/05-integration-04-barter-details.png";
import BarterbyDetailsAfter from "./barterby/05-integration-04-barter-details-after.png";
import BarterbyQRBeforeOne from "./barterby/05-integration-05-deliver-pickup1.png";
import BarterbyQRBeforeTwo from "./barterby/05-integration-05-deliver-pickup2.png";
import BarterbyQRAfterOne from "./barterby/05-integration-05-deliver-pickup-after1.png";
import BarterbyQRAfterTwo from "./barterby/05-integration-05-deliver-pickup-after2.png";
import BarterbyInPersonOne from "./barterby/06-01-01-in-person.png";
import BarterbyInPersonTwo from "./barterby/06-01-02-in-person.png";
import BarterbyInPersonThree from "./barterby/06-01-03-in-person.png";
import BarterbyShippingOne from "./barterby/06-02-01-shipping.png";
import BarterbyShippingTwo from "./barterby/06-02-02-shipping.png";
import BarterbyShippingThree from "./barterby/06-02-03-shipping.png";
import BarterbyShippingFour from "./barterby/06-02-04-shipping.png";
import BarterbyOrganizeOne from "./barterby/06-03-01-organize.png";
import BarterbyOrganizeTwo from "./barterby/06-03-02-organize.png";
import BarterbyOrganizeThree from "./barterby/06-03-03-organize.png";
import BarterbyHiFiSeq from "./barterby/06-mobile-hi-fi-sequential.png";
import BarterbyHiFiSeqShipping from "./barterby/06-mobile-hi-fi-sequetial-shipping.png";
import BarterbyHifiInPersonOne from "./barterby/hi-fi-in-person-01.png";
import BarterbyHifiInPersonTwo from "./barterby/hi-fi-in-person-02.png";
import BarterbyHifiInPersonThree from "./barterby/hi-fi-in-person-03.png";
import BarterbyHifiInPersonFour from "./barterby/hi-fi-in-person-04.png";
import BarterbyHifiInPersonFive from "./barterby/hi-fi-in-person-05.png";
import BarterbyHifiInPersonSix from "./barterby/hi-fi-in-person-06.png";
import BarterbyHifiShipOne from "./barterby/hi-fi-ship-01.png";
import BarterbyHifiShipTwo from "./barterby/hi-fi-ship-02.png";
import BarterbyHifiShipThree from "./barterby/hi-fi-ship-03.png";
import BarterbyKioskPhysicalSketch from "./barterby/07-kiosk-hardware-sketches.png";
import BarterbyKioskTestOne from "./barterby/08-kiosk-hardware-testing1.jpg";
import BarterbyKioskTestTwo from "./barterby/08-kiosk-hardware-testing2.jpg";
import BarterbyKioskPhysicalFinal from "./barterby/09-kiosk-hardware-revised-sketch.png";
import BarterbyKioskLoFi from "./barterby/10-kiosk-screens-sketches.png";
import BarterbyKioskHiFi from "./barterby/11-kiosk-hi-fi-sequential.png";
import BarterbyKioskHiFiOne from "./barterby/kiosk-hi-fi-01.png";
import BarterbyKioskHiFiTwo from "./barterby/kiosk-hi-fi-02.png";
import BarterbyKioskHiFiThree from "./barterby/kiosk-hi-fi-03.png";
import BarterbyKioskHiFiFour from "./barterby/kiosk-hi-fi-04.png";
import BarterbyKioskHiFiFive from "./barterby/kiosk-hi-fi-05.png";
import BarterbyKioskHiFiSix from "./barterby/kiosk-hi-fi-06.png";
import BarterbyDesignSystem from "./barterby/12-design-system.png";

import InfoWebsitesBTThumbnail from "./infowebsites/thumbnail.png";
import InfoWebsitesBTDVOne from "./infowebsites/braintagger-figma-home-v1.png";
import InfoWebsitesBTDVTwo from "./infowebsites/braintagger-figma-home-v2-02.png";
import InfoWebsitesBTDVThree from "./infowebsites/braintagger-figma-home-v3.png";
import InfoWebsitesBTDVFour from "./infowebsites/braintagger-figma-home-v4.png";
import InfoWebsitesBTDPeople from "./infowebsites/braintagger-figma-people-01.png";
import InfoWebsitesBTDResearch from "./infowebsites/braintagger-figma-research.png";
import InfoWebsitesBTDGames from "./infowebsites/braintagger-figma-games-03.png";
import InfoWebsitesBTWDesktopHome from "./infowebsites/braintagger-website-desktop-home.png";
import InfoWebsitesBTWDesktopMenu from "./infowebsites/braintagger-website-desktop-menu.png";
import InfoWebsitesBTWDesktopCTA from "./infowebsites/braintagger-website-desktop-cta.png";
import InfoWebsitesBTWDesktopResearchers from "./infowebsites/braintagger-website-desktop-researchers.png";
import InfoWebsitesBTWDesktopScience from "./infowebsites/braintagger-website-desktop-science.png";
import InfoWebsitesBTWTabletTiles from "./infowebsites/braintagger-website-tablet-tiles.png";
import InfoWebsitesBTWMobileHome from "./infowebsites/braintagger-website-mobile-home.png";
import InfoWebsitesBTWMobileTiles from "./infowebsites/braintagger-website-mobile-tiles.png";
import InfoWebsitesBTWMobileMenu from "./infowebsites/braintagger-website-mobile-menu.png";
import InfoWebsitesCBDesktopHome from "./infowebsites/centivizer-before-desktop-home.png";
import InfoWebsitesCBDesktopResearch from "./infowebsites/centivizer-before-desktop-research.png";
import InfoWebsitesCBDesktopButton from "./infowebsites/centivizer-before-desktop-button.png";
import InfoWebsitesCBDesktopButtonTwo from "./infowebsites/centivizer-before-desktop-button2.png";
import InfoWebsitesCBDesktopColumns from "./infowebsites/centivizer-before-desktop-columns.png";
import InfoWebsitesCBDesktopHeading from "./infowebsites/centivizer-before-desktop-heading.png";
import InfoWebsitesCADesktopHome from "./infowebsites/centivizer-after-desktop-home.png";
import InfoWebsitesCADesktopResearch from "./infowebsites/centivizer-after-desktop-research.png";
import InfoWebsitesCADesktopHeading from "./infowebsites/centivizer-after-desktop-heading.png";
import InfoWebsitesCATabletTiles from "./infowebsites/centivizer-after-tablet-tiles.png";
import InfoWebsitesCAMobileResearch from "./infowebsites/centivizer-after-mobile-research.png";
import InfoWebsitesCAMobileMenu from "./infowebsites/centivizer-after-mobile-menu.png";
import InfoWebsitesCAMobileTiles from "./infowebsites/centivizer-after-mobile-tiles.png";

import MessagingAppThumbnail from "./messagingapp/thumbnail.png";
import MessagingAppApp from "./messagingapp/app.png";
import MessagingAppMap from "./messagingapp/map.png";
import MessagingAppMedia from "./messagingapp/media.png";
import MessagingAppSteps from "./messagingapp/steps.png";
import MessagingAppText from "./messagingapp/text.png";
import MessagingAppFamily from "./messagingapp/family.png";

import DataVisThumbnail from "./datavis/thumbnail.png";
import DataVisRoadmap from "./datavis/roadmap.png";
import DataVisAllRedesigns from "./datavis/all-redesigns.png";
import DataVisLogoMedisafe from "./datavis/Medisafe-logo.png";
import DataVisLogoLumosity from "./datavis/Lumosity-logo.png";
import DataVisLogoFitbit from "./datavis/Fitbit-logo.png";
import DataVisLogoViaOpta from "./datavis/ViaOpta-logo.png";
import DataVisLogoTengo from "./datavis/TengoBajaVision-logo.png";
import DataVisLineMedisafeOriginal from "./datavis/Medisafe-line-original.jpg";
import DataVisLineMedisafeOriginalIntOne from "./datavis/Medisafe-line-original-interaction1.png";
import DataVisLineMedisafeOriginalIntTwo from "./datavis/Medisafe-line-original-interaction2.png";
import DataVisLineMedisafePortrait from "./datavis/Medisafe-line-portrait-selected.png";
import DataVisLineMedisafeLandscape from "./datavis/Medisafe-line-landscape-selected.png";
import DataVisLineMedisafeLandscapeFull from "./datavis/Medisafe-line-landscape-fullscreen.png";
import DataVisLineLumosityOriginal from "./datavis/Lumosity-line-original.jpg";
import DataVisLineLumosityOriginalCataracts from "./datavis/Lumosity-line-orignal-cataracts.jpg";
import DataVisLineLumosityDefault from "./datavis/Lumosity-line-default.png";
import DataVisLineLumositySelected from "./datavis/Lumosity-line-selected.png";
import DataVisLineFitbitOriginal from "./datavis/Fitbit-line-original.jpg";
import DataVisLineFitbitUnselected from "./datavis/Fitbit-line-unselected.png";
import DataVisLineFitbitSelected from "./datavis/Fitbit-line-selected.png";
import DataVisLineFitbitCataracts from "./datavis/Fitbit-line-cataracts.jpg";
import DataVisBarLumosityOriginal from "./datavis/Lumosity-progressbar-original.jpg"
import DataVisBarLumositySelectedOne from "./datavis/Lumosity-progressbar-selected1.png";
import DataVisBarLumositySelectedTwo from "./datavis/Lumosity-progressbar-selected2.png";
import DataVisBarFitbitOriginal from "./datavis/Fitbit-bar-original.jpg";
import DataVisBarFitbitUnselected from "./datavis/Fitbit-bar-unselected.png";
import DataVisBarFitbitSelected from "./datavis/Fitbit-bar-selected.png";
import DataVisStBarFitbitOriginal from "./datavis/Fitbit-stackedbar-original.jpg";
import DataVisStBarFitbitOriginalCataracts from "./datavis/Fitbit-stackedbar-original-cataracts.jpg";
import DataVisStBarFitbitUnselected from "./datavis/Fitbit-stackedbar-unselected.png";
import DataVisStBarFitbitSelected from "./datavis/Fitbit-stackedbar-selected.png";
import DataVisStBarFitbitCataracts from "./datavis/Fitbit-stackedbar-cataracts.jpg";

import HealthDirectoryThumbnail from "./healthdirectory/thumbnail.png";
import HealthDirectoryRoadmap from "./healthdirectory/roadmap.png";
import HealthDirectoryAffinitySurvey from "./healthdirectory/affinity-survey.png";
import HealthDirectoryAffinityInterview from "./healthdirectory/affinity-interview.png";
import HealthDirectoryDraftMap from "./healthdirectory/artifacts-draft-as-is-map.png";
import HealthDirectoryArtifactPersona from "./healthdirectory/artifacts-persona.png";
import HealthDirectoryArtifactEmpathy from "./healthdirectory/artifacts-empathy-map.png";
import HealthDirectoryArtifactBusiness from "./healthdirectory/artifacts-business-model-canvas.png";
import HealthDirectoryArtifactAsIsMap from "./healthdirectory/artifacts-as-is-map.png";
import HealthDirectoryArtifactToBeMapTop from "./healthdirectory/artifacts-to-be-map-top.png";
import HealthDirectoryArtifactToBeMapBottom from "./healthdirectory/artifacts-to-be-map-bottom.png";
import HealthDirectoryIdeationSearch from "./healthdirectory/ideation-search.png";
import HealthDirectoryIdeationFilter from "./healthdirectory/ideation-filter.png";
import HealthDirectoryFlowQuestionnaire from "./healthdirectory/flow-questionnaire.png";
import HealthDirectoryFlowPrototype from "./healthdirectory/flow-prototype.png";
import HealthDirectoryPrototypeInitialSequential from "./healthdirectory/prototype-initial-abridged-sequential.png";
import HealthDirectoryPrototypeInitialFilters from "./healthdirectory/prototype-initial-filters.jpg"
import HealthDirectoryPrototypeInitialIdea1 from "./healthdirectory/prototype-initial-idea1.png";
import HealthDirectoryPrototypeInitialIdea2 from "./healthdirectory/prototype-initial-idea2.png";
import HealthDirectoryPrototypeInitialIdea3 from "./healthdirectory/prototype-initial-idea3.png";
import HealthDirectoryPrototypeFinalSequential from "./healthdirectory/prototype-final-abridged-sequential.png";

import ConnectUofTZeroOne from "./connectuoft/00-01-screen.png";
import ConnectUoftZeroTwo from "./connectuoft/00-02-roadmap.png";
import ConnectUofTOne from "./connectuoft/01-research.png";
import ConnectUofTTwo from "./connectuoft/02-persona.png";
import ConnectUofTThreeOne from "./connectuoft/03-01-video.png";
import ConnectUofTThreeTwo from "./connectuoft/03-02-text.png";
import ConnectUofTThreeThree from "./connectuoft/03-03-notification.png";
import ConnectUofTFourOne from "./connectuoft/04-01-browse.png"
import ConnectUofTFourTwo from "./connectuoft/04-02-onebyone.png"
import ConnectUofTFourThree from "./connectuoft/04-03-noreply.png";
import ConnectUofTFourFour from "./connectuoft/04-04-verified.png";
import ConnectUofTFourFive from "./connectuoft/04-05-icebreaker.png";
import ConnectUofTFive from "./connectuoft/05-mysketches.png";
import ConnectUofTSixOne from "./connectuoft/06-01-lofi-storyboard.png";
import ConnectUofTSixTwo from "./connectuoft/06-02-lofi-storyboard-full.png";
import ConnectUofTSevenOne from "./connectuoft/07-01-login.png";
import ConnectUofTSevenTwo from "./connectuoft/07-02-chat.png";
import ConnectUofTSevenThree from "./connectuoft/07-03-list.png";
import ConnectUofTSevenFour from "./connectuoft/07-04-avatar.png";
import ConnectUofTEightOne from "./connectuoft/08-01-medfi-storyboard.png";
import ConnectUofTEightTwo from "./connectuoft/08-02-medfi-storyboard-full.png";

import BooksThumbnail from "./books/thumbnail.png";


export const Images = {
  profile: Profile,
  shopifyStore:{
    thumbnail: ShopifyStoreThumbnail,
    problem1:{
      before: {
        page: ShopifyStoreP1Before,
        userFlow: ShopifyStoreP1BeforeFlow
      },
      then: {
        top: ShopifyStoreP1Then,
        bottom: ShopifyStoreP1ThenBottom
      },
      after: {
        top: ShopifyStoreP1After,
        bottom: ShopifyStoreP1AfterBottom,
        userFlow: ShopifyStoreP1AfterFlow
      }
    },
    problem2:{
      before: {
        page: ShopifyStoreP2Before,
        userFlow: ShopifyStoreP2BeforeFlow
      },
      then:{
        top: ShopifyStoreP2ThenTop,
        middle: ShopifyStoreP2ThenMiddle,
        bottom: ShopifyStoreP2ThenBottom
      },
      after:{
        basic: ShopifyStoreP2AfterBasic,
        premium: ShopifyStoreP2AfterPremium,
        userFlow: ShopifyStoreP2AfterFlow
      }
    },
    problem3:{
      before: {
        page: ShopifyStoreP3Before,
        signup: ShopifyStoreP3BeforeSignUp,
        userFlow: ShopifyStoreP3BeforeFlow
      },
      then:{
        self: ShopifyStoreP3ThenSelf,
        gift: ShopifyStoreP3ThenGift,
        checkout: ShopifyStoreP3ThenCheckout
      },
      pushback:{
        email: ShopifyStoreP3PushbackEmail,
        form: ShopifyStoreP3PushbackForm
      },
      after:{
        self: ShopifyStoreP3AfterSelf,
        gift: ShopifyStoreP3AfterGift,
        userFlow: ShopifyStoreP3AfterFlow
      }
    },
    problem4: {
      before: {
        menu: ShopifyStoreP4BeforeMenu,
        categories: ShopifyStoreP4BeforeCategories,
        shop: ShopifyStoreP4BeforeShop
      },
      then: {
        menu: ShopifyStoreP4ThenMenu,
        categories: ShopifyStoreP4ThenCategories,
        cardSort: ShopifyStoreP4ThenCardSort,
        menuRevised: ShopifyStoreP4ThenMenuRevised,
        categoriesRevised: ShopifyStoreP4ThenCategoriesRevised
      },
      after: {
        menu: ShopifyStoreP4AfterMenu,
        categories: ShopifyStoreP4AfterCategories,
        shop: ShopifyStoreP4AfterShop
      }
    },
    demoSite:{
      before:{
        home: ShopifyStoreDemoSiteBeforeHome,
        menu: ShopifyStoreDemoSiteBeforeMenu,
        end: ShopifyStoreDemoSiteBeforeEnd
      },
      after:{
        home: ShopifyStoreDemoSiteAfterHome,
        homeMarked: ShopifyStoreDemoSiteAfterHomeMarked,
        menu: ShopifyStoreDemoSiteAfterMenu,
        end1: ShopifyStoreDemoSiteAfterEnd1,
        end2: ShopifyStoreDemoSiteAfterEnd2
      }
    },
    userFlow:{
      before:
      {
        purchase: ShopifyStoreFlowBeforePurchase
      },
      after:
      {
        purchase: ShopifyStoreFlowAfterPurchase,
        upgrade: ShopifyStoreFlowAfterUpgrade
      },
      giftCard: ShopifyStoreFlowGiftCard,
      recipient: ShopifyStoreFlowGiftRecipient
    },
    hiFi:{
      home: ShopifyStoreHiFiHome,
      mind: ShopifyStoreHiFiMind,
      brainTagger: ShopifyStoreHiFiBrainTagger,
      checkout: ShopifyStoreHiFiCheckout,
      shipping: ShopifyStoreHiFiShipping
    },
    designSystem: ShopifyStoreDesignSystem
  },
  playerDashboard:{
    thumbnail: PlayerDashboardThumbnail,
    before: {
      signup: PlayerDashboardBeforeSignup,
      home: PlayerDashboardBeforeHome,
      profile: PlayerDashboardBeforeProfile,
      data: {
        leaderboard: PlayerDashboardBeforeDataLeaderboard,
        performance: PlayerDashboardBeforeDataPerformance,
        highScore: PlayerDashboardBeforeDataHighScore,
        report: PlayerDashboardBeforeDataReport
      }
    },
    after:{
      signup: PlayerDashboardAfterSignup,
      home: PlayerDashboardAfterHome,
      menuExpanded: PlayerDashboardAfterMenuExpanded,
      profile: PlayerDashboardAfterProfile,
      reports: {
        performance: PlayerDashboardAfterReportsPerformance,
        points: PlayerDashboardAfterReportsPoints
      },
      relatives: PlayerDashboardAfterRelatives,
      friends: PlayerDashbaordAfterFriends,
      settings: PlayerDashboardAfterSettings,
      subscription: PlayerDashboardAfterSubscription
    }
  },
  barterby:{
    thumbnail: BarterbyThumbnail,
    roadmap: BarterbyRoadmap,
    ideation: BarterbyIdeation,
    affinity: BarterbyAffinity,
    painPoint:{
      one: BarterbyPainPointOne,
      two: BarterbyPainPointTwo,
      three: BarterbyPainPointThree,
      four: BarterbyPainPointFour,
      five: BarterbyPainPointFive
    },
    desktop: BarterbyDesktop,
    loFi: BarterbyLofi,
    medFi: {
      one: BarterbyMedFiOne,
      two: BarterbyMedFiTwo,
      three: BarterbyMedFiThree,
      four: BarterbyMedFiFour,
      five: BarterbyMedFiFive,
      six: BarterbyMedFiSix
    },
    integration:{
      home: {
        before: BarterbyHomeBefore,
        afterOne: BarterbyHomeAfterOne,
        afterTwo: BarterbyHomeAfterTwo
      },
      profileMenu: {
        before: BarterbyMenuBefore,
        after: BarterbyMenuAfter,
      },
      barters: {
        before: BarterbyBartersBefore,
        afterOne: BarterbyBartersAfterOne,
        afterTwo: BarterbyBartersAfterTwo
      },
      details: {
        before: BarterbyDetailsBefore,
        after: BarterbyDetailsAfter
      },
      QR: {
        beforeOne: BarterbyQRBeforeOne,
        beforeTwo: BarterbyQRBeforeTwo,
        afterOne: BarterbyQRAfterOne,
        afterTwo: BarterbyQRAfterTwo,
      },
    },
    hiFiSeq: BarterbyHiFiSeq,
    hiFiSeqShipping: BarterbyHiFiSeqShipping,
    features:{
      inPerson: {
        one: BarterbyInPersonOne,
        two: BarterbyInPersonTwo,
        three: BarterbyInPersonThree
      },
      shipping: {
        one: BarterbyShippingOne,
        two: BarterbyShippingTwo,
        three: BarterbyShippingThree,
        four: BarterbyShippingFour,
      },
      organize: {
        one: BarterbyOrganizeOne,
        two: BarterbyOrganizeTwo,
        three: BarterbyOrganizeThree
      },
    },
    hiFi:{
        inPerson:{
        one: BarterbyHifiInPersonOne,
        two: BarterbyHifiInPersonTwo,
        three: BarterbyHifiInPersonThree,
        four: BarterbyHifiInPersonFour,
        five: BarterbyHifiInPersonFive,
        six: BarterbyHifiInPersonSix
      },
      ship:{
        one: BarterbyHifiShipOne,
        two: BarterbyHifiShipTwo,
        three: BarterbyHifiShipThree
      },
    },
    kiosk:{
      physical:{
        sketch: BarterbyKioskPhysicalSketch,
        test: {
          one: BarterbyKioskTestOne,
          two: BarterbyKioskTestTwo
        },
        final: BarterbyKioskPhysicalFinal,
      },
      loFi: BarterbyKioskLoFi,
      hiFiSeq: BarterbyKioskHiFi,
      hiFi:{
        one: BarterbyKioskHiFiOne,
        two: BarterbyKioskHiFiTwo,
        three: BarterbyKioskHiFiThree,
        four: BarterbyKioskHiFiFour,
        five: BarterbyKioskHiFiFive,
        six: BarterbyKioskHiFiSix
      },
    },
    designSystem: BarterbyDesignSystem
  },
  infoWebsites:{
    thumbnail: InfoWebsitesBTThumbnail,
    braintagger:{
      design:{
        home:{
          v1: InfoWebsitesBTDVOne,
          v2: InfoWebsitesBTDVTwo,
          v3: InfoWebsitesBTDVThree,
          v4: InfoWebsitesBTDVFour,
        },
        people: InfoWebsitesBTDPeople,
        research: InfoWebsitesBTDResearch,
        games: InfoWebsitesBTDGames
      },
      website:{
        desktop:{
          home: InfoWebsitesBTWDesktopHome,
          menu: InfoWebsitesBTWDesktopMenu,
          cta: InfoWebsitesBTWDesktopCTA,
          researchers: InfoWebsitesBTWDesktopResearchers,
          science: InfoWebsitesBTWDesktopScience
        },
        tablet:{
          tiles: InfoWebsitesBTWTabletTiles,
        },
        mobile:{
          home: InfoWebsitesBTWMobileHome,
          tiles: InfoWebsitesBTWMobileTiles,
          menu: InfoWebsitesBTWMobileMenu
        }
      }
    },
    centivizer:{
      before:{
        desktop:{
          home: InfoWebsitesCBDesktopHome,
          research: InfoWebsitesCBDesktopResearch,
          button: InfoWebsitesCBDesktopButton,
          button2: InfoWebsitesCBDesktopButtonTwo,
          columns: InfoWebsitesCBDesktopColumns,
          heading: InfoWebsitesCBDesktopHeading
        },
      },
      after:{      
        desktop:{
          home: InfoWebsitesCADesktopHome,
          research: InfoWebsitesCADesktopResearch,
          heading: InfoWebsitesCADesktopHeading
        },
        tablet:{
          tiles: InfoWebsitesCATabletTiles,
        },
        mobile:{
          research: InfoWebsitesCAMobileResearch,
          menu: InfoWebsitesCAMobileMenu,
          tiles: InfoWebsitesCAMobileTiles
        },
      }
    }
  },
  messagingApp:{
    thumbnail: MessagingAppThumbnail,
    app: MessagingAppApp,
    map: MessagingAppMap,
    media: MessagingAppMedia,
    text: MessagingAppText,
    steps: MessagingAppSteps,
    family: MessagingAppFamily
  },
  dataVis:{
    thumbnail: DataVisThumbnail,
    roadmap: DataVisRoadmap,
    all: DataVisAllRedesigns,
    logo:{
      medisafe: DataVisLogoMedisafe,
      lumosity: DataVisLogoLumosity,
      fitbit: DataVisLogoFitbit,
      viaopta: DataVisLogoViaOpta,
      tengo: DataVisLogoTengo
    },
    simulator:{
      original:{
        lumosity: DataVisLineLumosityOriginalCataracts,
        fitbit: DataVisStBarFitbitOriginalCataracts
      },
      redesigns:{
        fitbitLine: DataVisLineFitbitCataracts,
        fitbitStacked: DataVisStBarFitbitCataracts
      }
    },
    interaction:{
      medisafe1: DataVisLineMedisafeOriginalIntOne,
      medisafe2: DataVisLineMedisafeOriginalIntTwo,
    },
    line:{
      medisafe:{
        original: DataVisLineMedisafeOriginal,
        portrait: DataVisLineMedisafePortrait,
        landscape: DataVisLineMedisafeLandscape,
        full: DataVisLineMedisafeLandscapeFull
      },
      lumosity:{
        original: DataVisLineLumosityOriginal,
        default: DataVisLineLumosityDefault,
        selected: DataVisLineLumositySelected
      },
      fitbit:{
        original: DataVisLineFitbitOriginal,
        unselected: DataVisLineFitbitUnselected,
        selected: DataVisLineFitbitSelected
      }
    },
    bar:{
      lumosity:{
        original: DataVisBarLumosityOriginal,
        selected1: DataVisBarLumositySelectedOne,
        selected2: DataVisBarLumositySelectedTwo
      },
      fitbit:{
        original: DataVisBarFitbitOriginal,
        unselected: DataVisBarFitbitUnselected,
        selected: DataVisBarFitbitSelected
      },
      fitbitStacked:{
        original: DataVisStBarFitbitOriginal,
        unselected: DataVisStBarFitbitUnselected,
        selected: DataVisStBarFitbitSelected
      }
    }
  },
  healthDirectory:{
    thumbnail: HealthDirectoryThumbnail,
    roadmap: HealthDirectoryRoadmap,
    affinity:{
      survey: HealthDirectoryAffinitySurvey,
      interview: HealthDirectoryAffinityInterview,
    },
    draft:{
      map: HealthDirectoryDraftMap
    },
    artifact:{
      business: HealthDirectoryArtifactBusiness,
      persona: HealthDirectoryArtifactPersona,
      empathy: HealthDirectoryArtifactEmpathy,
      asIsMap: HealthDirectoryArtifactAsIsMap,
      toBeMapTop: HealthDirectoryArtifactToBeMapTop,
      toBeMapBottom: HealthDirectoryArtifactToBeMapBottom
    },
    flow:{
      questionnaire: HealthDirectoryFlowQuestionnaire,
      prototype: HealthDirectoryFlowPrototype,
    },
    ideation:{
      search: HealthDirectoryIdeationSearch,
      filter: HealthDirectoryIdeationFilter,
    },
    prototype:{
      initial:{
        sequential: HealthDirectoryPrototypeInitialSequential,
        filters: HealthDirectoryPrototypeInitialFilters,
        idea1: HealthDirectoryPrototypeInitialIdea1,
        idea2: HealthDirectoryPrototypeInitialIdea2,
        idea3: HealthDirectoryPrototypeInitialIdea3,
      },
      final:{
        sequential: HealthDirectoryPrototypeFinalSequential
      }
    }
  },
  connectUofT: {
    thumbnail: ConnectUofTZeroOne,
    roadmap: ConnectUoftZeroTwo,
    one: ConnectUofTOne,
    two: ConnectUofTTwo,
    three: {
      one: ConnectUofTThreeOne,
      two: ConnectUofTThreeTwo,
      three: ConnectUofTThreeThree,
    },
    four:{
      one: ConnectUofTFourOne,
      two: ConnectUofTFourTwo,
      three: ConnectUofTFourThree,
      four: ConnectUofTFourFour,
      five: ConnectUofTFourFive,
    },
    five: ConnectUofTFive,
    six: {
      abridged: ConnectUofTSixOne,
      full: ConnectUofTSixTwo
    },
    seven:{
      one: ConnectUofTSevenOne,
      two: ConnectUofTSevenTwo,
      three: ConnectUofTSevenThree,
      four: ConnectUofTSevenFour,
    },
    eight:{
      abridged: ConnectUofTEightOne,
      full: ConnectUofTEightTwo
    }
  },
  books:{
    thumbnail: BooksThumbnail
  }
};

export const Thumbnail = styled.img`
  flex: 1;
  width: ${({ width }) => (width ? `${width}vw` : "100%")};
  height: ${({ height }) => (height ? `${height}vh` : "auto")};
  object-fit: scale-down;
  padding: ${({ padding }) => (padding ?? "2.5%")};
  cursor: ${({ cursor }) => (cursor ?? "auto")};

  @media only screen and (min-width: 600px){
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : "25px")};
  };

  border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : "15px")};
`;
