import React from "react";
import { 
    COLORS } from "core";
import {
    Grid,
    Typography,
    Button,
} from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';

const Private = props => {

    const { setStep } = props;

    return(
        <Grid container spacing={3} display="flex">
            <Grid item xs={12} display='flex' flexDirection="column" alignItems='center' justifyContent='center' textAlign="center">     
                <Typography variant="body2" color="primary">
                    <p>
                        I am happy to share this semi-confidential project with recruiters.
                    </p>
                    <p>
                        <b style={{ color: COLORS.OFF_WHITE}}>If you already have a password, please unlock the project.</b>
                    </p>
                </Typography>
            </Grid>

            <Grid item xs={12} display='flex' alignItems='center' justifyContent='center'>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={()=> {
                        setStep(1);
                    }}
                    sx={{ boxShadow: `0px 0px 5px ${COLORS.OFF_WHITE}`,
                            padding: '15px 30px',
                            transition: "transform 0.15s ease-in-out",
                            "&:hover": { boxShadow: `0px 0px 5px ${COLORS.OFF_WHITE}`,
                            transform: "scale3d(1.2, 1.2, 1)" }
                        }}
                    >
                        <SvgIcon component={LockIcon} sx={{ marginRight: '15px', fontSize: '32px', color: COLORS.BLACK }} />
                        Unlock
                </Button>
            </Grid>
            <Grid item
                xs={12} mt={4} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', flexWrap: 'wrap',
                transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.1, 1.1, 1)"} }}
            >
                <SvgIcon component={EmailIcon} sx={{ marginRight: '15px', fontSize: '32px', color: COLORS.LAVENDER }} />
                <a href="mailto:ksondjaja@gmail.com" style={{ textDecoration:"none"}}>
                    Email me
                </a>
                <Typography variant="body1" color="primary">
                    &nbsp;to get access to my password-protected projects
                </Typography>
            </Grid>
        </Grid>
    );
}


export default Private;