import React from "react";
import bcrypt from 'bcryptjs';
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Helmet } from 'react-helmet'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "core";
import CssBaseline from '@mui/material/CssBaseline';

import PasswordPrompt from "PasswordPrompt";
import Nav from "Nav";
import Work from "Work";
import About from "About";
import Resume from "Resume";
import Contact from "Contact";
import {
  Barterby,
  BlurredVision,
  ShopifyStore,
  PlayerDashboard
} from "projects";

import './App.css';

const TITLE = "Lala K. Sondjaja's Portfolio";

export class App extends React.Component {
  constructor(props) {
    super(props);

    this.PASS = process.env.REACT_APP_ACCESS_KEY;

    this.state = {
      step: -1,
      password: ''
    };

    this.setStep = value => {
      this.setState({
        step: value
      });
    }

    this.handlePasswordChange = input => {
      this.setState({
        password: input
      });
    }

  };

  componentDidMount() {
    let password;
    
    if(localStorage.getItem("ksondj")){
      password = JSON.parse(localStorage.getItem("ksondj"));
    }else{
      localStorage.setItem("ksondj", "");
      password='';

      console.log("no password");
    }

    this.setState({
      password: password
    });
  }
  componentDidUpdate() {
    localStorage.setItem("ksondj", JSON.stringify(this.state.password));
  }

  render(){

    return (
      <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>

        <ThemeProvider theme={theme}>
          <React.Fragment>
            <CssBaseline />

            <PasswordPrompt
              PASS = {this.PASS}
              state = {this.state}
              setStep = {this.setStep.bind(this)}
              handlePasswordChange = {this.handlePasswordChange.bind(this)}
            />

              <Nav
                PASS = {this.PASS}
                state = {this.state}
                setStep = {this.setStep.bind(this)}
              />

                  <Routes>
                    <Route path="/" element={(<Navigate to="/work"/>)} />

                    <Route
                      exact path="/work"
                      element={
                        <Work
                        PASS = {this.PASS}
                        state = {this.state}
                        />
                      }
                    />

                    <Route path="/about" element={<About />}/>

                    <Route
                      path="/resume"
                      element={<Resume 
                        PASS = {this.PASS}
                        state = {this.state}
                      />}
                    />

                    <Route path="/contact" element={<Contact />}/>

                    <Route exact path="/work/barterby" element={<Barterby/>}/>
                    <Route exact path="/work/blurredvision" element={<BlurredVision/>}/>

                    <Route exact path="/work/shopifystore" element={
                      <ShopifyStore
                        PASS = {this.PASS}
                        state = {this.state}
                        setStep = {this.setStep.bind(this)}
                      />
                    }/>

                    <Route exact path="/work/playerdashboard" element={
                      <PlayerDashboard
                        PASS = {this.PASS}
                        state = {this.state}
                        setStep = {this.setStep.bind(this)}
                      />
                    }/>

                  </Routes>

            </React.Fragment>
        </ThemeProvider>
      </>
    );
    }

}

export default App;
