import React, { useState } from "react";
import bcrypt from 'bcryptjs';
import { 
    Layout,
    Construction,
    Private,
    ProjectTitle,
    ProjectDates,
    ProjectDescription,
    ProjectHeading,
    ProjectBody,
    ImageModal,
    LinkButton,
    SkinnyStartArrowButton,
    SkinnyArrowButton,
    StartArrowButton,
    ArrowButton,
    DownStartArrowButton,
    DownArrowButton,
    StyledButton,
    StyledOutlineButton,
    B,
    ThumbnailButton,
    COLORS } from "core";
import {
    Fade,
    Menu,
    Drawer,
    Paper,
    Grid,
    Box,
    Typography,
    Button,
    IconButton,
    SvgIcon
} from '@mui/material';
import { MUIAppBar } from "../Nav";
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Images, Thumbnail } from "assets";
import { Search,
    Visibility,
    Link,
    PhonelinkErase,
    ExpandLess,
    ExpandMore,
    Person,
    SouthRounded,
    EastRounded,
    Psychology,
    MarkEmailRead,
    Interests
} from '@mui/icons-material';


const PlayerDashboard = props => {

    const [showRoadmap, setShowRoadmap] = useState(null);

    const theme = useTheme();
    const isWide = useMediaQuery(theme.breakpoints.up('730'));
    const isXS = useMediaQuery(theme.breakpoints.between('470','600'));
    const isSM = useMediaQuery(theme.breakpoints.between('600','900'));
    const isMD = useMediaQuery(theme.breakpoints.up('900'));
    const isTiny = useMediaQuery(theme.breakpoints.down('470'));
    const isNotTiny = useMediaQuery(theme.breakpoints.up('470'));

    const col =[
        COLORS.GOLD,
        ['#ffd9bc', '#a1d1d7', '#91b5ba'],
        ['#ffcec4', '#a2ccde', '#95b3c1'],
        ['#ffc7d3', '#a7c7e5', '#98b3cb', '#8a9fb2'],
        ['#f2c3e3', '#c0caed', '#b4bddc', '#a8b0cc'],
        COLORS.LAVENDER,
    ]

    const skills = ['Prototyping', 'Data Visualization', 'Responsive Design', 'Design System', 'Figma', 'Usability Testing', 'User Interviews'];


    return(
        <Layout>
            {bcrypt.compareSync(props.state.password, props.PASS) &&
                <>
                
                <Box sx={{ display:'flex', flexDirection: 'column', alignItems: 'center', position: 'fixed', top: 'auto', bottom: '10px', left: 'auto', right: '20px', zIndex: 899,}}>                       
                    <StyledButton fontSize="16px" padding="5px 10px"
                        onClick={()=>setShowRoadmap(true)}
                    >
                        Jump to...
                    </StyledButton>

                </Box>
                <Box sx={{ display:'flex', flexDirection: 'column', position: 'fixed', top: '45%', bottom: 'auto', left: 'auto', right: '20px', zIndex: 899,}}>
                    <IconButton href="#" aria-label="scroll-to-top" color="secondary" size="small"
                        sx={{ display:'flex', flexDirection: 'column', py:1,
                        mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                        boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                        '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandLess fontSize="inherit" sx={{mb:-3}}/>
                        <ExpandLess fontSize="inherit" />
                    </IconButton>

                    {/* <IconButton href="#" aria-label="scroll-up" color="secondary" size="small"
                        sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                        boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                        '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandLess fontSize="inherit" />
                    </IconButton>

                    <IconButton href="#bottom" aria-label="scroll-down" color="secondary" size="small"
                            sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandMore fontSize="inherit" />
                    </IconButton> */}

                    <IconButton href="#bottom" aria-label="scroll-to-top" color="secondary" size="small"
                        sx={{ display:'flex', flexDirection: 'column', py:1,
                        mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                        boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                        '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandMore fontSize="inherit" sx={{mb:-3}}/>
                        <ExpandMore fontSize="inherit" />
                    </IconButton>      
                </Box>
                
                

                <Box mb={4} display="flex" sx={{ width: '100%' }}>
                    <Box bgcolor={ COLORS.GOLD } display="flex" alignItems="center" justifyContent="center"
                        sx={{ borderRadius: '1vw', width:'100%' }}
                    >
                        <Thumbnail src={Images.playerDashboard.thumbnail} width="95%" alt="BrainTagger Dashboard key screen"/>
                    </Box>
                </Box>
                </>
            }

            <ProjectTitle color={col[0]}>
                BrainTagger Dashboard<br/>
                <Typography variant="h5" color={col[0]}>
                    View game performance, connect with other users, and manage subscription
                </Typography>
            </ProjectTitle>
            
            <ProjectBody style={{ marginTop: '20px', marginBottom: '10px' }}>
                <p>
                    <Typography variant="h4" color="primary">
                        Implemented Website:
                    </Typography>
                    <a href="https://report.braintagger.com/" target="_blank" rel="noreferrer" style={{color: col[0]}}>
                        BrainTagger Dashboard
                    </a>
                    &nbsp;(under construction)
                </p>
                <br/>
                {skills.map(skill=>{
                    return(
                        <Box display="inline-block" backgroundColor={COLORS.BLACK} px={1.5} py={0.5} mr={1.5} mb={1.5}
                            sx={{ borderRadius:'5px', border: `1px solid ${COLORS.GOLD}` }}>
                            <Typography variant="body1">
                                <span style={{ fontSize: '16px', fontWeight: '700', color: COLORS.GOLD }}>
                                    {skill}
                                </span>
                            </Typography>
                        </Box>
                    );
                })}
            </ProjectBody>

            <ProjectDates>
                UX/UI Design paid work<br/>
                November 2022 - present (part-time)<br/>
            </ProjectDates>

            <br/><br/>

            {!bcrypt.compareSync(props.state.password, props.PASS)?
                <Private {...props} />
                :
                <>  
                    <Construction color={col[0]} />

                        <ProjectHeading color={col[0]} narrow id="overview">
                            Problem Space
                        </ProjectHeading>
                        <ProjectDescription>
                            <p>
                                Centivizer is creating a new <B col={col[0]}>B2C Dashboard</B> and game suite based on an older B2B BrainTagger, their original cognitive health web game. The existing Dashboard, which allows users' to <B col={col[0]}>monitor game performance data and manage their account</B>, has been partially implemented.
                            </p>
                            <p>
                                When I tested the Dashboard with users for B2C BrainTagger, however, I discovered these problems:
                            </p>
                            <br/>
                            <ThumbnailButton href="#data">
                                <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                                    <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                                            <SvgIcon component={Visibility} sx={{ fontSize:{ xs: "50px"}, color: col[1][0]}}/>
                                        </Grid>
                                        <Grid item xs={10} mt={1}>
                                            <Typography variant="h4" color={col[1][0]}>
                                                Problem 1:
                                            </Typography>
                                            <Typography variant="body2" color="primary">
                                                Users were puzzled by <B col={col[1][0]}>data that do not match their labels</B>, and could not use data to effectively <B col={col[1][0]}>identify specific areas to monitor/improve.</B>
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                <p>
                                                    <B col={col[1][0]}>Solution:</B><br/>
                                                    Make information architecture and visualization of data pages more organized and self-explanatory.
                                                </p>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ThumbnailButton>
                            <br/><br/>
                            <ThumbnailButton href="#connect">
                                <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                                    <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                                            <SvgIcon component={Link} sx={{ fontSize:{ xs: "50px"}, color: col[2][0]}}/>
                                        </Grid>
                                        <Grid item xs={10} mt={1}>
                                            <Typography variant="h4" color={col[2][0]}>
                                                Problem 2:
                                            </Typography>
                                            <Typography variant="body2" color="primary">
                                                Users <B col={col[2][0]}>cannot directly go between BrainTagger Dashboard, game site, and Healthy Age Mart</B>, to play and manage payment.
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                <p>
                                                    <B col={col[2][0]}>Solution:</B><br/>
                                                    Worked on user flow to make game purchase, play, and data monitoring as seamless as possible.
                                                </p>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ThumbnailButton>
                            <br/><br/>
                            <ThumbnailButton href="#responsive">
                                <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                                    <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                                            <SvgIcon component={PhonelinkErase} sx={{ fontSize:{ xs: "50px"}, color: col[3][0]}}/>
                                        </Grid>
                                        <Grid item xs={10} mt={1}>
                                            <Typography variant="h4" color={col[3][0]}>
                                                Problem 3:
                                            </Typography>
                                            <Typography variant="body2" color="primary">
                                                Although access to BrainTagger Dashboard costs at least $4.99, much of the <B col={col[3][0]}>website layout breaks when viewed in a small tablet or mobile screen</B>.
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                <p>
                                                    <B col={col[3][0]}>Solution:</B><br/>
                                                    Worked on responsive design and documented Material UI components for developers to implement.
                                                </p>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ThumbnailButton>

                        </ProjectDescription>

                        <ProjectHeading color={col[0]} narrow>
                            Constraints
                        </ProjectHeading>
                        <ProjectDescription>
                            <Typography variant="body1" color="primary">
                                <p>
                                    The way game data is measured and calculated is based on psychology research, which I am not a part of. My job is to display the data more effectively to end users who are not scientists themselves.
                                </p>
                                <p>
                                    In addition, the BrainTagger Dashboard has been partially implemented, and I edited the design based on technical limitations and stacks used on the frontend and backend.
                                </p>
                            </Typography>
                        </ProjectDescription>

                    <ProjectHeading color={col[0]} narrow>
                        Team
                    </ProjectHeading>
                    <ProjectBody>
                        <p>
                            I collaborated with 1 developer & project manager for the BrainTagger product, Centivizer's Head Project Manager, and Centivizer's CEO.
                        </p>
                    </ProjectBody>

                    <ProjectHeading color={col[0]} narrow>
                        My Role
                    </ProjectHeading>
                    <ProjectBody>
                        <ul>
                            <li>
                                Iteratively designed UX and UI based on B2C BrainTagger product features
                            </li>
                            <li>
                                Worked on a design system to make the dashboard more accessible to older-aged target audience
                            </li>
                            <li>
                                Conducted usability testing with target users and integrated feedback into design
                            </li>
                            <li>
                                Collaborated with developer, project manager, and Centivizer's CEO to work around technical and product constraints
                            </li>
                        </ul>
                    </ProjectBody>

                    <ProjectHeading color={col[1]}>
                        Screens Before & After
                    </ProjectHeading>
                    <ProjectBody>
                        <Grid container spacing={5} display="flex" flexDirection="row" alignItems="flex-start">
                            <Grid item xs={12}  mt={3}>
                                <Typography variant="h4" color={col[1]}>
                                    Sign Up
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    BEFORE
                                </Typography>
                                <ImageModal image={Images.playerDashboard.before.signup} alt="BrainTagger Dashboard sign up before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    AFTER
                                </Typography>
                                <ImageModal image={Images.playerDashboard.after.signup} alt="BrainTagger Dashboard sign up after redesign" borderRadius='0' padding='0'/>
                            </Grid>

                            <Grid item xs={12}  mt={5}>
                                <Typography variant="h4" color={col[1]}>
                                    Home
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    BEFORE
                                </Typography>
                                <ImageModal image={Images.playerDashboard.before.home} alt="BrainTagger Dashboard home before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    AFTER
                                </Typography>
                                <ImageModal image={Images.playerDashboard.after.home} alt="BrainTagger Dashboard home after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <br/>
                                <p>
                                    I wanted to make more space for the main dashboard site content, thus I made the persistent side menu skinnier and expandable upon mouse hover.
                                </p>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    Expanded Menu
                                </Typography>
                                <ImageModal image={Images.playerDashboard.after.menuExpanded} alt="BrainTagger Dashboard expanded menu after redesign" borderRadius='0' padding='0'/>
                            </Grid>

                            <Grid item xs={12}  mt={3}>
                                <Typography variant="h4" color={col[1]}>
                                    Data Reports - Game Performance
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    BEFORE
                                </Typography>
                                <ImageModal image={Images.playerDashboard.before.data.performance} alt="BrainTagger Dashboard data - performance before redesign" borderRadius='0' padding='0'/>
                                <br/>
                                <ImageModal image={Images.playerDashboard.before.data.highScore} alt="BrainTagger Dashboard data - high score before redesign" borderRadius='0' padding='0'/>
                                <br/>
                                <ImageModal image={Images.playerDashboard.before.data.report} alt="BrainTagger Dashboard new reports page before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    AFTER
                                </Typography>
                                <ImageModal image={Images.playerDashboard.after.reports.performance} alt="BrainTagger Dashboard reports - performance after redesign" borderRadius='0' padding='0'/>
                            </Grid>

                            <Grid item xs={12}  mt={3}>
                                <Typography variant="h4" color={col[1]}>
                                    Data Reports - Usage Overview
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    BEFORE
                                </Typography>
                                <ImageModal image={Images.playerDashboard.before.data.leaderboard} alt="BrainTagger Dashboard data - leaderboard before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    AFTER
                                </Typography>
                                <ImageModal image={Images.playerDashboard.after.reports.points} alt="BrainTagger Dashboard reports - points after redesign" borderRadius='0' padding='0'/>
                            </Grid>

                            <Grid item xs={12}  mt={3}>
                                <Typography variant="h4" color={col[1]}>
                                    Profile
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    BEFORE
                                </Typography>
                                <ImageModal image={Images.playerDashboard.before.profile} alt="BrainTagger Dashboard profile before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1]}>
                                    AFTER
                                </Typography>
                                <ImageModal image={Images.playerDashboard.after.profile} alt="BrainTagger Dashboard profile after redesign" borderRadius='0' padding='0'/>
                            </Grid>

                            <Grid item xs={12} mt={3}>
                                <p>
                                    I also made new pages based on additional features that my team decided to include
                                </p>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[1]}>
                                    Relatives and Friends
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <ImageModal image={Images.playerDashboard.after.relatives} alt="BrainTagger Dashboard relatives after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <ImageModal image={Images.playerDashboard.after.friends} alt="BrainTagger Dashboard friends after redesign" borderRadius='0' padding='0'/>
                            </Grid>

                            <Grid item xs={12}  mt={3}>
                                <Typography variant="h4" color={col[1]}>
                                    Manage Subscription
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <ImageModal image={Images.playerDashboard.after.settings} alt="BrainTagger Dashboard settings after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <ImageModal image={Images.playerDashboard.after.subscription} alt="BrainTagger Dashboard edit subscription after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    {/* <ProjectHeading color={col[0]}>
                        Roadmap
                    </ProjectHeading>
                    <ProjectBody>
                        <p>
                            I designed the BrainTagger Dashboard as my team was still collaboratively deciding on the list of features and subscription tiers for the B2C BrainTagger product. Thus, I iteratively worked on the Dasboard's design as we made these decisions, and I tested my design with target users every few weeks. Our developer also gradually worked on implementation as I still worked on the design.
                        </p>
                        Gather Requirements -> Iterative Design & Testing -> Design System
                    </ProjectBody>

                    <ProjectHeading color={col[0]}>
                        Users
                    </ProjectHeading>
                    <ProjectBody>
                        <Grid container spacing={3}>
                            <Grid item xs={12} mt={3}>
                                <Typography variant="h4" color={col[0]}>
                                    Target Users
                                </Typography>
                                <p>
                                    The target end users (players) of the BrainTagger game, hence the target users of the BrainTagger Dashboard, are mainly aging individuals who are interested in doing gamified brain exercises. It is possible that they or their relatives would be interested in monitoring the end users' brain health. However, anyone with no significant visual, hand, and cognitive impairment can theoretically be a BrainTagger end user.
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[0]}>
                                    Key Assumptions
                                </Typography>
                                <ul>
                                    <li>
                                        Can access the BrainTagger Dashboard and game through an internet browser on a device with a desktop or tablet-sized screen
                                    </li>
                                    <li>
                                        Somewhat comfortable with logging into online accounts with a password
                                    </li>
                                    <li>
                                        Has a good understanding of English, but might not be familiar with scientific jargon
                                    </li>
                                    <li>
                                        Might have mild age-related visual impairment, but not severe enough to be considered legally blind or prevent them from playing the BrainTagger game
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color={col[0]}>
                        Gather Requirements
                    </ProjectHeading>
                    <ProjectBody>
                        <Grid container spacing={3}>
                            <Grid item xs={12} mt={3}>
                                <Typography variant="h4" color={col[0]}>
                                    User Access (based on Subscription Plans)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[0]}>
                                    BrainTagger Premium
                                </Typography>
                                Users with Premium subscription have access to all BrainTagger games and a detailed brain health report based on their game scores. They can share direct access to their performance report to up to 5 people; we call these users "Relatives", and they can have any type of BrainTagger account. They also can add an unlimited number of other BrainTagger players as "Friends", against whom the user can rank their performance on a leaderboard.
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[0]}>
                                    BrainTagger Basic
                                </Typography>
                                Users with a Basic account have access to only 3 BrainTagger games. They can only see a brief summary of their brain health report based on their scores in these 3 games. Unlike Premium users, they cannot share direct access to their performance report with "Relatives". They also cannot add other players as "Friends", because the Leaderboard is a Premium-only part of the performance report.
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[0]}>
                                    View Only
                                </Typography>
                                These users have been shared direct access to monitor a Premium user's detailed brain health report. However, View Only users do not have access to play BrainTagger games.
                            </Grid>
                            <Grid item xs={12} mt={3}>
                                <Typography variant="h4" color={col[0]}>
                                    Available Game Data
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[0]}>
                                    Other Features
                                </Typography>
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color={col[1]}>
                        Redesign Existing Dashboard
                    </ProjectHeading>
                    <ProjectBody>
                        <Grid container spacing={3}>
                            <Grid item xs={12} mt={3}>
                                (show before & after, divided in sections)
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color={col[1]}>
                        Iterative Design & Testing
                    </ProjectHeading>
                    <ProjectBody>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[1]}>
                                    Testing
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[1]}>
                                    User Flow
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[1]}>
                                    High-Fidelity Prototype
                                </Typography>
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color="secondary">
                        Design System
                    </ProjectHeading>
                    <ProjectBody>
                        asdf
                    </ProjectBody> */}

                    <ProjectBody sx={{ my: 20 }}>
                        <Typography variant="h3" color={col[2]} textAlign="center">
                            More coming soon!
                        </Typography>
                    </ProjectBody>

                    <ProjectHeading style={{ display: 'flex', justifyContent: 'flex-end'}} id="bottom">
                        <Button
                            href="/work"
                            display="flex" alignItems="center"
                            sx={{ padding:'15px 30px', marginLeft: '0px', borderRadius: '10px', border: `2px solid ${COLORS.LAVENDER}`,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)' } }}>
                                <Typography variant="h4" color="secondary">
                                    See more of my work
                                </Typography>
                        </Button>
                    </ProjectHeading>

                    {/* ROADMAP STICKY MENU */}

                    {/* {isWide?
                    <Fade appear={false} in={showRoadmap} easing={{ enter: "linear", exit: "linear" }}>
                        <MUIAppBar position="fixed" pt={2} m={0} sx={{top: 'auto', bottom: 0, zIndex: 899, boxShadow: '0px -3px 5px 0px rgb(112, 112, 112, 0.3)'}}>
                            <StyledOutlineButton fontSize="16px" padding="5px"
                                style={{position: 'fixed', top: 'auto', bottom: '25px', left: 'auto', right: '10px'}}
                                onClick={()=>setShowRoadmap(null)}
                            >
                                <SvgIcon component={CloseRoundedIcon} sx={{ fontSize:"22px", color: COLORS.LAVENDER}}/>
                            </StyledOutlineButton>

                            <Grid container spacing={-5}  display="flex" justifyContent="center" alignItems="flex-start">
                                <Grid item sm={1.6}>
                                    <SkinnyStartArrowButton label="Identify Target Users" bg={col[0]}
                                        onClick={positionMenuUsers}
                                    />
                                    <Menu
                                        id="users-menu"
                                        anchorEl={anchorElUsers}
                                        open={showRoadmapUsers}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapUsers(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#users" label="Section Top" bg={col[0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#users-primary" label="Primary Users" bg={col[0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#users-secondary" label="Secondary Users" bg={col[0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#users-tertiary" label="Tertiary Users" bg={col[0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#users-summary" label="Interviews Summary" bg={col[0]} style={{ width: '150px', marginRight: 0}}/>
                                        </Box>
                                    </Menu>
                                </Grid>
                                <Grid item sm={1.6}>
                                    <SkinnyArrowButton label="Test Website" bg={col[1]}
                                        onClick={positionMenuAnalyze}
                                    />
                                    <Menu
                                        id="analyze-menu"
                                        anchorEl={anchorElAnalyze}
                                        open={showRoadmapAnalyze}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapAnalyze(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#analyze" label="Section Top" bg={col[1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#analyze-home" label="Home Page" bg={col[1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#analyze-shop" label="Shop Page" bg={col[1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#analyze-product" label="Product Page" bg={col[1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#analyze-summary" label="Summary" bg={col[1]} style={{ width: '150px', marginRight: 0}}/>
                                        </Box>
                                    </Menu>
                                </Grid>
                                <Grid item sm={1.6} display="flex">
                                    <SkinnyArrowButton label="Design & Info Archt" bg={col[2]}
                                        onClick={positionMenuIA}
                                    />
                                    <Menu
                                        id="architecture-menu"
                                        anchorEl={anchorElIA}
                                        open={showRoadmapIA}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapIA(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#architecture" label="Section Top" bg={col[2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#architecture-categories" label="Product Categories" bg={col[2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#architecture-menu" label="Navigation Menu" bg={col[2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#architecture-home" label="Home Page" bg={col[2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#architecture-product" label="Product Page" bg={col[2]} style={{ width: '150px', marginRight: 0}}/>
                                        </Box>
                                    </Menu>
                                </Grid>
                                <Grid item sm={1.6}>
                                    <SkinnyArrowButton href="#content" label="CTA & Content" bg={col[2]}/>
                                </Grid>
                                <Grid item sm={1.6}>
                                    <SkinnyArrowButton label="User Flow" bg={col[2]}
                                        onClick={positionMenuFlow}
                                    />
                                    <Menu
                                        id="flow-menu"
                                        anchorEl={anchorElFlow}
                                        open={showRoadmapFlow}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapFlow(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#flow" label="Section Top" bg={col[2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#flow-gifting" label="Gifting Option" bg={col[2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#flow-tiers" label="Product Tiers" bg={col[2]} style={{ width: '150px', marginRight: 0 }}/>
                                        </Box>
                                    </Menu>
                                </Grid>
                                <Grid item sm={1.6}>
                                    <SkinnyArrowButton href="#hi-fi" label="Hi-Fi Prototype" bg={col[2]}/>
                                </Grid>
                                <Grid item sm={1.6}>
                                    <SkinnyArrowButton href="#system" label="Design System" bg={col[3]}/>
                                </Grid>
                            </Grid>
                        </MUIAppBar>
                    </Fade>                    
                    :
                    <Drawer
                        anchor="left"
                        open={showRoadmap}
                        width="100%"
                        variant="temporary"
                    >
                        <StyledOutlineButton fontSize="16px" padding="5px"
                                style={{position: 'fixed', top: '10px', bottom: 'auto', left: '10px', right: 'auto'}}
                                onClick={()=>setShowRoadmap(null)}
                            >
                                <SvgIcon component={CloseRoundedIcon} sx={{ fontSize:"22px", color: COLORS.LAVENDER}}/>
                        </StyledOutlineButton>
                        
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                            sx={{ height: '100%' }}
                        >
                            <DownStartArrowButton href="#users" variant="overline" label="Identify Target Users" bg={col[0]} style={{height: '15%'}}/>

                            <DownArrowButton href="#analyze" variant="overline" label="Analyze & Test Existing Website" bg={col[1]} style={{height: '15%'}} />

                            <DownArrowButton href="#architecture" variant="overline" label="Overall Design & Info Archt" bg={col[2]} style={{height: '15%'}}/>

                            <DownArrowButton href="#content" variant="overline" label="Call-to-Action & Content" bg={col[2]} style={{height: '15%'}}/>

                            <DownArrowButton href="#flow" variant="overline" label="User Flow" bg={col[2]} style={{height: '15%'}}/>

                            <DownArrowButton href="#hi-fi" variant="overline" label="High Fidelity Prototype" bg={col[2]} style={{height: '15%'}}/>

                            <DownArrowButton href="#system" variant="overline" label="Design System" bg={col[3]} style={{height: '15%'}}/>
                        </Box>
                    </Drawer>    
                } */}
                </>
            }
        </Layout>
    );
}


export default PlayerDashboard;