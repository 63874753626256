const colors = {
    LAVENDER: '#D6C4F0',
    OFF_WHITE: '#EFEFEF',
    LIGHT_GRAY: '#707070',
    MED_GRAY: '#404040',
    DARK_GRAY: '#242424',
    STORYBOARD_GRAY: '#CBCBCB',
    BLACK: '#1A1A1A',
    MINT: '#BEF3EC',
    BLUE: '#CDEEFE',
    ORANGE: '#FED9CD',
    GOLD: '#F3E5BE',
    GREEN: "#BEF3BF",
    RED: "#F3BEBE"
};

export default colors;