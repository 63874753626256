import React from "react";
import {
    Grid,
    Box,
    Typography,
    SvgIcon
} from '@mui/material';
import { projectItems, WorkItem } from "./WorkItem.js";
import { Layout } from "core";
import SouthIcon from '@mui/icons-material/South';



const Work = props => {

//   const [category, setCategory] = useState('all');

//   const handleCategory = (event, newCategory) => {
//     setCategory(newCategory);
//   };

//   const projectColors = [
//     COLORS.MINT, COLORS.GOLD, COLORS.ORANGE, COLORS.BLUE, COLORS.LAVENDER
//   ];

//   const theme = useTheme();
//   const isXS = useMediaQuery(theme.breakpoints.down('600'));

//   const { PASS, state } = props;

  return(
    <Layout>
       <Typography variant="h1" color="secondary">
            I am a Frontend Developer
        </Typography>
        <p style={{marginBottom: '50px'}}>
            <Typography variant="h3" color="primary">
                with UX Design experience.
            </Typography>
        </p>
        <p>
            <Typography variant="h4" color="primary">
                Currently looking for a Frontend or UX Developer job.
            </Typography>
        </p>
        <Typography variant="body2" color="primary">
            <Box display="flex" alignItems="center" sx={{ flexWrap: 'wrap' }}>
                See examples of my Design work below&nbsp;
                <SvgIcon component={SouthIcon}
                    sx={{ fontSize: '22px' }}
                />
            </Box>
        </Typography>

        
        {/* <StyledToggleButtonGroup
        value={category}
        exclusive
        onChange={handleCategory}
        aria-label="project categories"
        orientation={isXS? 'vertical': 'horizontal'}
        sx={{ mt: 8, mx:{sm:0, xs: 1}, display: 'flex', justifyItems:{sm: 'flex-start', xs: 'center'} }}>
            <ToggleButton value="all" aria-label="all">
                    All Projects
            </ToggleButton>
            <ToggleButton value="ux" aria-label="ux">
                    UX/UI Design
            </ToggleButton>
            <ToggleButton value="dev" aria-label="dev">
                    Development
            </ToggleButton>
        </StyledToggleButtonGroup> */}

        <Box pt={6}>

            <Grid container spacing={15} alignItems="stretch">
                {projectItems.map((item, i) =>
                    <WorkItem key={i} projectItem={item} {...props}/>)
                }
            </Grid>

            {/* <Grid container spacing={15} alignItems="stretch">
                {category==='all'?
                    projectItems.map((item, i) =>
                        <WorkItem key={i} projectItem={item}/>)
                    :
                    category==='ux'?
                        projectItems.map((item, i) =>
                            item.category==='ux' &&
                            <WorkItem key={i} projectItem={item} />)
                        :
                        projectItems.map((item, i) =>
                            item.category==='dev' &&
                            <WorkItem key={i} projectItem={item} />)
                    
                }
            </Grid> */}

        </Box>
    </Layout>
  );
};

export default Work;
