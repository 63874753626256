import React, { useState } from "react";
import "./lightbox.css";
import Lightbox from "react-image-lightbox";
import { Thumbnail } from "assets";



export function ImageModal(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Thumbnail src={props.image} width={props.thumbnailWidth} height={props.height} alt={props.alt} cursor="pointer" onClick={ () => setShowModal(true)} {...props}/>
      
      {showModal && (
        <Lightbox
          mainSrc={props.image}
          onCloseRequest={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export function TextLinkModal(props){
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <a onClick={ () => setShowModal(true)} {...props}>{props.text}</a>
      
      {showModal && (
        <Lightbox
          mainSrc={props.image}
          onCloseRequest={() => setShowModal(false)}
        />
      )}
    </>
  );

}
