import { styled } from '@mui/material/styles';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { COLORS } from "core";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      margin: theme.spacing(0),
      padding: '12px 30px',
      fontSize: '18px',
      fontWeight: 700,
      color: COLORS.LAVENDER,
      backgroundColor: COLORS.MED_GRAY,
      "&:hover":{
        color: COLORS.DARK_GRAY,
        backgroundColor: COLORS.LAVENDER
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        color: COLORS.DARK_GRAY,
        backgroundColor: COLORS.LAVENDER
      },
    },
  }));