import React, { useState } from 'react';
import bcrypt from 'bcryptjs';
import { 
    Typography,
    Dialog,
    DialogContentText,
    Grid,
    TextField,
    SvgIcon,
    InputAdornment,
    IconButton
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { StyledButton, StyledOutlineButton, COLORS } from "core";


function PasswordPrompt(props) {
    const [input, setInput] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const { state, setStep, handlePasswordChange, PASS } = props;


    const handleInputChange = (event) => {
        setInput(event.target.value);
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    
    if (state.step===1 && !bcrypt.compareSync(state.password, PASS)){
        return (
            <Dialog
                open = {state.step===1}>
            
                <Grid container
                    display="flex"
                    justifyContent="center"
                    spacing={3}
                    mt={1}
                    mb={4}
                    >
                    <Grid item
                        xs={10}
                        display="flex"
                        justifyContent="flex-end"
                        textAlign="center"
                        mb={3}
                    >
                        <StyledOutlineButton
                            fontSize="18px"
                            lineHeight="18px"
                            padding="5px 5px"
                            onClick={() => {
                                setStep(-1);
                            }}
                        >
                            <SvgIcon component={CloseRoundedIcon} sx={{ color: COLORS.LAVENDER }} />
                        </StyledOutlineButton>
                    </Grid>
                    <Grid item
                        xs={10}
                        display="flex"
                        justifyContent="center"
                        textAlign="center"
                    >
                        <DialogContentText color="primary" variant="body">
                            Enter password to access my semi-confidential projects:
                        </DialogContentText>
                    </Grid>
                    <Grid item
                        xs={8}
                        display="flex"
                        justifyContent="center"
                        mb={3}
                    >
                        <TextField
                            autoFocus
                            margin="normal"
                            id="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            variant="standard"
                            value={input}
                            onChange={handleInputChange}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ?
                                        <SvgIcon component={VisibilityIcon} sx={{ color: COLORS.LAVENDER }} />
                                      : 
                                        <SvgIcon component={VisibilityOffIcon} sx={{ color: COLORS.LAVENDER }} />
                                    }
                                    </IconButton>
                                  </InputAdornment>
                                )
                            }}
                            sx={{ input: { color: COLORS.LAVENDER } }}
                            InputLabelProps={{
                                style: { color: COLORS.OFF_WHITE },
                            }}
                        />
                    </Grid>
                    <Grid item
                        xs={5}
                        mb={2}
                        display="flex"
                        justifyContent="center"
                    >
                        <StyledButton
                            fontSize="18px"
                            lineHeight="18px"
                            onClick={() => {
                                handlePasswordChange(input);
                                if(!bcrypt.compareSync(state.password, PASS)){
                                    setStep(2);
                                }else{
                                    setStep(-1);
                                }
                            }}
                        >
                            Submit
                        </StyledButton>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }else if(state.step===2 && !bcrypt.compareSync(state.password, PASS)){
        return (
            <Dialog
                open = {state.step===2}>
                <Grid container
                    display="flex"
                    justifyContent="center"
                    spacing={3}
                    mt={1}
                    mb={4}
                >
                    <Grid item
                        xs={10}
                        display="flex"
                        justifyContent="flex-end"
                        textAlign="center"
                        mb={3}
                    >
                        <StyledOutlineButton
                            fontSize="18px"
                            lineHeight="18px"
                            padding="5px 5px"
                            onClick={() => {
                                setStep(-1);
                            }}
                        >
                            <SvgIcon component={CloseRoundedIcon} sx={{ color: COLORS.LAVENDER }} />
                        </StyledOutlineButton>
                    </Grid>

                    <Grid item
                        xs={10}
                        display="flex"
                        justifyContent="center"
                        textAlign="center"
                        mb={3}
                    >
                        <DialogContentText color="primary" variant="body1">
                            The password you entered was incorrect
                        </DialogContentText>
                    </Grid>
                    <Grid item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                    >
                        <StyledButton
                            fontSize="18px"
                            lineHeight="18px"
                            onClick={() => {
                                setStep(1);
                            }}
                        >
                            Try Again
                        </StyledButton>
                    </Grid>
                    <Grid item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        textAlign="center"
                        >
                        <Typography variant="body1" color="primary">
                            <p>
                                OR
                            </p>
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={10} mb={2} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', flexWrap: 'wrap', textAlign: "center",
                        transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.1, 1.1, 1)"} }}
                    >     
                        <a href="mailto:ksondjaja@gmail.com" style={{ textDecoration:"none", display: 'flex', alignItems: 'center', justifyContent:'center', flexWrap: 'wrap'}}>
                            <SvgIcon component={EmailIcon} sx={{ marginRight: '15px', fontSize: '32px', color: COLORS.LAVENDER }} />
                            Email me
                        </a>
                        <Typography variant="body1" color="primary">
                            &nbsp; to get access to my password-protected projects
                        </Typography>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }else{
        return(
            <></>
        );
    };
};

export default PasswordPrompt;