import React from "react";
import {
    Box,
    Typography,
    Card,
    Paper
} from '@mui/material';
import { COLORS } from "core";
import { Thumbnail } from "assets";

export const B = (props) => (
    <b style={{ color: props.col }} {...props}></b>
)

export const ProjectHeader = (props) => (
    <Box sx={{ marginBottom: "50px" }} bgcolor={props.color? props.color : "secondary"} {...props}>
    </Box>
);

export const ProjectTitle = (props) => (
    <Typography variant="h1" color={props.color? props.color : "secondary"} {...props}>
    </Typography>
);

export const ProjectDates = (props) => (
    <Typography sx={{ marginBottom: '20px'}} variant="body1" color="primary" {...props}>
    </Typography>
);

export const ProjectDescription = (props) => (
    <Typography variant="body2" color="primary"  sx={{ marginBottom: '70px'}} {...props}>
    </Typography>
);

export const ProjectHeading = (props) => (
    <Box sx={{pt: props.narrow ? 5 : 15 }} id={props.id}>
        <Typography variant="h3" color={props.color? props.color : "secondary"} sx={{mb: 2}} {...props}>
        </Typography>
    </Box>
);

export const ProjectBody = (props) => (
    <Box sx={{ borderBottom: props.border?`1px solid ${COLORS.MED_GRAY}`:'none' }}>
        <Typography variant="body1" color="primary" sx={{mb: props.border? 15 : 0 }} {...props}>
        </Typography>
    </Box>
);

export const ProjectFullWidthImage = (props) => (
    <Box bgcolor="secondary.main" sx={{ padding: "2.5%", margin:"50px 0 50px 0", borderRadius: "1vw" }} {...props}>
        <Thumbnail src={props.image} />
    </Box>
);

export const OutcomeCard = (props) => (
    <Paper elevation={10} sx={{ backgroundColor: props.bg, color: COLORS.BLACK, p:3, width:"100%", borderRadius: '15px' }} {...props}></Paper>
)

export const QuoteCard = (props) => (
    <Paper elevation={10} sx={{ backgroundColor: COLORS.DARK_GRAY, mx:'5%', my: 1, p:3, width:"90%", borderRadius: '15px' }} {...props}>
        <Box>
            <Typography variant="body2" color={props.col}>
                <b style={{ color:props.col }}><i>"{props.quote}"</i></b>
            </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
            <Typography variant="body2" color={props.col}>
                <b style={{ color:props.col }}>-{props.user}</b>
            </Typography>
        </Box>
    </Paper>
)