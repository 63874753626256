import React, { useState,
    // useRef, useEffect
} from "react";
import bcrypt from 'bcryptjs';
import { 
    B,
    Layout,
    Private,
    ProjectTitle,
    ProjectDates,
    ProjectDescription,
    ProjectHeading,
    ProjectBody,
    ImageModal,
    TextLinkModal,
    QuoteCard,
    OutcomeCard,
    LinkButton,
    StyledAccordion,
    StyledAccordionSummary,
    StyledAccordionDetails,
    SkinnyButton,
    SkinnyStartArrowButton,
    SkinnyArrowButton,
    StartArrowButton,
    ArrowButton,
    DownStartArrowButton,
    DownArrowButton,
    StyledButton,
    StyledOutlineButton,
    ThumbnailButton,
    COLORS } from "core";
import {
    Grid,
    Box,
    Typography,
    Fade,
    Button,
    SvgIcon,
    Menu,
    Drawer,
    IconButton,
    Paper
} from '@mui/material';
import { MUIAppBar } from "../Nav";
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Images, Thumbnail } from "assets";
import { Search,
         SportsEsports,
         CardGiftcard,
         Schema,
         PanToolAlt,
         Edit,
         DoubleArrow,
         Category,
         HomeWork,
         WheelchairPickup,
         Elderly,
         CloseRounded,
         ExpandLess,
         ExpandMore,
         Person,
         SouthRounded,
         EastRounded,
         Visibility,
         Psychology,
         MarkEmailRead,
         Interests
} from '@mui/icons-material';


const ShopifyStore = props => {

    // const myRef = useRef(null);

    const [scrollLoc, setScrollLoc] = useState(null);

    const [showRoadmap, setShowRoadmap] = useState(null);

    const [anchorElUsers, setAnchorElUsers] = React.useState(null);
    const [showRoadmapUsers, setShowRoadmapUsers] = useState(null);

    const [anchorElP1, setAnchorElP1] = React.useState(null);
    const [showRoadmapP1, setShowRoadmapP1] = useState(null);

    const [anchorElP2, setAnchorElP2] = React.useState(null);
    const [showRoadmapP2, setShowRoadmapP2] = useState(null);

    const [anchorElP3, setAnchorElP3] = React.useState(null);
    const [showRoadmapP3, setShowRoadmapP3] = useState(null);

    const [anchorElP4, setAnchorElP4] = React.useState(null);
    const [showRoadmapP4, setShowRoadmapP4] = useState(null);

    const theme = useTheme();
    const isWide = useMediaQuery(theme.breakpoints.up('780'));

    const col =[
        COLORS.MINT,
        ['#b2edf5', '#a1d1d7', '#91b5ba'],
        ['#afe5fc', '#a2ccde', '#95b3c1'],
        ['#b6dbff', '#a7c7e5', '#98b3cb', '#8a9fb2'],
        ['#d6deff', '#c0caed', '#b4bddc', '#a8b0cc'],
        COLORS.LAVENDER,
    ]

    const skills = ['Prototyping', 'Usability Testing', 'UX Writing', 'Design System', 'Figma', 'User Interview', 'Card Sorting'];

    const positionMenuUsers= (event: any) => {
        setAnchorElUsers(event.currentTarget);
        setShowRoadmapUsers(true);
    }

    const positionMenuP1= (event: any) => {
        setAnchorElP1(event.currentTarget);
        setShowRoadmapP1(true);
    }

    const positionMenuP2 = (event: any) => {
        setAnchorElP2(event.currentTarget);
        setShowRoadmapP2(true);
    }

    const positionMenuP3 = (event: any) => {
        setAnchorElP3(event.currentTarget);
        setShowRoadmapP3(true);
    }

    const positionMenuP4 = (event: any) => {
        setAnchorElP4(event.currentTarget);
        setShowRoadmapP4(true);
    }


    // FOR RECOGNIZING ID WHILE SCROLLING

    // https://medium.com/the-non-traditional-developer/how-to-use-an-intersectionobserver-in-a-react-hook-9fb061ac6cb5

    // https://www.youtube.com/watch?v=r1auJEf9ISo&ab_channel=ColbyFayock

    // Error: Failed to execute 'observe' on 'IntersectionObserver': parameter 1 is not of type 'Element'

    // window.addEventListener('scroll', function() {
    //     var element = document.querySelector('#users');
    //     var position = element.getBoundingClientRect();
    
    //     // checking for partial visibility
    //     if(position.top < window.innerHeight && position.bottom >= 0) {
    //         console.log('#users');
    //     }
    // });

    // useEffect(() => {
    //     const observer = new IntersectionObserver((entries)=>{
    //         const entry = entries[0];
    //         console.log('entry', entry)
    //     })

    //     observer.observe(myRef.current);
    // }, []);

    // useEffect(() => {
    //     const handleScroll = (event) => {
    //       console.log(myRef.current.id);
    //     };
    
    //     window.addEventListener('scroll', handleScroll);
    
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return(
        <Layout>
            {bcrypt.compareSync(props.state.password, props.PASS) &&
                <>
                    <Box sx={{ display:'flex', flexDirection: 'column', alignItems: 'center', position: 'fixed', top: 'auto', bottom: '10px', left: 'auto', right: '20px', zIndex: 899,}}>                       
                        <StyledButton fontSize="16px" padding="5px 10px"
                            onClick={()=>setShowRoadmap(true)}
                        >
                            Jump to...
                        </StyledButton>

                        {/* <Typography variant="body2" color="primary">
                            scrollLoc: {scrollLoc}
                        </Typography> */}

                    </Box>
                    <Box sx={{ display:'flex', flexDirection: 'column', position: 'fixed', top: '45%', bottom: 'auto', left: 'auto', right: '20px', zIndex: 899,}}>
                        <IconButton href="#" aria-label="scroll-to-top" color="secondary" size="small"
                            sx={{ display:'flex', flexDirection: 'column', py:1,
                            mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                        >
                            <ExpandLess fontSize="inherit" sx={{mb:-3}}/>
                            <ExpandLess fontSize="inherit" />
                        </IconButton>

                        {/* <IconButton href="#" aria-label="scroll-up" color="secondary" size="small"
                            sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                        >
                            <ExpandLess fontSize="inherit" />
                        </IconButton>

                        <IconButton href="#bottom" aria-label="scroll-down" color="secondary" size="small"
                                sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                                boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                                '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                        >
                            <ExpandMore fontSize="inherit" />
                        </IconButton> */}

                        <IconButton href="#bottom" aria-label="scroll-to-top" color="secondary" size="small"
                            sx={{ display:'flex', flexDirection: 'column', py:1,
                            mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                        >
                            <ExpandMore fontSize="inherit" sx={{mb:-3}}/>
                            <ExpandMore fontSize="inherit" />
                        </IconButton>      
                    </Box>

                    <Box mb={4} display="flex" sx={{ width: '100%' }}>
                        <Box bgcolor={ COLORS.MINT } display="flex" alignItems="center" justifyContent="center"
                            sx={{ borderRadius: '1vw', width:'100%' }}
                        >
                            <Thumbnail src={Images.shopifyStore.thumbnail} width="95%" alt="Healthy Age Mart desktop home page"/>
                        </Box>
                    </Box>
                </>
            }

            <ProjectTitle color={col[0]}>
                Healthy Age Mart<br/>
                <Typography variant="h2" color={col[0]}>
                    Centivizer's Shopify Store
                </Typography>
            </ProjectTitle>
            
            <ProjectBody style={{ marginTop: '20px', marginBottom: '10px' }}>
                <p>
                    <Typography variant="h4" color="primary">
                        Implemented Website:
                    </Typography>
                    <a href="https://healthyagemart.com/" target="_blank" rel="noreferrer" style={{color: col[0]}}>
                        Healthy Age Mart
                    </a>
                    &nbsp;(under construction)
                </p>
                <br/>
                {skills.map(skill=>{
                    return(
                        <Box display="inline-block" backgroundColor={COLORS.BLACK} px={1.5} py={0.5} mr={1.5} mb={1.5}
                            sx={{ borderRadius:'5px', border: `1px solid ${COLORS.MINT}` }}>
                            <Typography variant="body1">
                                <span style={{ fontSize: '16px', fontWeight: '700', color: COLORS.MINT }}>
                                    {skill}
                                </span>
                            </Typography>
                        </Box>
                    );
                })}
                
            </ProjectBody>

            <ProjectDates>
                UX/UI Design paid work<br/>
                October 2022 - present (part-time)<br/>
            </ProjectDates>
            <br/><br/>
            {!bcrypt.compareSync(props.state.password, props.PASS)?
                <Private {...props} />
                :
                <>
                    <ProjectHeading color={col[0]} narrow id="overview">
                        Problem Space
                    </ProjectHeading>
                    <ProjectDescription>
                        <p>
                            Centivizer wanted to <B col={col[0]}>sell to customers online accounts for their original game, BrainTagger,</B> and various healthy aging products through <B col={col[0]}>Healthy Age Mart, their Shopify store</B>. The company is creating B2C BrainTagger based on an older B2B version. After purchasing BrainTagger, customers should be able to <B col={col[0]}>manage their accounts & subscriptions entirely online</B>.
                        </p>
                        <p>
                            Previous developers have started building Healthy Age Mart, and <B col={col[0]}>I tested this website with users</B>. Users <B col={col[0]}>did not respond the way Centivizer expected</B>, because parts of the website <B col={col[0]}>did not align with user needs and interests</B>:
                        </p>
                        <br/>
                          
                            <ThumbnailButton href="#discovery">
                                <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                                    <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                                            <SvgIcon component={Search} sx={{ fontSize:{ xs: "50px"}, color: col[1][0]}}/>
                                        </Grid>
                                        <Grid item xs={10} mt={1}>
                                            <Typography variant="h4" color={col[1][0]}>
                                                Problem 1: Discover
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                <B col={col[1][0]}>Users could not easily find BrainTagger</B>, the main product Centivizer wants to sell.
                                                
                                                <p>
                                                    <B col={col[1][0]}>Solution:</B><br/>
                                                    Structured home page to lead users to BrainTagger product page.
                                                </p>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ThumbnailButton>
                            <br/><br/>
                            <ThumbnailButton href="#understand">
                                <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                                    <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                                            <SvgIcon component={SportsEsports} sx={{ fontSize:{ xs: "50px"}, color: col[2][0]}}/>
                                        </Grid>
                                        <Grid item xs={10} mt={1}>
                                            <Typography variant="h4" color={col[2][0]}>
                                                Problem 2: Understand Value
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                Users <B col={col[2][0]}>misunderstood</B> what BrainTagger offers, how to play it, and how it is delivered.
                                                
                                                <p>
                                                    <B col={col[2][0]}>Solution:</B><br/>
                                                    Worked on BrainTagger page & demo site structure and content to emphasize key concepts and features.
                                                </p>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ThumbnailButton>
                            <br/><br/>
                            <ThumbnailButton href="#delivery">
                            <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                                    <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                                            <SvgIcon component={CardGiftcard} sx={{ fontSize:{ xs: "50px"}, color: col[3][0]}}/>
                                        </Grid>
                                        <Grid item xs={10} mt={1}>
                                            <Typography variant="h4" color={col[3][0]}>
                                                Problem 3: Gift & Receive
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                <B col={col[3][0]}>Customers could not buy BrainTagger for another user</B>, even though this is a big part of Centivizer's sales & marketing plan
                                                
                                                <p>
                                                    <B col={col[3][0]}>Solution:</B><br/>
                                                    Designed user flow for digital delivery that aligns with business goals and implementability.
                                                </p>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ThumbnailButton>
                            <br/><br/>
                            <ThumbnailButton href="#organization">
                            <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                                    <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                        <Grid item xs={1} display="flex" justifyContent="flex-end">
                                            <SvgIcon component={Schema} sx={{ fontSize:{ xs: "50px"}, color: col[4][0]}}/>
                                        </Grid>
                                        <Grid item xs={10} mt={1}>
                                            <Typography variant="h4" color={col[4][0]}>
                                                Problem 4: Browse (Info Architecture)
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                <B col={col[4][0]}>Users felt lost browsing</B> because of <B col={col[4][0]}>unfamiliar names, concepts, and categorization</B>.
                                                
                                                <p>
                                                    <B col={col[4][0]}>Solution:</B><br/>
                                                    Redesigned menu and product tags to better fit user interests and mental model.
                                                </p>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </ThumbnailButton>

                    </ProjectDescription>
                    
                    <ProjectHeading color={col[0]} narrow>
                        Constraints
                    </ProjectHeading>
                    <ProjectBody>
                        The website needs to be built with <B col={col[0]}>Shopify and its various add-on features on a low budget</B>. When a user buys BrainTagger through Shopify, their data needs to enter Centivizer's own <B col={col[0]}>user database</B>.
                    </ProjectBody>

                    <ProjectHeading color={col[0]} narrow>
                        Team
                    </ProjectHeading>
                    <ProjectBody>
                        <p>
                            I collaborated with:
                            <ul>
                                <li>A Shopify developer for Healthy Age Mart</li>
                                <li>A developer (who is also the project manager) for BrainTagger</li>
                                <li>A visual asset designer, who creates illustrations</li>
                                <li>Head project manager</li>
                                <li>Centivizer's CEO</li>
                            </ul>
                        </p>
                    </ProjectBody>

                    <ProjectHeading color={col[0]} narrow>
                        My Role
                    </ProjectHeading>
                    <ProjectBody>
                        <ul>
                            <li>
                                <B col={col[0]}>Designed and tested 5 iterations*</B>, and worked within Shopify's <B col={col[0]}>framework and design system</B>
                            </li>
                            <li>
                                <B col={col[0]}>Collaborated with two developers</B> to understand and work around technical limitations
                            </li>
                            <li>
                                Planned and conducted <B col={col[0]}>interviews, card-sorting, and usability tests</B>
                            </li>
                            <li>
                                Used research and test findings to <B col={col[0]}>help visual asset designer create new illustrations</B> for Healthy Age Mart
                            </li>
                            <li>
                                Worked with <B col={col[0]}>project manager and CEO to plan</B> B2C BrainTagger and <B col={col[0]}>effectively communicate</B> it to Healthy Age Mart users
                            </li>
                        </ul>
                    </ProjectBody>


                    <ProjectHeading color={col[0]} id="roadmap" narrow>
                        Roadmap
                    </ProjectHeading>
                    <ProjectBody border>
                        <p>
                            After identifying main business goals and target users, I <B col={col[0]}>tested the old Healthy Age Mart</B> with representative users. Then I <B col={col[0]}>redesigned & tested prototypes through 5 iterations*</B>, working <B col={col[0]}>in parallel</B> to my team's planning of the new <B col={col[0]}>B2C BrainTagger</B>, the main product to be sold on Healthy Age Mart. Our Shopify developer also worked on Healthy Age Mart's <B col={col[0]}>implementation as I refined the design</B>, thus the implementated work in progress is already online.
                        </p>
                        <p>
                            <B col={col[0]}>*</B>In this case study, I show only iterations with key solutions & findings for each problem area.
                        </p>
                        <br/>
                        {isWide?

                            <Grid container spacing={-5} display="flex" alignItems="center">
                                <Grid item xs={2}>
                                    <StartArrowButton href="#overview" label="Identify Business Goals" bg={col[0]} />
                                </Grid>
                                <Grid item xs={2}>
                                    <ArrowButton href="#users" label="Identify Target Users" bg={col[0]} fit="fill"/>
                                </Grid>
                                <Grid item xs={6}>
                                <Box sx={{ width: '100%', position:'relative' }}>
                                    <svg id="arrow-button-icon" viewBox="0 0 750 300" xmlns="http://www.w3.org/2000/svg"
                                        style={{ position: 'absolute', objectFit: 'contain', display:"flex" }}>
                                        <path d="M1.23607 14.4721C-2.08843 7.82313 2.74652 0 10.1803 0h568.32C672.107 0 675.57 2.14002 677.264 5.52786L747.264 145.528C748.672 148.343 748.672 151.657 747.264 154.472L677.264 294.472C675.57 297.86 672.107 300 668.32 300H10.1804C2.74656 300 -2.08844 292.177 1.23607 285.528L66.7639 154.472C68.1716 151.657 68.1716 148.343 66.7639 145.528L1.23607 14.4721Z"
                                            fill='none' stroke={col[3][0]} stroke-width="4" />
                                    </svg>
                                </Box>

                                    <Grid container spacing={0.5} px={0.5} ml={3} mr={5} sx={{width: '90%', objectFit: "contain" }} display="flex" alignItems="center" justifyContent="center">
                                        <Grid item xs={4}>
                                            <SkinnyButton href="#discovery" label="Test Prob 1" bg={col[1][2]} padding="5px"/>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <SkinnyButton href="#discovery-then" label="Design, Test, Repeat" bg={col[1][0]} padding="5px"/>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <SkinnyButton href="#understand" label="Test Prob 2" bg={col[2][2]} padding="5px"/>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <SkinnyButton href="#understand-then" label="Design, Test, Repeat" bg={col[2][0]} padding="5px"/>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <SkinnyButton href="#delivery" label="Test Prob 3" bg={col[3][3]} padding="5px"/>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <SkinnyButton href="#delivery-then" label="Design, Test, Repeat" bg={col[3][0]} padding="5px"/>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <SkinnyButton href="#organization" label="Test Prob 4" bg={col[4][3]} padding="5px"/>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <SkinnyButton href="#organization-then" label="Design, Test, Repeat" bg={col[4][0]} padding="5px"/>
                                        </Grid>   
                                    </Grid>    
                                </Grid>
                                <Grid item xs={2} ml={-3}>
                                    <ArrowButton href="#handover" label="Handover to Developers" bg={col[5]}/>
                                </Grid>
                            </Grid>
                        :
                            <Grid container spacing={-5} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Grid item xs={7}>
                                    <DownStartArrowButton href="#overview" label="Identify Business Goals" bg={col[0]}/>
                                </Grid>
                                <Grid item xs={7}>
                                    <DownArrowButton href="#users" label="Identify Target Users" bg={col[0]}/>
                                </Grid>
                                <Grid item xs={7} my={5} mr={{sm:0, xs:5}} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                    <Grid container spacing={{sm:4, xs: 7}} mb={1} display="flex" alignItems="center" justifyContent="center">
                                        <Grid item xs={3} >
                                            <SkinnyButton href="#discovery" label="Identify Prob 1" bg={col[1][2]} height="auto" width="auto" padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SkinnyButton href="#understand" label="Identify Prob 2" bg={col[2][2]} height="auto" width="auto" padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SkinnyButton href="#delivery" label="Identify Prob 3" bg={col[3][3]} height="auto" width="auto" padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SkinnyButton href="#organization" label="Identify Prob 4" bg={col[4][3]} height="auto" width="auto" padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={{sm:4, xs: 7}} display="flex" alignItems="center" justifyContent="center">
                                        <Grid item xs={3}>
                                            <SkinnyButton href="#discovery-then" label="Design, Test, Repeat" bg={col[1][0]} height="auto" width="auto"  padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SkinnyButton href="#understand-then" label="Design, Test, Repeat" bg={col[2][0]} height="auto" width="auto" padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SkinnyButton href="#delivery-then" label="Design, Test, Repeat" bg={col[3][0]} height="auto" width="auto" padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <SkinnyButton href="#organization-then" label="Design, Test, Repeat" bg={col[4][0]} height="auto" width="auto" padding="5px" style={{writingMode: "vertical-rl", textOrientation: "mixed" }}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={7}>
                                    <DownArrowButton href="#handover" label="Handover to Developers" bg={col[5]}/>
                                </Grid>
                            </Grid>
                        }
                        
                    </ProjectBody>
                    
                    <ProjectHeading color={col[0]} id="users">
                        Identify Target Users
                    </ProjectHeading>
                    <ProjectBody border>
                        <p>
                            I identified & recruited <B col={col[0]}>target users</B> of Healthy Age Mart based on Centivizer's business goals. Then I <B col={col[0]}>tested Healthy Age Mart designs and B2C BrainTagger ideas</B> with these users.
                        </p>

                        <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs: "column"}} alignItems="center">
                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <SvgIcon component={Elderly} sx={{ fontSize:{ xs: "100px"}, color: col[1][0]}}/>
                            </Grid>
                            <Grid item xs={10} id="users-primary" mb={{ sm: 0, xs:2 }}>
                                <p>
                                    <Typography variant="h4" color={col[1][0]}>
                                        Primary: Aging End Users
                                    </Typography>
                                </p>
                                <p>
                                    Although users of any age can play B2C BrainTagger, our primary target users are <B col={col[1][0]}>aging individuals</B> who might be interested in buying products from Healthy Age Mart <B col={col[1][0]}>for themselves</B>. I recruited <B col={col[1][0]}>3 Primary Users (P1, P2, P3)</B>, who are in or approaching senior age. Through them, I found that:
                                    <ul>
                                        <li>
                                            Some retirees enjoy playing <B col={col[1][0]}>casual games to fill their time</B>, primarily on mobile devices.
                                        </li>
                                        <li>
                                            Many are <B col={col[1][0]}>comfortable with online shopping</B>, and <B col={col[1][0]}>take their time</B> to interpret online content.
                                        </li>
                                        <li>
                                            They want text to have <B col={col[1][0]}>bigger fonts and higher contrast</B> for easier reading.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>


                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <SvgIcon component={WheelchairPickup} sx={{ fontSize:{ xs: "100px"}, color: col[2][0]}}/>
                            </Grid>
                            <Grid item xs={10} id="users-secondary" mb={{ sm: 0, xs:2 }}>
                                <p>
                                    <Typography variant="h4" color={col[2][0]}>
                                        Secondary: Relatives or Caretakers of Primary Users
                                    </Typography>
                                </p>
                                <p>
                                    <B col={col[2][0]}>Younger individuals</B> who might want to buy BrainTagger or other products on Healthy Age Mart to <B col={col[2][0]}>gift to an aging relative</B> or friend. I recruited <B col={col[2][0]}>3 Secondary Users (S1, S2, S3)</B> in their late 20s and early 30s. I found that:
                                    <ul>
                                        <li>
                                            Some of them tend to <B col={col[2][0]}>skim thorugh visual elements</B> on webpages without reading all the text.
                                        </li>
                                        <li>
                                            They want to see a <B col={col[2][0]}>demonstration</B> of how a product could <B col={col[2][0]}>benefit their older relatives</B> before gifting it to them.
                                        </li>
                                        <li>
                                            They said that their <B col={col[2][0]}>older relatives (aged 80-90)</B> like to <B col={col[2][0]}>read text messages & emails thoroughly</B>, and they are comfortable with only a few simple apps.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>

                            <Grid item xs={2} display="flex" justifyContent="flex-end">
                                <SvgIcon component={HomeWork} sx={{ fontSize:{ xs: "100px"}, color: col[3][0]}}/>
                            </Grid>
                            <Grid item xs={10} id="users-tertiary" mb={{ sm: 3, xs: 5 }}>
                                <p>
                                    <Typography variant="h4" color={col[3][0]}>
                                        Tertiary: Long-Term Care Homes, Researchers, and Organizations
                                    </Typography>
                                </p>
                                <p>
                                    It is also possible that <B col={col[3][0]}>organizations would learn about Centivizer products through Healthy Age Mart</B>. Centivizer wants to redirect them to contact us directly for B2B solutions. I recruited <B col={col[3][0]}>1 Tertiary User (T1)</B>. I learned that when considering to acquire B2B recreational products, long-term care homes might:
                                    <ul>
                                        <li>
                                            Need to know their <B col={col[3][0]}>technological requirements</B>,
                                        </li>
                                        <li>
                                            Look for the products' <B col={col[3][0]}>social and health benefits</B>, and
                                        </li>
                                        <li>
                                            <B col={col[3][0]}>Research other clients' experiences</B> with the products' makers.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[4][0]}>
                                    Key User Assumptions
                                </Typography>
                                <p>
                                    There are also some factors that I needed to assume about a user of Healthy Age Mart:
                                </p>
                                <ul>
                                    <li>
                                        Has a good understanding of English, but <B col={col[4][0]}>might not be familiar with scientific/academic jargons</B>
                                    </li>
                                    <li>
                                        Somewhat familiar with browsing and buying items on an <B col={col[4][0]}>online store</B>, such as Amazon, and access to <B col={col[4][0]}>digital payment methods</B>
                                    </li>
                                    <li>
                                        Somewhat familiar with <B col={col[4][0]}>digital products and subscription plans</B>
                                    </li>
                                    <li>
                                        Quite comfortable with <B col={col[4][0]}>signing up and logging into online accounts</B> using a password
                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </ProjectBody>


                    <ProjectHeading color={col[1][2]} id="discovery">
                        Problem 1: Discover BrainTagger
                    </ProjectHeading>
                    <ProjectBody border>
                        <Grid container spacing={5} display="flex" justifyContent="center">
                            <Grid item xs={12} mb={-3} mt={3}>
                                <p>
                                    Centivizer's main goal right now is to sell BrainTagger through Healthy Age Mart. However, tested users had <B col={col[1][2]}>difficulties finding BrainTagger or did not immediately notice it</B>.
                                </p>
                            </Grid>
                            <Grid item xs={12} mb={-1} mt={3} id="discovery-before">
                                <Typography variant="h4" color={col[1][2]}>
                                    BEFORE REDESIGN (Test Existing Website)
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[1][2]}>
                                    Home Page - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem1.before.page} alt="Healthy Age Mart Home Page before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <QuoteCard
                                    quote="Not very clear what kind of products are being sold... There's a lack of focus."
                                    user="P1"
                                    col={col[1][2]}
                                />
                                <br/>
                                <Typography variant="h5" color={col[1][2]}>
                                    Problems:
                                </Typography>
                                <ul>
                                    <li>
                                        BrainTagger was <B col={col[1][2]}>not immediately visible to users upon landing</B>.
                                    </li>
                                    <li>
                                        Users must click <B col={col[1][2]}>"Shop"</B> on the menu or <B col={col[1][2]}>"Active Aging with Centivizer"</B> category <B col={col[1][2]}>before they see BrainTagger</B> in the product listing. (See user flow).
                                    </li>
                                    <li>
                                        New users wanted to understand what the store offers, but the introduction paragraph <B col={col[1][2]}>revolved around what Healthy Age Mart ("we") does</B>, instead of addressing <B col={col[1][2]}>how the user ("you") might benefit</B> from the store's products.
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item sm={8} xs={12}>
                                <Typography variant="h5" color={col[1][2]} textAlign="center">
                                    Discover BrainTagger User Flow - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem1.before.userFlow} alt="Healthy Age Mart BrainTagger Discovery User Flow before redesign"/>
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[1][2], marginBottom:'-10px' }} />
                            </Grid>

                            <Grid item xs={12} mb={-1} mt={3} id="discovery-then">
                                <Typography variant="h4" color={col[1][1]}>
                                    EARLY ITERATION (with Shopify's Taste Theme)
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1][1]}>
                                    Home Page - Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem1.then.top} alt="Healthy Age Mart Home Page early Shopify redesign" borderRadius='0' padding='0'/>
                                <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                                    <SvgIcon component={SouthRounded} sx={{ fontSize: '20px', color: col[1][1] }}/> <Typography variant="body1" color={col[1][1]}>scroll down</Typography>
                                </Box>
                                <ImageModal image={Images.shopifyStore.problem1.then.bottom} alt="Healthy Age Mart Home Page early Shopify redesign - bottom" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[1][1]}>
                                    Tested Solutions:
                                </Typography>
                                <ul>
                                    <li>
                                        The <B col={col[1][1]}>large BrainTagger banner</B> I added drew <B col={col[1][1]}>3 out of 6</B> tested users (P1, P2, and T1) to click its "Learn More" call-to-action first. This links to BrainTagger product page.
                                    </li>
                                    <li>
                                        <B col={col[1][1]}>3 users</B> (S1, S2, S3) wanted to click the <B col={col[1][1]}>"Shop All"</B> call-to-action first, after seeing the <B col={col[1][1]}>new website introduction</B>.
                                    </li>
                                    <li>
                                        Some users <B col={col[1][1]}>stopped to look through "Featured products"</B> while scanning, but still gravitated the most towards the large BrainTagger banner or "Shop All" button.
                                    </li>
                                </ul>
                                <br/>
                                <Typography variant="h5" color={col[1][1]}>
                                    Problems:
                                </Typography>
                                <ul>
                                    <li>
                                        On some screens, users <B col={col[1][1]}>needed to scroll down</B> to see the BrainTagger banner, and just did not bother scrolling before clicking away from home page.
                                    </li>
                                    <li>
                                        Both call-to-action's are quite effective on tested users, but they <B col={col[1][1]}>compete for users' attention</B>.
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <QuoteCard
                                    quote="I want to click this (Shop All) because I see it first... I wouldn't scroll down."
                                    user="S3"
                                    col={col[1][1]}
                                />
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[1][1], marginBottom:'-10px' }} />
                            </Grid>

                            <Grid item xs={12} mb={-1} mt={3} id="discovery-after">
                                <Typography variant="h4" color={col[1][0]}>
                                    LATEST DESIGN
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[1][0]}>
                                    Home Page - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem1.after.top} alt="Healthy Age Mart Home Page after redesign" borderRadius='0' padding='0'/>
                                <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                                    <SvgIcon component={SouthRounded} sx={{ fontSize: '20px', color: col[1][0] }}/> <Typography variant="body1" color={col[1][0]}>scroll down</Typography>
                                </Box>
                                <ImageModal image={Images.shopifyStore.problem1.after.bottom} alt="Healthy Age Mart Home Page after redesign - bottom" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[1][0]}>
                                    Tested Solutions:
                                </Typography>
                                <ul>
                                    <li>
                                        Added a <B col={col[1][0]}>notification banner on the top of every page</B> (not just home page) that <B col={col[1][0]}>links to BrainTagger Premium</B>, so that users see BrainTagger mentioned without needing to scroll.
                                    </li>
                                    <li>
                                        When tested with <B col={col[1][0]}>3 users</B> (P1, P3, S3), the notification banner was not always the first thing they clicked on, but <B col={col[1][0]}>all participants used it at some point in their path</B> to quickly look into BrainTagger and compare products.
                                    </li>
                                </ul>
                                <br/>
                                <p>
                                    After the redesign, there are <B col={col[1][0]}>many direct paths users can take, starting from the top of the Home Page</B>, to get to a BrainTagger product page.
                                </p>
                            </Grid>
                            <Grid item sm={10} xs={12}>
                                <Typography variant="h5" color={col[1][0]} textAlign="center">
                                    Discover BrainTagger User Flow - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem1.after.userFlow} alt="Healthy Age Mart BrainTagger Discovery User Flow after redesign"/>
                            </Grid>
                            <Grid item xs={12} mt={3} id="discovery-outcome">
                                <OutcomeCard bg={col[1][0]}>
                                    <Typography variant="h4" color="inherit">
                                        Problem 1 Outcome
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        <p>
                                            Compared to the old website, test results show that my current design <B col={COLORS.BLACK}>improves the chance for a Healthy Age Mart visitor to stumble upon BrainTagger</B>, and at least begin to learn about it.
                                        </p>
                                    </Typography>
                                </OutcomeCard>
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color={col[2][2]} id="understand">
                        Problem 2: Understand BrainTagger's Value
                    </ProjectHeading>
                    <ProjectBody border>
                        <Grid container spacing={5} justifyContent="center">
                            <Grid item xs={12} mb={-3} mt={3}>
                                <p>
                                    After users took notice of BrainTagger and clicked to learn about the product, they were <B col={col[2][2]}>confused about how BrainTagger game works, what its purpose and benefits are, and how the product is delivered to users upon purchase</B>.
                                </p>
                            </Grid>
                            <Grid item xs={12} mb={-1} mt={3} id="understand-before">
                                <Typography variant="h4" color={col[2][2]}>
                                    BEFORE REDESIGN (Test Existing Website)
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[2][2]}>
                                    BrainTagger Page - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem2.before.page} alt="Healthy Age Mart BrainTagger Product Page before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <QuoteCard
                                    quote="Does it come with the dock?... Is this used by caregivers for testing?"
                                    user="P1"
                                    col={col[2][2]}
                                />
                                <br/>
                                <Typography variant="h5" color={col[2][2]}>
                                    Problems:
                                </Typography>
                                <ul>
                                    <li>
                                        <B col={col[2][2]}>Product image misled users to think that BrainTagger is a console/hardware</B>, while it is actually a digital product accessible from any browser.
                                    </li>
                                    <li>
                                        Text description <B col={col[2][2]}>focused on BrainTagger's benefits for research and health monitoring</B>, misleading end users to think that they are not the target customers.
                                    </li>
                                    <li>
                                        There was <B col={col[2][2]}>no explanation of how the game works</B> for end users.
                                    </li>
                                    <li>
                                        Free and Premium <B col={col[2][2]}>product options were not easily noticeable</B>. The description also did not say what the Free account includes, <B col={col[2][2]}>only what additional features Premium users will get</B>.
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[2][2]} textAlign="center">
                                    Learn About BrainTagger User Flow - Before Redesign
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} mt={-4}>
                                <ImageModal image={Images.shopifyStore.problem2.before.userFlow} alt="Healthy Age Mart Explore BrainTagger Plans User Flow before redesign"/>
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[2][2], marginBottom:'-10px' }} />
                            </Grid>

                            <Grid item xs={12} mb={-1} mt={3} id="understand-then">
                                <Typography variant="h4" color={col[2][1]}>
                                    EARLY ITERATION (with Shopify's Taste Theme)
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[2][1]}>
                                    BrainTagger Page - Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem2.then.top} alt="BrainTagger product page early Shopify version top" borderRadius='0' padding='0'/>
                                <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                                    <SvgIcon component={SouthRounded} sx={{ fontSize: '20px', color: col[2][1] }}/> <Typography variant="body1" color={col[2][1]}>scroll down</Typography>
                                </Box>
                                <ImageModal image={Images.shopifyStore.problem2.then.middle} alt="BrainTagger product page early Shopify version, free and premium descriptions" borderRadius='0' padding='0'/>
                                <Box display="flex" alignItems="center" justifyContent="center" py={1}>
                                    <SvgIcon component={SouthRounded} sx={{ fontSize: '20px', color: col[2][1] }}/> <Typography variant="body1" color={col[2][1]}>scroll down</Typography>
                                </Box>
                                <ImageModal image={Images.shopifyStore.problem2.then.bottom} alt="BrainTagger product page early Shopify version FAQ section" borderRadius='0' padding='0'/>
                                <br/>
                                <Typography variant="h5" color={col[2][1]}>
                                    Demo Menu - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.demoSite.before.menu} alt="BrainTagger Demo Game Selection Menu before redesign" borderRadius='0' padding='0'/>
                                <br/>
                                <Typography variant="h5" color={col[2][1]}>
                                    Demo Game End - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.demoSite.before.end} alt="BrainTagger Demo Game End Dialog before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <QuoteCard
                                    quote="Maybe it's like a Sudoku game for mind exercise... Is it an app or something you download?"
                                    user="S2"
                                    col={col[2][1]}
                                />
                                <br/>
                                <Typography variant="h5" color={col[2][1]}>
                                    Tested Solutions:
                                </Typography>
                                <ul>
                                    <li>
                                        After I worked on the <B col={col[2][1]}>image & written content</B>, users overall had a <B col={col[2][1]}>better understanding</B> of BrainTagger's main concepts and purpose.
                                    </li>
                                    <li>
                                        When I tested their understanding of BrainTagger, the new <B col={col[2][1]}>FAQ section</B> helped users identify details that they missed in the main description.
                                    </li>
                                    <li>
                                        A BrainTagger <B col={col[2][1]}>Demo Site</B> was already availalbe, and I added <B col={col[2][1]}>a link to it</B> in product description.
                                    </li>
                                </ul>
                                <br/>
                                <QuoteCard
                                    quote="I'm just looking for games to play for fun, to fill my time."
                                    user="P1"
                                    col={col[2][1]}
                                />
                                <br/>
                                <Typography variant="h5" color={col[2][1]}>
                                    Problems:
                                </Typography>
                                <ul>
                                    <li>
                                        Users still misinterpreted the <B col={col[2][1]}>form and delivery</B> of BrainTagger, and needed to <B col={col[2][1]}>dig through the text</B> to correctly identify it.
                                    </li>
                                    <li>
                                        Tested users <B col={col[2][1]}>primarily cared for the entertainment value</B> of BrainTagger, rather than its brain health monitoring feature, which Centivizer still wants to mention.
                                    </li>
                                    <li>
                                        Some users, mostly younger Secondary Users, (P2, S1, S3) <B col={col[2][1]}>did not bother reading</B> through all of the descriptions.
                                    </li>
                                    <li>
                                        Shopify's default template, with the product description <B col={col[2][1]}>scrolling only on the right column</B>, made the text <B col={col[2][1]}>less appealing to read through</B>.
                                    </li>
                                    <li>
                                        The link to the BrainTagger Demo Site was <B col={col[2][1]}>buried in the text description</B>, and users did not notice it. Instead, they expressed their desire to see a game demo.
                                    </li>
                                    <li>
                                        The Demo Site had <B col={col[2][1]}>no mention of Healthy Age Mart</B>, and was <B col={col[2][1]}>not prompting users to buy BrainTagger</B>.
                                    </li>
                                </ul>
                                <br/>
                                <QuoteCard
                                    quote="I would want to try it first before buying... Or see how someone would play it."
                                    user="S3"
                                    col={col[2][1]}
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[2][1], marginBottom:'-10px' }} />
                            </Grid>

                            <Grid item xs={12} mb={-1} mt={3} id="understand-after">
                                <Typography variant="h4" color={col[2][0]}>
                                    LATEST DESIGN
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[2][0]}>
                                    BrainTagger Premium - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem2.after.premium} alt="Healthy Age Mart BrainTagger Premium product page after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[2][0]}>
                                    BrainTagger Basic - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem2.after.basic} alt="Healthy Age Mart BrainTagger Basic product page after redesign" borderRadius='0' padding='0'/>
                                <br/>
                                <Typography variant="h5" color={col[2][0]}>
                                    Tested Solutions:
                                </Typography>
                                <ul>
                                    <li>
                                        After my team changed account prices and features, we put <B col={col[2][0]}>Basic and Premium plans on two different product pages</B> to make their distinction clearer.
                                    </li>
                                    <li>
                                        I <B col={col[2][0]}>carefully worded product titles, gifting options, and large headings</B> to effectively communicate the game format, delivery method, and account tiers. All 3 tested users (P1, P3, S3) <B col={col[2][0]}>quickly understood the features and prices</B> of different BrainTagger plans.
                                    </li>
                                    <li>
                                        <B col={col[2][0]}>Links to Premium and Basic</B> plans I added in text headings made users want to <B col={col[2][0]}>quickly click between the two product pages</B> and compare plans.
                                    </li>
                                    <li>
                                        The <B col={col[2][0]}>large button to game Demo</B> was immediately noticed by all users. Even S3, who initially did not read the description, sufficiently understood the product after using the Demo.
                                    </li>
                                    <li>
                                        After I <B col={col[2][0]}>redesigned the Demo Site</B>, users claimed that it was easy to identify <B col={col[2][0]}>which games are included</B> in each account tier, and to <B col={col[2][0]}>go back to Healthy Age Mart to purchase a BrainTagger plan</B>.
                                    </li>
                                </ul>
                                <br/>
                                <QuoteCard
                                    quote="(Healthy Age Mart website) looks attractive... (Demo game ending screen) is clear and friendly."
                                    user="P3"
                                    col={col[2][0]}
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}  display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[2][0]}>
                                    Demo Game Menu - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.demoSite.after.menu} alt="BrainTagger Demo Menu page after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}  display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[2][0]}>
                                    Demo Game End - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.demoSite.after.end1} alt="BrainTagger Demo Game End Dialog both plans after redesign" borderRadius='0' padding='0'/>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[2][0]} textAlign="center">
                                    Learn About BrainTagger User Flow - Latest Design
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} mt={-4}>
                                <ImageModal image={Images.shopifyStore.problem2.after.userFlow} alt="Healthy Age Mart Explore BrainTagger Plans User Flow after redesign"/>
                            </Grid>
                            <Grid item xs={12} mt={3} id="understand-outcome">
                                <OutcomeCard bg={col[2][0]}>
                                    <Typography variant="h4" color="inherit">
                                        Problem 2 Outcome
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        <p>
                                            Users now can <B col={COLORS.BLACK}>much more quickly and correctly understand</B> how the BrainTagger game works, what features are included in each account type, and what to expect after purchasing BrainTagger.
                                        </p>
                                    </Typography>
                                </OutcomeCard>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[2][0]}>
                                    NEXT STEPS
                                </Typography>
                                <p>
                                    There are still more user suggestions that have not been addressed. Users suggested including better product <B col={col[2][0]}>photos</B> and a demo <B col={col[2][0]}>video</B> of BrainTagger, which require some special planning.
                                </p>
                                <br/>
                                <QuoteCard
                                    quote="(The photo) should show the whole family having fun together."
                                    user="S1"
                                    col={col[2][0]}
                                />
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color={col[3][3]} id="delivery">
                        Problem 3: Gift & Receive BrainTagger
                    </ProjectHeading>
                    <ProjectBody border>
                        <Grid container spacing={5} display="flex" justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[3][3]}>
                                    JUSTIFICATION
                                </Typography>
                                <p>
                                    One of Centivizer's sales strategies is to market B2C BrainTagger as a possible gift for older relatives, especially because some older target users <B col={col[3][3]}>might not be experienced with online shopping</B>. But this gifting system did not exist in the old website.
                                </p>
                                <p>
                                    The value of being able to digitally gift BrainTagger is yet to be proven with actual customer data. However, this problem was still worth addressing, because Centivizer <B col={col[3][3]}>did not have a system to smoothly connect</B> a user <B col={col[3][3]}>from buying BrainTagger</B> at Healthy Age Mart <B col={col[3][3]}>to receiving, then using BrainTagger</B> in its Dashboard website. Thus, laying the <B col={col[3][3]}>foundation for a seamless and flexible</B> digital product delivery system early on could pay off more as the product matures later.
                                </p>
                            </Grid>
                            <Grid item xs={12} mb={-1} mt={3} id="delivery-before">
                                <Typography variant="h4" color={col[3][3]}>
                                    BEFORE REDESIGN (Test Existing Website)
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column" alignItems="flex-start">
                                <Typography variant="h5" color={col[3][3]}>
                                    Product Options - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.before.page} alt="BrainTagger purchasing options before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12} display="flex" flexDirection="column" alignItems="flex-start">
                                <Typography variant="h5" color={col[3][3]}>
                                    Dashboard Sign Up - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.before.signup} alt="BrainTagger Dashboard Sign Up page before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[3][3]}>
                                    Problems:
                                </Typography>
                                <p>
                                    <ul>
                                        <li>
                                            In the old Healthy Age Mart, there was <B col={col[3][3]}>no gifting option</B>.
                                        </li>
                                        <li>
                                            When users purchased BrainTagger, they would get an <B col={col[3][3]}>order confirmation email through Shopify</B> and the <B col={col[3][3]}>email address they use for Healthy Age Mart</B> would be automatically <B col={col[3][3]}>registered for BrainTagger</B>.
                                        </li>
                                        <li>
                                            The feature to send digital products to a different email address was <B col={col[3][3]}>not available natively in our Shopify plan</B>. Thus, a buying user can only get BrainTagger for someone else by letting them use the buying user's email address and credentials to login.
                                        </li>
                                        <li>
                                            My team had not fleshed out <B col={col[3][3]}>how users would be directed to the BrainTagger Dashboard</B> after their purchase. At this point, users would have to <B col={col[3][3]}>find the BrainTagger Dashboard Sign Up page</B>, manually <B col={col[3][3]}>enter their Healthy Age Mart email address</B>, and check if we have registered them.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            
                            <Grid item sm={10} xs={12}>
                                <Typography variant="h5" color={col[3][3]} textAlign="center">
                                    Gift & Receive BrainTagger User Flow - Before
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.before.userFlow} alt="Healthy Age Mart Gift & Receive BrainTagger User Flow before redesign"/>
                            </Grid>
                            
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[3][2], marginBottom:'-10px' }} />
                            </Grid>
                            
                            <Grid item xs={12} mb={-1} mt={3} id="delivery-then">
                                <Typography variant="h4" color={col[3][2]}>
                                    EARLY ITERATION (with Shopify's Taste Theme)
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[3][2]}>
                                    Buy for Self - Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.then.self} alt="BrainTagger - buy for self - early Shopify version" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[3][2]}>
                                    Buy as a Gift - Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.then.gift} alt="BrainTagger - buy as a gift - early Shopify version" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[3][2]}>
                                    Checkout Review - Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.then.checkout} alt="Checkout Review Page - early Shopify version" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}  display="flex" flexDirection="column">
                                <Typography variant="h5" color={col[3][2]}>
                                    Tested Solutions:
                                </Typography>
                                <p>
                                    <ul>
                                        <li>
                                            I added the <B col={col[3][2]}>gifting option UI in the product page</B>. Users <B col={col[3][2]}>understood</B> this design immediately when <B col={col[3][2]}>tasked to purchase BrainTagger as a gift</B> for someone else.
                                        </li>
                                        <li>
                                            Tested users expected that the gift recipient they entered will <B col={col[3][2]}>get an email</B> with instructions on <B col={col[3][2]}>how to access BrainTagger</B>. Thus, gift recipients can use the BrainTagger account <B col={col[3][2]}>without needing to manually communicate</B> with the buying user.
                                        </li>
                                    </ul>
                                </p>
                                <br/>
                                <Typography variant="h5" color={col[3][2]}>
                                    Problems:
                                </Typography>
                                <p>
                                    <ul>
                                        <li>
                                            However, when asked to <B col={col[3][2]}>buy BrainTagger for themselves, it took users a bit more time</B> to figure out how they would receive the product, and sometimes they needed my help to locate the <B col={col[3][2]}>delivery information on the Checkout Review Page</B>.
                                        </li>
                                        <li>
                                            Turns out that this Checkout Review Page design <B col={col[3][2]}>cannot be fully implemented</B>. Delivery email would be visible only when buying item as a gift, and not when users buy for themselves, which could cause them to <B col={col[3][2]}>make an error or feel confused</B>.
                                        </li>
                                        <li>
                                            My team was also worried about customers <B col={col[3][0]}>accidentally entering the wrong gift recipient email address</B>. Error prevention is important, because we do not have a customer service department to correct such mistakes manually. 
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[3][2], marginBottom:'-10px' }} />
                            </Grid>
                            
                            <Grid item xs={12} mb={-1} mt={3} id="delivery-pushback">
                                <Typography variant="h4" color={col[3][1]}>
                                    PUSHBACK
                                </Typography>
                                <br/>
                                <Typography variant="h4" color={col[3][1]}>
                                    Problems:
                                </Typography>
                                <p>
                                    <ul>
                                        <li>
                                            Because digital product gifting option is not natively available in our Shopify plan, my team proposed selling <B col={col[3][1]}>Healthy Age Mart gift cards</B> to quickly work around this technical problem.
                                        </li>
                                        <li>
                                            I was afraid that this would <B col={col[3][1]}>derail us from creating a smooth user flow</B>. I pointed out to my team that, although a gift card is generally a good product option, it <B col={col[3][1]}>does not make accessing BrainTagger any easier for the recipient user</B>.
                                        </li>
                                        <li>
                                            An older recipient <B col={col[3][1]}>might not be as comfortable with online shopping</B> to successfully go through all the steps in the user flow below.
                                        </li>
                                        <li>
                                            Users might end up <B col={col[3][1]}>buying a different product</B> with their gift card, or find it <B col={col[3][1]}>too difficult to change payment method</B> later for Premium subscription, thus failing to complete the task.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[3][1]}>
                                    Buy BrainTagger with a Gift Card - Hypothetical User Flow
                                </Typography>
                                <ImageModal image={Images.shopifyStore.userFlow.giftCard} alt="Healthy Age Mart - hypothetical BrainTagger Purchase as gift card recipient user flow"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[3][1]}>
                                    Solutions:
                                </Typography>
                                <p>
                                    <ul>
                                        <li>
                                            If we could set up our own digital product delivery through a <B col={col[3][1]}>sign up email, independent from the limitations of Shopify's system</B> (see user flow below), BrainTagger gift recipients could skip all the steps that involve being in Healthy Age Mart.
                                        </li>
                                        <li>
                                            With an understanding of BrainTagger's backend API's and user database, <B col={col[3][1]}>I asked the BrainTagger Dashboard developer to prioritize</B> connecting with Shopify data and automatically <B col={col[3][1]}>send our own BrainTagger sign up email</B> to all new users (who either bought for themselves or received as gifts).
                                        </li>
                                        <li>
                                            I requested including a <B col={col[3][1]}>customized link</B> in the email, so that the sign up form would be <B col={col[3][1]}>autofilled with the recipient's email address</B>, as I designed below.
                                        </li>
                                        <li>
                                            The BrainTagger Dashboard developer found that what I proposed is <B col={col[3][1]}>implementable</B>, thus we did not need a gift card to replace the digital product delivery solution.
                                        </li>
                                    </ul>
                                    
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[3][1]}>
                                    Receive BrainTagger Digital Product - User Flow
                                </Typography>
                                <ImageModal image={Images.shopifyStore.userFlow.recipient} alt="Healthy Age Mart - BrainTagger product gift recipient user flow"/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[3][1]}>
                                    BrainTagger Sign Up Email - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.pushback.email} alt="BrainTagger Dashboard Sign Up Email" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[3][1]}>
                                    BrainTagger Sign Up Form - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.pushback.form} alt="BrainTagger Dashboard Sign Up Form" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    I am also working on the design for <a href="/work/playerdashboard" target="_blank" style={{ color: col[3][1] }}>BrainTagger Dashboard</a> to make the experience from purchase to product use as seamless as possible.
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <OutcomeCard bg={col[3][1]}>
                                    <Typography variant="h4" color="inherit">
                                        Pushback Outcome
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        <p>
                                            My argument helped steer my team away from a roundabout gift card solution and <B col={COLORS.BLACK}>speed up the execution of the more ideal</B> digital product gift solution.
                                        </p>
                                    </Typography>
                                </OutcomeCard>
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[3][1], marginBottom:'-10px' }} />
                            </Grid>
                            
                            <Grid item xs={12} mb={-1} mt={3} id="delivery-after">
                                <Typography variant="h4" color={col[3][0]}>
                                    LATEST DESIGN
                                </Typography>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[3][0]}>
                                    Buy for Self - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.after.self} alt="BrainTagger - buy for self - after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Typography variant="h5" color={col[3][0]}>
                                    Buy as a Gift - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.after.gift} alt="BrainTagger - buy as a gift - after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <Typography variant="h5" color={col[3][0]}>
                                        Tested Solutions:
                                    </Typography>
                                    <ul>
                                        <li>
                                            To prevent users from entering the wrong recipient email by mistake, my team considered making them <B col={col[3][0]}>enter the recipient address twice</B> to confirm it. But this solution <B col={col[3][0]}>did not solve</B> delivery confusion for users <B col={col[3][0]}>buying for themselves</B>. Also it turned out <B col={col[3][0]}>not to be implementable</B> on Shopify.
                                        </li>
                                        <li>
                                            As the next best implementable alternative, I added a <B col={col[3][0]}>note for each buying option</B>, clarifying <B col={col[3][0]}>where the product will be delivered</B> and asking to <B col={col[3][0]}>double-check</B> entered recipient address.
                                        </li>
                                        <li>
                                            With the new design, I tasked 3 users (P1, P3, S3) to purchase a BrainTagger account and start playing the game. All 3 <B col={col[3][0]}>understood to look for an email</B> after purchase, then <B col={col[3][0]}>successfuly signed up and logged in</B> to the Dashboard to start playing.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[3][0]} textAlign="center">
                                    Gift & Receive BrainTagger User Flow - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem3.after.userFlow} alt="Healthy Age Mart Gift & Receive BrainTagger User Flow after redesign"/>
                            </Grid>

                            <Grid item xs={12}>
                                <OutcomeCard bg={col[3][0]} id="delivery-outcome">
                                    <Typography variant="h4" color="inherit">
                                        Problem 3 Outcome
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        <p>
                                            I designed and <B col={COLORS.BLACK}>advocated for a straightforward, user-friendly, and implementable</B> digital product delivery solution by discussing its <B col={COLORS.BLACK}>advantages from a business perspective</B> and using my <B col={COLORS.BLACK}>technical knowledge to communicate with developers</B> in my team.
                                        </p>
                                    </Typography>
                                </OutcomeCard>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h4" color={col[3][0]}>
                                    NEXT STEPS
                                </Typography>
                                <p>
                                    Once the technical problem of sign up email customization is fully solved, it might be a good idea to also <B col={col[3][0]}>let users add a gift note to BrainTagger recipients in the sign up email</B>, so that recipients <B col={col[3][0]}>would not be surprised or suspicious about receiving an email from an unfamiliar website</B> (BrainTagger Dashboard). An alternate idea would be to <B col={col[3][0]}>let buying users easily notify the recipient</B> about the gift and incoming sign up email <B col={col[3][0]}>through a messaging app or social media</B>.
                                </p>
                                <br/>
                                <QuoteCard
                                    quote="Would be nice if I could write a gift note to tell them who it's from."
                                    user="S1"
                                    col={col[3][0]}
                                />
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color={col[4][3]} id="organization">
                        Problem 4: Browse Products & Pages (Information Architecture)
                    </ProjectHeading>
                    <ProjectBody border>
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <p>
                                    In general, the way <B col={col[4][3]}>products were organized</B> in the old Healthy Age Mart was <B col={col[4][3]}>unexpected or did not help users find items</B>. Thus, I reorganized the information architecture and renamed labels help direct users to Healthy Age Mart products and information relevant to them.
                                </p>
                            </Grid>
                            <Grid item xs={12} mb={-1} mt={3} id="organization-before">
                                <Typography variant="h4" color={col[4][3]}>
                                    BEFORE REDESIGN (Test Existing Website)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][3]}>
                                    Menu - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.before.menu} alt="Healthy Age Mart Menu before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <Typography variant="h5" color={col[4][3]}>
                                        Problems:
                                    </Typography>
                                    <ul>
                                        <li>
                                            The <B col={col[4][3]}>search bar</B> in the old Healthy Age Mart menu could only look for <B col={col[4][3]}>full, exact keywords in product titles</B>. It consumes a lot of space and might not be very useful for users still unfamiliar with the store's products.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][3]}>
                                    Product Categories on Home Page - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.before.categories} alt="Healthy Age Mart Categories before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <QuoteCard
                                    quote="I didn't know you can click these 3 (categories), I thought they're just images... What are 'AAT' and 'Centivizer'? They are not common words."
                                    user="P1"
                                    col={col[4][3]}
                                />
                                <br/>
                                <p>
                                    <ul>
                                        <li>
                                            There were <B col={col[4][3]}>3 main product categories</B> in the home page, but the tested user <B col={col[4][3]}>did not know they're clickable</B>. Moreover, the user <B col={col[4][3]}>did not understand</B> what the brand names and categories meant.
                                        </li>
                                    </ul>  
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][3]}>
                                    Shop Page - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.before.shop} alt="Healthy Age Mart Shop before redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <ul>
                                        <li>
                                            In the shop page, there are also <B col={col[4][3]}>categories that use unfamiliar brand names</B>.
                                        </li>
                                        <li>
                                            Some products were grouped in a way that <B col={col[4][3]}>did not match the user's expectations</B> of the category names.
                                        </li>
                                    </ul>
                                </p>
                                <br/>
                                <QuoteCard
                                    quote="These are not what I think of when I see the word 'Exercise'."
                                    user="P1"
                                    col={col[4][3]}
                                />
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[4][3], marginBottom:'-10px' }} />
                            </Grid>

                            <Grid item xs={12} mb={-1} mt={3} id="organization-then">
                                <Typography variant="h4" color={col[4][2]}>
                                    1ST EARLY ITERATION (Med-Fi)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][2]}>
                                    Menu - 1st Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.then.menu} alt="Healthy Age Mart Menu - medium fidelity" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <Typography variant="h5" color={col[4][2]}>
                                        Solutions:
                                    </Typography>
                                    <ul>
                                        <li>
                                            In the <B col={col[4][2]}>medium fidelity prototype</B>, I put <B col={col[4][2]}>4 product categories with more self-explanatory names</B> on both the menu and home page. I thought placing them on the menu was important, because the website mainly serves as an online store.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][2]}>
                                    Product Categories on Home Page - 1st Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.then.categories} alt="Healthy Age Mart Categories - medium fidelity" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <Typography variant="h5" color={col[4][2]}>
                                        Card Sort Study:
                                    </Typography>
                                    <ul>
                                        <li>
                                            To organize items under new categories in a way that better fits users' mental model, I conducted a <B col={col[4][2]}>card sort study</B> with 5 participants (C1, C2, C3, C4, C5) that were unfamiliar with Healthy Age Mart products.
                                        </li>
                                        <li>
                                            I asked them to <B col={col[4][2]}>put each product under one of the new categories</B>. Participants could also place items under <B col={col[4][2]}>multiple categories</B> or <B col={col[4][2]}>add & edit categories</B>.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][2]}>
                                    Study Data
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.then.cardSort} alt="Healthy Age Mart card sort study"/>
                            </Grid>
                            <Grid item xs={12}>
                                <QuoteCard
                                    quote="I feel that 'Brain Exercise' could also be 'Games for Dementia'."
                                    user="C5"
                                    col={col[4][2]}
                                />
                                <br/>
                                <p>
                                    <Typography variant="h5" color={col[4][2]}>
                                        Findings:
                                    </Typography>
                                    <ul>
                                        <li>
                                            Participant C2 replaced "Lifestyle Products" with more specific categories of <B col={col[4][2]}>"Digital Products", "Mobility Assistance", and "Pets"</B>, which are similar to some existing old categories. Therefore, I <B col={col[4][2]}>added these labels as subcategories under "Lifestyle Products"</B> to help users sort items in this broad category.
                                        </li>
                                        <li>
                                            Considering the study results and Centivizer's product concepts, several items could <B col={col[4][2]}>easily fall into multiple categories</B> between "Brain Exercise", "Physical Exercise", and "Games for Dementia". I realized that it was not as important for categories to be mutually exclusive, so I asked the developer to <B col={col[4][2]}>let any product be tagged with multiple category labels</B>.
                                        </li>
                                        <li>
                                            I focused on <B col={col[4][2]}>leading users to categories based on topics</B> that they were already interested in or seem relevant to their lives before landing on Healthy Age Mart.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[4][2], marginBottom:'-10px' }} />
                            </Grid>

                            <Grid item xs={12} mb={-1} mt={3} id="organization-then2">
                                <Typography variant="h4" color={col[4][1]}>
                                    2ND EARLY ITERATION (with Shopify's Taste Theme)
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][1]}>
                                    Menu - 2nd Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.then.menuRevised} alt="Healthy Age Mart Menu - early Shopify version" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <Typography variant="h5" color={col[4][1]}>
                                        Solutions:
                                    </Typography>
                                    <ul>
                                        <li>
                                            To make the design look <B col={col[4][1]}>cleaner and more straightforward</B> within the new Shopify template, I <B col={col[4][1]}>shortened the category names</B>.
                                        </li>
                                        <li>
                                            When tested, users seemed to have an easy time completing tasks using the new category links. For example, P2 clicked on <B col={col[4][1]}>"Mind"</B> on the menu when <B col={col[4][1]}>asked to look for a game that could help their relative's brain health</B>. T1 went directly to <B col={col[4][1]}>"Dementia"</B> category when asked to <B col={col[4][1]}>explore products that would be relevant for their long-term care organization's needs</B>.
                                        </li>
                                    </ul>
                                </p>
                                <br/>
                                <QuoteCard
                                    quote="The (Mind) link and (BrainTagger) product were easy to find."
                                    user="S2"
                                    col={col[4][1]}
                                />
                                <br/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][1]}>
                                    Product Categories on Home Page - 2nd Early Iteration
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.then.categoriesRevised} alt="Healthy Age Mart Categories - early Shopify version" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <QuoteCard
                                    quote="Why does this guy (with the cane) look sad?... If the products are helpful, he should look more lively."
                                    user="S1"
                                    col={col[4][1]}
                                />
                                <br/>
                                <QuoteCard
                                    quote="Maybe this (Dementia) category would include items like a wheelchair."
                                    user="S2"
                                    col={col[4][1]}
                                />
                                <br/>
                                <p>
                                    <Typography variant="h5" color={col[4][1]}>
                                        Problems:
                                    </Typography>
                                    <ul>
                                        <li>
                                            However, the <B col={col[4][1]}>images</B> used for the categories in this prototype seem to <B col={col[4][1]}>mislead users and set the wrong mood</B>. At this point, I was still using assets recycled from Centivizer's old websites.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>

                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[4][1], marginBottom:'-10px' }} />
                            </Grid>

                            <Grid item xs={12} mb={-1} mt={3} id="organization-after">
                                <Typography variant="h4" color={col[4][0]}>
                                    LATEST DESIGN
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][0]}>
                                    Menu - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.after.menu} alt="Healthy Age Mart Menu with All Pages after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <QuoteCard
                                    quote="The 'Organizations' page is useful, because I'm interested in hearing other organizations' experiences with Centivizer."
                                    user="T1"
                                    col={col[4][0]}
                                />
                                <br/>
                                <p>
                                    <Typography variant="h5" color={col[4][3]}>
                                        Tested Solutions:
                                    </Typography>
                                    <ul>
                                        <li>
                                            To address Tertiary Users, such as clients and vendors, that might use the website, I added a new <B col={col[4][0]}>'Partners'</B> section. Upon testing with T1, this seems to <B col={col[4][0]}>resonate with their need</B> to know about other organizations working with Centivizer and <B col={col[4][0]}>build more trust</B> in the company.
                                        </li>
                                        <li>
                                            I added more pages under the <B col={col[4][0]}>'About'</B> section to help clear up potential confusion on product delivery, let users reach out to customer service through a webform, further market our products to visitors, etc. Users I tested <B col={col[4][0]}>did not need these pages in the tasks I gave them</B>, but still had a <B col={col[4][0]}>positive overall impression</B> of the pages' presence.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][0]}>
                                    Product Categories on Home Page - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.after.categories} alt="Healthy Age Mart Categories after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <ul>
                                        <li>
                                        <B col={col[4][1]}>I used test findings</B> to request our visual asset designer to <B col={col[4][1]}>create more suitable illustrations</B>. Now the new product category images <B col={col[4][0]}>more accurately represent</B> our actual products, no longer mislead tested users, and fit better into the website's visual theme.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" color={col[4][0]}>
                                    Shop Page - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.problem4.after.shop} alt="Healthy Age Mart Shop after redesign" borderRadius='0' padding='0'/>
                            </Grid>
                            <Grid item xs={12}>
                                <p>
                                    <ul>
                                        <li>
                                            To help users filter items by category within the product listing pages, I also <B col={col[4][0]}>asked the Shopify developer to add 'Tags' filter</B>, which initially did not exist in our default settings. The tags are the same as the menu categories that tested well with users.
                                        </li>
                                    </ul>
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <OutcomeCard bg={col[4][0]} id="organization-outcome">
                                    <Typography variant="h4" color="inherit">
                                        Problem 4 Outcome
                                    </Typography>
                                    <Typography variant="body2" color="inherit">
                                        <p>
                                            By reorganizing item categories and redesigning the menu, I made it much easier for users to <B col={COLORS.BLACK}>understand the store's offerings</B> and <B col={COLORS.BLACK}>navigate to products or information that align with their interests</B>.
                                        </p>
                                    </Typography>
                                </OutcomeCard>
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color={col[5]} id="handover">
                        Handover to Developers
                    </ProjectHeading>
                    <ProjectBody border>
                        <Grid container spacing={5}>
                            <Grid item xs={12} mt={3}>
                                <p>
                                    The developers began implementing even as I was still testing and editing the new Healthy Age Mart design. These are some of the artifacts and documentation I made for them.
                                </p>
                            </Grid>
                            <Grid item xs={12} mb={-1}>
                                <Typography variant="h4" color={col[5]} alignSelf="flex-start">
                                    User Flow
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
                                <Typography variant="h5" color={col[5]} alignSelf="flex-start">
                                    Purchasing BrainTagger - Before Redesign
                                </Typography>
                                <ImageModal image={Images.shopifyStore.userFlow.before.purchase} alt="Healthy Age Mart BrainTagger first-time purchase before redesign"/>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <SvgIcon component={SouthRounded} sx={{ fontSize: '80px', color: col[5], marginBottom:'-10px' }} />
                            </Grid>
                            <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
                                <Typography variant="h5" color={col[5]} alignSelf="flex-start">
                                    Purchasing BrainTagger - Latest Design
                                </Typography>
                                <ImageModal image={Images.shopifyStore.userFlow.after.purchase} alt="Healthy Age Mart User Flow after redesign"/>
                            </Grid>
                            <Grid item xs={12} mt={3}>
                                <Typography variant="h4" color={col[5]} alignSelf="flex-start">
                                    Hi-Fi Prototype
                                </Typography>
                                <p>
                                    Below are Healthy Age Mart's high fidelity prototype and key screens through the <b>happy path</b> of discovering product through 'Mind' category, trying the BrainTagger demo, and purchasing BrianTagger Basic product. Afterwards, users can also learn more about Healthy Age Mart policies through 'About Me' pages. 
                                </p>
                            </Grid>
                            <Grid item xs={12} mt={3} mb={5} display="flex" justifyContent="center" alignItems="center">
                                <LinkButton
                                    id="clickable-hifi"
                                    href="https://www.figma.com/proto/qT3fEvxzlp17xR1mGisQAr/Healthy-Age-Mart-redesign-backup?node-id=406%3A23838&scaling=scale-down-width&page-id=406%3A22229&starting-point-node-id=406%3A23838&hide-ui=1"
                                    bg={col[5]}
                                    text="Open Clickable Hi-Fi Prototype"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mt={0}>
                            <Grid item sm={6} xs={12} mb={5}>
                                <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <Typography variant="h5" color={col[5]}>
                                        1. Land on Home Page
                                    </Typography>
                                </Box>
                                <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <p>
                                        Select 'Mind' category from menu or home page
                                    </p>
                                </Box>
                                <Box>
                                    <ImageModal image={Images.shopifyStore.hiFi.home} alt="Healthy Age Mart Home after redesign" borderRadius='0' padding='0'/>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12} mb={5}>
                                <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <Typography variant="h5" color={col[5]}>
                                        2. Browse Items by Category
                                    </Typography>
                                </Box>
                                <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <p>
                                        Select BrainTagger Basic or Premium
                                    </p>
                                </Box>
                                <Box>
                                    <ImageModal image={Images.shopifyStore.hiFi.mind} alt="Healthy Age Mart Mind Category after redesign" borderRadius='0' padding='0'/>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12} mb={5}>
                                <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <Typography variant="h5" color={col[5]}>
                                        3. View BrainTagger Product Page
                                    </Typography>
                                </Box>
                                <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <p>
                                        Click 'Try the BrainTagger Demo' button
                                    </p>
                                </Box>
                                <Box>
                                    <ImageModal image={Images.shopifyStore.hiFi.brainTagger} alt="Healthy Age Mart BrainTagger Basic Page after redesign" borderRadius='0' padding='0'/>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12} mb={5}>
                                <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <Typography variant="h5" color={col[5]}>
                                        4. Try BrainTagger Demo
                                    </Typography>
                                </Box>
                                <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <p>
                                        Play game, select a BrainTagger plan, and add it to cart in Healthy Age Mart
                                    </p>
                                </Box>
                                <Box>
                                    <ImageModal image={Images.shopifyStore.demoSite.after.end1} alt="BrainTagger Demo Site game ending dialog after redesign" borderRadius='0' padding='0'/>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12} mb={5}>
                                <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <Typography variant="h5" color={col[5]}>
                                        5. Purchase BrainTagger through Shopify
                                    </Typography>
                                </Box>
                                <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <p>
                                        Go through Shopify's default checkout sequence
                                    </p>
                                </Box>
                                <Box>
                                    <ImageModal image={Images.shopifyStore.hiFi.checkout} alt="Healthy Age Mart Checkout after redesign" borderRadius='0' padding='0'/>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12} mb={5}>
                                <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <Typography variant="h5" color={col[5]}>
                                        6. Learn about Shipping Policy
                                    </Typography>
                                </Box>
                                <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                                    <p>
                                        Go to 'About' -> 'Shipping & Delivery' on the mneu to learn more about how products will be delivered
                                    </p>
                                </Box>
                                <Box>
                                    <ImageModal image={Images.shopifyStore.hiFi.shipping} alt="Healthy Age Mart Shipping & Delivery Info after redesign" borderRadius='0' padding='0'/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} mt={3} mb={-3}>
                                <Typography variant="h4" color={col[5]} alignSelf="flex-start">
                                    Design System
                                </Typography>
                                <p>
                                    I mostly followed and replicated Shopify <a href="https://themes.shopify.com/themes/taste/styles/default?surface_inter_position=1&surface_intra_position=3&surface_type=all" target="_blank"  rel="noreferrer">Taste theme</a>'s
                                    existing design system, but customized parts of it in the Figma prototype when necessary.
                                </p>
                            </Grid>
                            <Grid item xs={12} mt={-3}>
                                <ImageModal image={Images.shopifyStore.designSystem} alt="Healthy Age Mart Design System"/>
                            </Grid>
                        </Grid>
                    </ProjectBody>

                    <ProjectHeading color="secondary" id="outcome">
                        Outcome
                    </ProjectHeading>
                    <ProjectBody>
                        <Typography variant="body2" color="primary">
                            <p>
                                Healthy Age Mart has not officially launched and gained real customers. But compared to the old website, after my redesign, all tested representative users...
                            </p>
                        </Typography>

                        <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                            <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                <Grid item xs={1} display="flex" justifyContent="flex-end">
                                    <SvgIcon component={Visibility} sx={{ fontSize:{ xs: "50px"}, color: col[5]}}/>
                                </Grid>
                                <Grid item xs={10} mt={1}>
                                    <Typography variant="body2" color="primary">
                                        <b>Were much more likely to see and stumble into a BrainTagger product page</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                        <br/>
                        <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                            <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                <Grid item xs={1} display="flex" justifyContent="flex-end">
                                    <SvgIcon component={Psychology} sx={{ fontSize:{ xs: "50px"}, color: col[5]}}/>
                                </Grid>
                                <Grid item xs={10} mt={1}>
                                    <Typography variant="body2" color="primary">
                                        <b>Understood correctly the main concept of BrainTagger and its two online account plans</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                        <br/>
                        <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                            <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                <Grid item xs={1} display="flex" justifyContent="flex-end">
                                    <SvgIcon component={MarkEmailRead} sx={{ fontSize:{ xs: "50px"}, color: col[5]}}/>
                                </Grid>
                                <Grid item xs={10} mt={1}>
                                    <Typography variant="body2" color="primary">
                                        <b>Found it easy to send BrainTagger as a gift and digitally receive the product</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                        <br/>
                        <Paper elevation={10} sx={{ width: '100%', backgroundColor: COLORS.DARK_GRAY, p:3, borderRadius: '15px' }}>
                            <Grid container spacing={5} display="flex" flexDirection={{sm: "row", xs:"column" }} alignItems="center" justifyContent="center">
                                <Grid item xs={1} display="flex" justifyContent="flex-end">
                                    <SvgIcon component={Interests} sx={{ fontSize:{ xs: "50px"}, color: col[5]}}/>
                                </Grid>
                                <Grid item xs={10} mt={1}>
                                    <Typography variant="body2" color="primary">
                                        <b>Could quickly locate products and pages that relate to topics of their interest</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>

                    </ProjectBody>

                    <ProjectHeading color="secondary" id="next-steps">
                        Next Steps
                    </ProjectHeading>
                    <ProjectBody>
                        <ul>
                            <li>
                                <b>Collaborate with developers</b> to ensure that the implemented Healthy Age Mart and BrainTagger Demo website are as close as possible to the design, and tweak design to go around technical limitations when necessary.
                            </li>
                            <li>
                                Continue to iteratively <b>test design</b> for how users move from purchasing at Healthy Age Mart to <a href="/work/playerdashboard" target="_blank">using BrainTagger through its Dashboard</a>. Especially because BrainTagger Dashboard implementation is still in early stages and and <b>technical limitations might be discovered later</b>.
                            </li>
                            <li>
                                Help <b>advise on and pass user feedback</b> for the design for BrainTagger product <b>images & video for marketing</b>.
                            </li>
                            <li>
                                Continue <b>documenting design</b> to hand off to designers and developers that might take over future iterations of the project.
                            </li>
                            <li>
                                Plan how Centivizer team could best <b>gather data and feedback from actual customers</b> after Healthy Age Mart launch to continue improving the website. 
                            </li>
                        </ul>
                    </ProjectBody>
                    <ProjectHeading color="secondary" id="lessons">
                        Lessons Learned
                    </ProjectHeading>
                    <ProjectBody>
                        <ul>
                            <li>
                                This project gave me a chance to <b>develop my UX writing skills</b>. I learned that carefully wording a few key headings and components could significantly <b>increase user's understanding of a page or system within a few seconds</b>.
                            </li>
                            <li>
                                My knowledge of <b>backend development, such as on API's and SQL database,</b> helped me deal with <b>pushback</b> and <b>communicate</b> with my cross-functional team. I was able to provide <b>user flow design justifications, ask the right questions, and suggest realistic solutions</b> for the BrainTagger account digital delivery that is not natively supported by Shopify.
                            </li>
                            <li>
                                I learned how important it is to <b>quickly find and involve users in the early stages of a project</b>, to ensure that the product heads in a direction that <b>aligns with users' needs and perspectives</b>. Quickly testing the old Healthy Age Mart with even one user really <b>helped me justify</b> to my manager why we needed to <b>prioritize</b> redesigning information architecture, user flow, and the voice of our written content.
                            </li>
                            <li>
                                After a few weeks into the project, I <b>discovered previous designers' assets</b> that never got deployed, and we were able to reuse these assets. I realized how important it is to <b>thoroughly document and annotate my work</b> for handover, especially in organizations where team members come and go very frequently.
                            </li>
                        </ul>
                    </ProjectBody>

                    <ProjectHeading style={{ display: 'flex', justifyContent: 'flex-end'}} id="bottom">
                        <Button
                            href="/work"
                            display="flex" alignItems="center"
                            sx={{ padding:'15px 30px', marginLeft: '0px', borderRadius: '10px', border: `2px solid ${COLORS.LAVENDER}`,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)' } }}>
                                <Typography variant="h4" color="secondary">
                                    See more of my work
                                </Typography>
                        </Button>
                    </ProjectHeading>


                    {/* ROADMAP STICKY MENU */}

                    {isWide?
                    <Fade appear={false} in={showRoadmap} easing={{ enter: "linear", exit: "linear" }}>
                        <MUIAppBar position="fixed" pt={2} m={0} sx={{top: 'auto', bottom: 0, zIndex: 899, minHeight:'85px',
                            display:'flex', alignItems:'center', justifyContent: 'center', boxShadow: '0px -3px 5px 0px rgb(112, 112, 112, 0.3)'}}
                        >
                            <Grid container spacing={-5}  display="flex" justifyContent="center" alignItems="center" mx={{md:3, sm:2}} >
                                
                                <Grid item sm={1} alignSelf="stretch">
                                    <SkinnyButton href="#overview" label="Overview" bg={col[0]}/>
                                </Grid>

                                <Grid item sm={1.4}>
                                    <SkinnyStartArrowButton label="Identify Target Users" bg={col[0]}
                                        onClick={positionMenuUsers}
                                    />
                                    <Menu
                                        id="users-menu"
                                        anchorEl={anchorElUsers}
                                        open={showRoadmapUsers}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapUsers(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#users" label="Section Top" bg={col[0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#users-primary" label="Primary Users" bg={col[1][0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#users-secondary" label="Secondary Users" bg={col[2][0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#users-tertiary" label="Tertiary Users" bg={col[3][0]} style={{ width: '150px', marginRight: 0}}/>
                                        </Box>
                                    </Menu>
                                </Grid>

                                <Grid item sm={1.4} display="flex">
                                    <SkinnyArrowButton label="Prob 1: Discover" bg={col[1][0]}
                                        onClick={positionMenuP1}
                                    />
                                    <Menu
                                        id="redesign-menu"
                                        anchorEl={anchorElP1}
                                        open={showRoadmapP1}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapP1(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#discovery" label="Section Top" bg={col[1][2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#discovery-before" label="Before" bg={col[1][2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#discovery-then" label="Then" bg={col[1][1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#discovery-after" label="After" bg={col[1][0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#discovery-outcome" label="Outcome" bg={col[1][0]} style={{ width: '150px', marginRight: 0}}/>
                                        </Box>
                                    </Menu>
                                </Grid>

                                <Grid item sm={1.4}>
                                    <SkinnyArrowButton label="Prob 2: Understand" bg={col[2][0]}
                                        onClick={positionMenuP2}
                                    />
                                    <Menu
                                        id="flow-menu"
                                        anchorEl={anchorElP2}
                                        open={showRoadmapP2}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapP2(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#understand" label="Section Top" bg={col[2][2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#understand-before" label="Before" bg={col[2][2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#understand-then" label="Then" bg={col[2][1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#understand-after" label="After" bg={col[2][0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#understand-outcome" label="Outcome" bg={col[2][0]} style={{ width: '150px', marginRight: 0 }}/>
                                        </Box>
                                    </Menu>
                                </Grid>

                                <Grid item sm={1.4}>
                                    <SkinnyArrowButton label="Prob 3: Gift & Receive" bg={col[3][0]}
                                        onClick={positionMenuP3}
                                    />
                                    <Menu
                                        id="flow-menu"
                                        anchorEl={anchorElP3}
                                        open={showRoadmapP3}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapP3(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#delivery" label="Section Top" bg={col[3][3]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#delivery-before" label="Before" bg={col[3][3]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#delivery-then" label="Then" bg={col[3][2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#delivery-pushback" label="Pushback" bg={col[3][1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#delivery-after" label="After" bg={col[3][0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#delivery-outcome" label="Outcome" bg={col[3][0]} style={{ width: '150px', marginRight: 0 }}/>
                                        </Box>
                                    </Menu>
                                </Grid>

                                <Grid item sm={1.4}>
                                    <SkinnyArrowButton label="Prob 4: Browse" bg={col[4][0]}
                                        onClick={positionMenuP4}
                                    />
                                    <Menu
                                        id="flow-menu"
                                        anchorEl={anchorElP4}
                                        open={showRoadmapP4}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapP4(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#organization" label="Section Top" bg={col[4][3]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#organization-before" label="Before" bg={col[4][3]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#organization-then" label="Then" bg={col[4][2]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#organization-then2" label="Then 2" bg={col[4][1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#organization-after" label="After" bg={col[4][0]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#organization-outcome" label="Outcome" bg={col[4][0]} style={{ width: '150px', marginRight: 0 }}/>
                                        </Box>
                                    </Menu>
                                </Grid>

                                <Grid item sm={1.4}>
                                    <SkinnyArrowButton href="#handover" label="Handover" bg={col[5]}/>
                                </Grid>
                                <Grid item sm={1.4}>
                                    <SkinnyArrowButton href="#outcome" label="Outcome" bg={col[5]}/>
                                </Grid>
                                <Grid item sm={0.8} display='flex' alignItems='center' justifyContent='flex-start' sx={{pl:4, height: '100%' }}>
                                    <StyledOutlineButton fontSize="16px" padding="5px"
                                        onClick={()=>setShowRoadmap(null)}
                                    >
                                        <SvgIcon component={CloseRounded} sx={{ fontSize:{ md: "22px", sm: "16px" }, color: COLORS.LAVENDER}}/>
                                    </StyledOutlineButton>
                                </Grid>
                            </Grid>
                        </MUIAppBar>
                    </Fade>                    
                    :
                    <Drawer
                        anchor="left"
                        open={showRoadmap}
                        width="100%"
                        variant="temporary"
                    >
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                            sx={{ height: '100%' }}
                        >   
                            <Box display="flex" direction="row" justifyContent="center" mb={3} sx={{ width: '100%'}}>
                                <StyledOutlineButton fontSize="16px" padding="5px"
                                    onClick={()=>setShowRoadmap(null)}
                                >
                                    <SvgIcon component={CloseRounded} sx={{ fontSize:"22px", color: COLORS.LAVENDER}}/>
                                </StyledOutlineButton>
                            </Box>

                            <DownStartArrowButton href="#overview" variant="overline" label="Overview" bg={col[0]} style={{height: '12%'}}/>

                            <DownArrowButton href="#users" variant="overline" label="Identify Target Users" bg={col[0]} style={{height: '12%'}} />

                            <DownArrowButton href="#discovery" variant="overline" label="Prob 1: Discover" bg={col[1][0]} style={{height: '12%'}}/>

                            <DownArrowButton href="#understand" variant="overline" label="Prob 2: Understand" bg={col[2][0]} style={{height: '12%'}}/>

                            <DownArrowButton href="#delivery" variant="overline" label="Prob 3: Gift & Receive" bg={col[3][0]} style={{height: '12%'}}/>

                            <DownArrowButton href="#organization" variant="overline" label="Prob 4: Browse" bg={col[4][0]} style={{height: '12%'}}/>

                            <DownArrowButton href="#handover" variant="overline" label="Handover" bg={col[5]} style={{height: '12%'}}/>

                            <DownArrowButton href="#outcome" variant="overline" label="Outcome" bg={col[5]} style={{height: '12%'}}/>
                        </Box>
                    </Drawer>    
                }
                </>
            }
        </Layout>
    );
}


export default ShopifyStore;