import React from "react";
import bcrypt from 'bcryptjs';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { styled as styledMUI } from "@mui/system";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
    AppBar,
    Drawer,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Button,
    MenuItem,
    useMediaQuery,
    Fade,
    SvgIcon
} from '@mui/material';
import { theme, COLORS } from "core";


export const MUIAppBar = styledMUI(AppBar)({
    backgroundColor: COLORS.BLACK,
    padding: "1%"
})

const MenuRoot = styled.div`
    flex-grow: 1;
`
const MenuButton = styledMUI(IconButton)({
    marginRight: "0px",
})
    
const HeaderOptions = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
`

const Nav = props => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  let initPath = window.location.pathname==='/'? '/work': window.location.pathname;
  const [currentPath, setCurrentPath] = React.useState(initPath);

  const trigger = useScrollTrigger();

  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenNavMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorEl(null);
  };

  const { PASS, state, setStep } = props;

  const menuItems = [
    {
      menuTitle: "Work",
      pageURL: "/work"
    },
    {
      menuTitle: "About",
      pageURL: "/about"
    },
    {
        menuTitle: "Resume",
        pageURL: "/resume",
    },
    {
        menuTitle: "Contact",
        pageURL: "/contact",
    },
  ];

  return(
    <>
    <Fade appear={false} in={!trigger} easing={{ enter: "linear", exit: "linear" }}>
        <MenuRoot>
            <MUIAppBar elevation={10} position="fixed" sx={{ zIndex: 999 }}>              
                {isMobile ? (
                <Toolbar>
                    <Typography variant="h4" color="primary">
                        <NavLink to = "/work">
                            Lala K. Sondjaja
                        </NavLink>
                    </Typography>
                    <HeaderOptions>
                        <MenuButton
                        edge="start"
                        variant="contained"
                        sx={{ backgroundColor: COLORS.BLACK,
                            boxShadow: `0px 0px 5px ${COLORS.OFF_WHITE}`,
                            border: `2px solid ${COLORS.LAVENDER}`,
                            borderRadius: "5px",
                            transition: "transform 0.15s ease-in-out",
                            "&:active":{
                                transform: "scale3d(1.2, 1.2, 1)"
                            }
                        }}
                        aria-label="menu"
                        onClick={handleOpenNavMenu}
                        >
                            <Typography variant="h6" color="secondary">
                                Menu
                            </Typography>
                        </MenuButton>

                        <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        open={open}
                        onClose={handleCloseNavMenu}
                        >
                        {menuItems.map(menuItem => {
                            return(
                            <MenuItem
                            component={NavLink}
                            to={menuItem.pageURL}
                            sx={{my:1}}
                            onClick={handleCloseNavMenu}
                            >
                                <Typography
                                    variant="h5" 
                                    color="primary"
                                    sx={{ "&:active": {color: COLORS.LAVENDER}}}
                                >
                                    {menuItem.menuTitle}
                                </Typography>
                            </MenuItem>
                        )})}
                        {!bcrypt.compareSync(state.password, PASS) &&
                            <MenuItem
                            component={Button}
                            onClick={()=> {
                                setStep(1);
                            }}
                            variant="contained"
                            sx={{ backgroundColor: COLORS.LAVENDER,
                                  borderRadius: "5px",
                                  boxShadow: `0px 0px 5px ${COLORS.OFF_WHITE}`,
                                  fontSize: "22px",
                                  fontWeight: "700",
                                  display: "flex",
                                  justifyContent:"center",
                                  transition: "transform 0.15s ease-in-out",
                                "&:active": { 
                                    backgroundColor: COLORS.LAVENDER,
                                    boxShadow: `0px 0px 5px ${COLORS.OFF_WHITE}`,
                                    transform: "scale3d(1.2, 1.2, 1)" }
                                }}
                            >
                                <LockIcon/>
                            </MenuItem>
                        }
                        </Menu>
                    </HeaderOptions>
                </Toolbar>
                ) : (
                <Toolbar>
                    <Typography variant="h3" color="primary">
                        <NavLink to = "/work">
                            Lala K. Sondjaja
                        </NavLink>
                    </Typography>
                    <HeaderOptions>
                        {menuItems.map(menuItem =>{
                            return (
                                <Button
                                component={NavLink}
                                to={menuItem.pageURL}
                                color={currentPath.includes(menuItem.pageURL)? "secondary" : "primary"}
                                variant="text"
                                onClick={()=>{setCurrentPath(menuItem.pageURL)}}
                                sx={{ transition: "transform 0.15s ease-in-out",
                                    "&:hover": { color: COLORS.LAVENDER, transform: "scale3d(1.2, 1.2, 1)" } }}
                                >
                                    {menuItem.menuTitle}
                                </Button>
                            );
                        })}
                        {!bcrypt.compareSync(state.password, PASS) ?
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={()=> {
                                    setStep(1);
                                }}
                                sx={{ boxShadow: `0px 0px 5px ${COLORS.OFF_WHITE}`,
                                      transition: "transform 0.15s ease-in-out",
                                      "&:hover": { boxShadow: `0px 0px 5px ${COLORS.OFF_WHITE}`,
                                      transform: "scale3d(1.2, 1.2, 1)" }
                                    }}
                                >
                                    <LockIcon/>
                            </Button>
                        :
                        <SvgIcon component={LockOpenIcon} sx={{ mx: 3, fontSize: '32px', color: COLORS.OFF_WHITE }} />
                        }
                    </HeaderOptions>
                </Toolbar>
                )}
            </MUIAppBar>
        </MenuRoot>
    </Fade>
    </>
  );
};

export default Nav;
