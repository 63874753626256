import React from "react";
import bcrypt from 'bcryptjs';
import {
    Box,
    Typography,
    Button
} from '@mui/material';
import { Layout, LinkButton, COLORS } from "core";
import { Thumbnail } from "assets";
import resumeFilePublic from "./assets/K sondjaja - resume 2023 public.pdf";
import resumeImagePublic from "./assets/K sondjaja - resume 2023 public.png";
import resumeFilePrivate from "./assets/K sondjaja - resume 2023 password.pdf";
import resumeImagePrivate from "./assets/K sondjaja - resume 2023 password.png";


const Resume = props => {

    return(
        <Layout>

            {bcrypt.compareSync(props.state.password, props.PASS)?
                <>
                    <Box>
                        <a
                        href={resumeFilePrivate}
                        target="_blank" rel="noreferrer"
                        >
                            <Thumbnail
                                src={resumeImagePrivate}
                                alt="Lala K. Sondjaja's resume"
                                style={{ cursor: "pointer" }}
                            />
                        </a>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={3}>
                        <LinkButton
                            href={resumeFilePrivate}
                            target="_blank" rel="noreferrer"
                            text="Open PDF Resume"
                        />
                    </Box>
                </>
                :
                <>
                    <Box>
                        <a
                        href={resumeFilePublic}
                        target="_blank" rel="noreferrer"
                        >
                            <Thumbnail
                                src={resumeImagePublic}
                                alt="Lala K. Sondjaja's resume"
                                style={{ cursor: "pointer" }}
                            />
                        </a>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={3}>
                        <LinkButton
                            href={resumeFilePublic}
                            target="_blank" rel="noreferrer"
                            text="Open PDF Resume"
                        />
                    </Box>
                </>
            }


            <Box mt={15} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    href="/work"
                    display="flex" alignItems="center"
                    sx={{ padding:'15px 30px', marginLeft: '0px', borderRadius: '10px', border: `2px solid ${COLORS.LAVENDER}`,
                    boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                    '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)' } }}>
                        <Typography variant="h4" color="secondary">
                            See my work
                        </Typography>
                </Button>
            </Box>
        </Layout>
      );
};

export default Resume;
