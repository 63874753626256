import React, { useState } from "react";
import { 
    Layout,
    COLORS,
    ProjectTitle,
    ProjectDates,
    ProjectDescription,
    ProjectHeading,
    ProjectBody,
    LinkButton,
    SkinnyStartArrowButton,
    SkinnyArrowButton,
    StartArrowButton,
    ArrowButton,
    DownStartArrowButton,
    DownArrowButton,
    StyledButton,
    StyledOutlineButton,
    ImageModal } from "core";
import {
    Fade,
    Grid,
    Box,
    Button,
    Typography,
    SvgIcon,
    Drawer,
    IconButton
} from '@mui/material';
import { MUIAppBar } from "../Nav";
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Images, Thumbnail } from "assets";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import CheckRounded from '@mui/icons-material/Check';
import CloseRounded from '@mui/icons-material/Close';

const BlurredVision = props => {

    const [showRoadmap, setShowRoadmap] = useState(null);

    const theme = useTheme();
    const isWide = useMediaQuery(theme.breakpoints.up('730'));
    const isXS = useMediaQuery(theme.breakpoints.between('470','600'));
    const isSM = useMediaQuery(theme.breakpoints.between('600','900'));
    const isMD = useMediaQuery(theme.breakpoints.up('900'));
    const isTiny = useMediaQuery(theme.breakpoints.down('470'));
    // const isNotTiny = useMediaQuery(theme.breakpoints.up('470'));

    const projectColors = [
        COLORS.ORANGE, '#feced3', '#f2c6e1', COLORS.LAVENDER
    ];
    
    const skills = ['Prototyping', 'Figma', 'Data Visualization', 'Interview', 'Testing', 'Qualitative Analysis'];

    const liIcon = {
        paddingLeft: 35,
        textIndent:-35
    };

    return(
        <Layout>
            <Box sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', position: 'fixed', top: 'auto', bottom: '10px', left: 'auto', right: '20px', zIndex: 899}}>
                <StyledButton fontSize="16px" padding="5px 10px" style={{ marginRight: "20px" }}
                    onClick={()=>setShowRoadmap(true)}
                >
                    Jump to...
                </StyledButton>
                <Box sx={{ display:'flex', flexDirection: 'column', fontSize:'22px', fontWeight: 700 }}>
                    <IconButton href="#" aria-label="scroll-to-top" color="secondary" size="small"
                        sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                        boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                        '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandLessIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton href="#bottom" aria-label="scroll-to-bottom" color="secondary" size="small"
                            sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandMoreIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Box>

            <Box mb={4} display="flex" sx={{ width: '100%' }}>
                <Box bgcolor={ COLORS.ORANGE } display="flex" alignItems="center" justifyContent="center"
                    sx={{ borderRadius: '1vw', width:'100%' }}
                >
                    <Thumbnail src={Images.dataVis.thumbnail} width="95%" alt="Redesigned Medisafe Data Visualization screen"/>
                </Box>
            </Box>

            <ProjectTitle color={projectColors[0]}>
                Data Visualization for Users with Blurred Vision
            </ProjectTitle>
            <ProjectBody style={{ marginTop: '20px', marginBottom: '10px' }}>
                {skills.map(skill=>{
                    return(
                        <Box display="inline-block" backgroundColor={COLORS.BLACK} px={1.5} py={0.5} mr={1.5} mb={1.5}
                            sx={{ borderRadius:'5px', border: `1px solid ${COLORS.ORANGE}` }}>
                            <Typography variant="body1">
                                <span style={{ fontSize: '16px', fontWeight: '700', color: COLORS.ORANGE }}>
                                    {skill}
                                </span>
                            </Typography>
                        </Box>
                    );
                })}
            </ProjectBody>
            <ProjectDates>
                Data Visualization testing and redesign, September - December 2021
            </ProjectDates>
            <br/><br/>
            <ProjectDescription>
                <Typography variant="h4" color={projectColors[0]}>
                    Problem Space
                </Typography>
                Many mobile consumer health-monitoring applications are especially popular among older adults. 
                However, they are not always designed to be easily accessible by users with age-related visual impairments.<br/><br/>
                <br/>
                <Typography variant="h4" color={projectColors[0]}>
                    Solution
                </Typography>
                I tested and redesigned existing data visualizations of 3 Android mobile health apps, 
                so that users with blurred vision can more easily read them without using add-on assistive technology.<br/><br/>

                The goal of the project was to also begin discovering design approaches that could make data visualization more 
                accessible for users with blurred vision.<br/><br/>

                <Typography variant="body1" color="primary">
                    Under "Fair Dealing" for the purpose of education and criticism & review in Canadian copyright law, I am using & showing parts of mobile applications 
                    that are publicly accessible by Android users or screenshots that are published online by other users. I am crediting any content that I did not create myself.
                </Typography>
            </ProjectDescription>

            <ProjectHeading color={projectColors[0]} narrow>
                My Role
            </ProjectHeading>
            <ProjectBody>
                <ul>
                    <li>Conducted <b style={{ color: projectColors[0] }}>user interviews and 2 rounds of usability tests</b> with 3 participants with blurred vision</li>
                    <li><b style={{ color: projectColors[0] }}>Redesigned data visualization screens</b> of existing health applications following their brand aesthetics</li>
                    <li>Summarized findings as <b style={{ color: projectColors[0] }}>possible data visualization design guidelines</b> that can be refined with more future research</li>
                </ul>
            </ProjectBody>

            <ProjectHeading color={projectColors[0]} narrow>
                Key User Assumptions
            </ProjectHeading>
            <ProjectBody>
                <ul>
                    <li>Have basic understanding of how to read line and bar charts and are generally comfortable with smartphones</li>
                    <li>Do not have other significant impairments, other than blurred vision, that could affect their interaction with/viewing experience of mobile apps</li>
                </ul>
            </ProjectBody>

            <ProjectHeading color={projectColors[0]}>
                Roadmap
            </ProjectHeading>
            
            <ProjectBody border>      
                {(isXS || isTiny )?
                <>
                    {isTiny?
                        <Grid container spacing={0} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Grid item sm={6}>
                                <DownStartArrowButton href="#bg-research" label="Background Research" bg={projectColors[0]} />
                            </Grid>
                            <Grid item sm={6}>
                                <DownArrowButton href="#research-tools" label="Research Tools" bg={projectColors[0]}/>
                            </Grid>
                            <Grid item sm={6}>
                                <DownArrowButton href="#bg-interviews" label="Participant Interviews" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item sm={6}>
                                <DownArrowButton href="#test-1" label="Usability Test 1" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item sm={6}>
                                <DownArrowButton href="#redesign" label="Redesign Data Vis" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item sm={6}>
                                <DownArrowButton href="#test-2" label="Usability Test 2" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item sm={6}>
                                <DownArrowButton href="#findings" label="Overall Findings" bg={projectColors[3]}/>
                            </Grid>
                        </Grid>
                    :
                        <Grid container spacing={0} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Grid item sm={4}>
                                <DownStartArrowButton href="#bg-research" label="Background Research" bg={projectColors[0]} />
                            </Grid>
                            <Grid item sm={4}>
                                <DownArrowButton href="#research-tools" label="Research Tools" bg={projectColors[0]}/>
                            </Grid>
                            <Grid item sm={4}>
                                <DownArrowButton href="#bg-interviews" label="Participant Interviews" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item sm={4}>
                                <DownArrowButton href="#test-1" label="Usability Test 1" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item sm={4}>
                                <DownArrowButton href="#redesign" label="Redesign Data Vis" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item sm={4}>
                                <DownArrowButton href="#test-2" label="Usability Test 2" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item sm={4}>
                                <DownArrowButton href="#findings" label="Overall Findings" bg={projectColors[3]}/>
                            </Grid>
                        </Grid>
                    }
                </>
                :
                <Grid container spacing={-5}>
                    <Grid item sm={1.7}>
                        <StartArrowButton href="#bg-research" label="Background Research" bg={projectColors[0]} />
                    </Grid>
                    <Grid item sm={1.7}>
                        <ArrowButton href="#research-tools" label="Research Tools" bg={projectColors[0]}/>
                    </Grid>
                    <Grid item sm={1.7}>
                        <ArrowButton href="#bg-interviews" label="Participant Interviews" bg={projectColors[1]}/>
                    </Grid>
                    <Grid item sm={1.7}>
                        <ArrowButton href="#test-1" label="Usability Test 1" bg={projectColors[1]}/>
                    </Grid>
                    <Grid item sm={1.7}>
                        <ArrowButton href="#redesign" label="Redesign Data Vis" bg={projectColors[2]}/>
                    </Grid>
                    <Grid item sm={1.7}>
                        <ArrowButton href="#test-2" label="Usability Test 2" bg={projectColors[2]}/>
                    </Grid>
                    <Grid item sm={1.7}>
                        <ArrowButton href="#findings" label="Overall Findings" bg={projectColors[3]}/>
                    </Grid>
                </Grid>
                }    
            </ProjectBody>

            <ProjectHeading id="bg-research" color={projectColors[0]}>
                Background
            </ProjectHeading>
            
            <ProjectBody border>
                Many <b style={{ color: projectColors[0] }}>older adults</b> are now relying on digital technology to <b style={{ color: projectColors[0] }}>monitor their health at home</b>. However, this user group often has blurred vision, such as <b style={{ color: projectColors[0] }}>presbyopia (age-related far-sightedness), and less commonly, cataracts</b>.**<br/><br/>

                Design accessibility guidelines and tools tend to <b style={{ color: projectColors[0] }}>address visual impairment quite broadly</b>. When specific visual impairment conditions are addressed, they are usually related to color deficiency.<br/><br/>
            
                Screen magnifier is typically recommended for visually impaired users. However, <b style={{ color: projectColors[0] }}>screen magnifier interactions on mobile devices often interfere with other app or platform interactions</b>. For example, when zoomed in on an Android phone with the "triple tap" magnifier, one cannot freely move around the screen without accidentally triggering other swipe gestures. Magnifiers seem to be more of an aftertought and <b style={{ color: projectColors[0] }}>not always well integrated with app functions</b>.<br/><br/>

                Thus, the goal of my project is to find ways to <b style={{ color: projectColors[0] }}>integrate designs that are more accessible for users with blurred vision into consumer health app data visualizations</b>.<br/><br/><br/>

                <i>**Because some other visual conditions common to older adults consist of obstructed vision, I am leaving those conditions out of the scope of this project.</i>
            </ProjectBody>

            <ProjectHeading color={projectColors[0]} id="research-tools">
                Research Tools
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid mt={3} mb={6} item xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            Visualizations to Test and Redesign
                        </Typography>
                        <p>
                            I selected three popular Android health applications from different subcategories. I chose the Android platform because it is the mobile device that I have available.<br/><br/>

                            I <b style={{color: projectColors[0]}}>analyzed the line and bar chart designs</b> that the health apps below all use to visualize data.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={7}>
                    <Grid item sm={4} xs={12} textAlign="center">
                        <Box>
                            <img src={Images.dataVis.logo.medisafe} style={{ paddingBottom: '10px' }} alt="Medisafe app logo"/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.medisafe.android.client" target="_blank" rel="noreferrer" style={{ color: projectColors[0]}}>
                                Medisafe Pill & Med Reminder
                            </a>
                        </Box>
                        <Box>
                            Diary and reminder to track medication & symptoms
                        </Box>

                    </Grid>
                    <Grid item sm={4} xs={12}  textAlign="center">
                        <Box>
                            <img src={Images.dataVis.logo.lumosity} style={{ paddingBottom: '10px' }} alt="Lumosity app logo"/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.lumoslabs.lumosity" target="_blank" rel="noreferrer" style={{ color: projectColors[0]}}>
                                Lumosity: Brain Training
                            </a>
                        </Box>
                        <Box>
                            A collection of games for congitive exercise
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}  textAlign="center">
                        <Box>
                            <img src={Images.dataVis.logo.fitbit} style={{ paddingBottom: '10px' }} alt="Fitbit app logo"/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.fitbit.FitbitMobile" target="_blank" rel="noreferrer" style={{ color: projectColors[0]}}>
                                Fitbit
                            </a>
                        </Box>
                        <Box>
                            Monitor activity, heart rate, oxygen level, sleep, etc using watch and mobile app
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item mt={6} xs={12}>
                        For Medisafe and Lumosity, I used the applications for about 2 weeks to generate my own data and captured the visualizations for testing. However, I do not have access to a Fitbit watch, thus I used screenshots posted on an
                        &nbsp;<a href="https://www.androidcentral.com/how-track-sleep-fitbit" target="_blank" rel="noreferrer" style={{ color: projectColors[0]}}>Android Central article</a> for evaluation.
                    </Grid>
                    <Grid item my={6} xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            Additional Tools
                        </Typography>
                        <p>
                            Because my 3 participants have different levels of blurred vision severity, and because <b style={{color: projectColors[0]}}>I could not find test participants with cataracts</b>, I also used visual impairment <b style={{color: projectColors[0]}}>simulator apps</b> below to get a better idea of the target users' visual experience.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={7}>
                    <Grid item sm={6} xs={12} textAlign="center">
                        <Box>
                            <img src={Images.dataVis.logo.viaopta} style={{ paddingBottom: '10px' }} alt="ViaOpta Simulator app logo"/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.novartis.visionsimulator" target="_blank" rel="noreferrer" style={{ color: projectColors[0]}}>
                                ViaOpta Simulator
                            </a>
                        </Box>
                        <Box>
                            Has simulator for cataracts, can adjust severity level
                        </Box>

                    </Grid>
                    <Grid item sm={6} xs={12}  textAlign="center">
                        <Box mb={2}>
                            <img src={Images.dataVis.logo.tengo} style={{ paddingBottom: '10px' }} alt="Tengo Baja Vision Simulator app logo"/><br/>
                            <a href="https://play.google.com/store/apps/details?id=eu.akting.tengobajavisionvr" target="_blank" rel="noreferrer" style={{ color: projectColors[0]}}>
                                Tengo Baja Vision - Simulator
                            </a>
                        </Box>
                        <Box>
                            Has simulator for loss of visual acuity (inability to focus eyes, causing blurred vision), can adjust severity level
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item mt={6} xs={12}>
                        Some downsides to these simulators is that the adjustable <b style={{color: projectColors[0]}}>severity levels do not use familiar scales</b> to measure visual impairment. Tengo Baja Vision uses percentage of visual acuity loss instead of eyeglasses prescription scale. The ViaOpta simulator just calls cataracts severity levels as "mild", "moderate", and "severe". Thus, I ended up <b style={{color: projectColors[0]}}>mostly just using the severe cataracts simulation for additional evaluation</b>.
                    </Grid>
                </Grid>
            </ProjectBody>

            <ProjectHeading color={projectColors[1]} id="bg-interviews">
                Participants and Background Interview
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item mb={6} xs={12} mt={3}>
                        I recruited 2 target user participants with presbyopia (age-related far-sightedness), and 1 participant with myopia (near-sightedness) to provide additional feedback due to their experience working with charts.<br/>
                    </Grid>
                </Grid>
                <Grid container spacing={7}>
                    <Grid item sm={4} xs={12} display="flex" justifyContent="center" textAlign="center">
                        <Box mb={2}>
                            <SvgIcon component={PersonIcon} sx={{ fontSize: '200px', color: COLORS.ORANGE, marginBottom:'-10px' }} /><br/>
                            <b style={{ fontSize: '28px', color: COLORS.ORANGE }}>P1</b><br/><br/>
                            <b style={{ color: COLORS.ORANGE }}>Myopia</b><br/>
                            Left -4, right-3.5,<br/>
                            with slight astigmatism.<br/><br/>
                            Uses charts at work
                        </Box>

                    </Grid>
                    <Grid item sm={4} xs={12} textAlign="center">
                        <Box mb={2}>
                            <SvgIcon component={PersonIcon} sx={{ fontSize: '200px', color: projectColors[2], marginBottom:'-10px' }} /><br/>
                            <b style={{ fontSize: '28px', color: projectColors[2] }}>P2</b><br/><br/>
                            <b style={{ color: projectColors[2] }}>Presbyopia</b><br/>
                            Between +1.5 and +2, stronger on right,<br/>
                            with slight myopia.<br/><br/>
                            Used charts at work (retired now)
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12}  textAlign="center">
                        <Box mb={2}>
                            <SvgIcon component={PersonIcon} sx={{ fontSize: '200px', color: COLORS.LAVENDER, marginBottom:'-10px' }} /><br/>
                            <b style={{ fontSize: '28px', color: COLORS.LAVENDER }}>P3</b><br/><br/>
                            <b style={{ color: COLORS.LAVENDER }}>Presbyopia</b><br/>
                            +3 both sides,<br/>
                            with slight myopia.<br/><br/>
                            Does not usually look at charts/data visualization
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item mt={6} xs={12}>
                        I conducted <b style={{color: projectColors[1]}}>semi-structured background interviews</b> to ask <b style={{color: projectColors[1]}}>presbyopia</b> participants (P2 & P3) more in-depth about their <b style={{color: projectColors[1]}}>day-to-day experience</b> of the condition, and how it affects their smartphone viewing/reading habits. The interviews revealed that:
                        <ul>
                            <li>P2 & P3 experience <b style={{color: projectColors[1]}}>visual discomfort</b> if they wear reading glasses too long or when not reading</li>
                            <li>P3 Does not want to wear bifocal glasses due to <b style={{color: projectColors[1]}}>physical discomfort</b> of wearing glasses</li>
                            <li>P2 wears reading glasses on the tip of nose, so that they can <b style={{color: projectColors[1]}}>quickly look up at other things</b> (not through the glasses)</li>
                            <li>P2 & P3 often <b style={{color: projectColors[1]}}>misplace</b> their reading glasses</li>
                            <li>P3's eye grade is 3+ and <b style={{color: projectColors[1]}}>cannot read their phone even at arm’s length</b> without reading glasses</li>
                        </ul>
                        Thus improving presbyopia patients' smartphone visualization viewing experience without reading glasses could be beneficial
                    </Grid>
                </Grid>

                
            </ProjectBody>

            <ProjectHeading color={projectColors[1]} id="test-1">
                Test of Existing Apps' Visualizations
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item xs={12} mt={3}>
                        <Typography variant="h4" color={projectColors[1]}>
                            Method
                        </Typography>
                        <p>
                            Due to COVID-19 restrictions, all testing with participants were conducted remotely through <b style={{color: projectColors[1]}}>Zoom video calls</b>. Each test was about <b style={{color: projectColors[1]}}>30 minutes long and in the form of semi-structured interviews</b>. All participants were asked to <b style={{color: projectColors[1]}}>take off their corrective lenses</b> when viewing visualization screens.
                        </p>
                        <ul>
                            <li>Participants could not view data visualizations on the native mobile apps without generating their own data for 1-2 weeks</li>
                            <li>Because generating data takes too much effort, I <b style={{color: projectColors[1]}}>sent screenshots from the apps for participants to view on their own mobile phones</b></li>
                            <li>While interviewing them on Zoom, I ask participants to view the screenshots one-by-one on full-screen</li>
                            <li>I cannot control the exact screen size & resolution of participants' mobile devices, but I <b style={{color: projectColors[1]}}>standardized the screenshot size, quality, and viewing instructions as much as possible</b></li>
                        </ul>
                    </Grid>
                    <Grid item mb={6} xs={12}>
                        <Typography variant="h4" color={projectColors[1]} mb={1}>
                            Questions
                        </Typography>
                        <b style={{color: projectColors[1]}}>For each chart</b> I showed the participants, I asked them to answer the following questions:
                        <ul>
                            <li>What can you see on this chart? Please describe as best as you can.</li>
                            <li>What can’t you see clearly?</li>
                            <li>What are the highest and lowest points on the chart?</li>
                        </ul>
                        <br/>
                        <b style={{color: projectColors[1]}}>When applicable</b>, I also asked them additional questions like the following:
                        <ul>
                            <li>How many different colors can you see on the line/bar?</li>
                            <li>How many times did this person wake up/ go into deep sleep?</li>
                            <li>Between bar X and bar Y, which one is higher?</li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container spacing={7} display="flex" justifyContent="center" textAlign="center">
                    <Grid item xs={12} textAlign="left">
                        <Typography variant="h4" color={projectColors[1]}>
                            Original Line Charts
                        </Typography>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <ImageModal image={Images.dataVis.line.medisafe.original} borderRadius="10" padding="1px" alt="Medisafe original line chart"/>
                        <b style={{ color: projectColors[1] }}>Medisafe</b>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <ImageModal image={Images.dataVis.line.lumosity.original} borderRadius="10" padding="1px" alt="Lumosity original line chart"/>
                        <b style={{ color: projectColors[1] }}>Lumosity</b>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <ImageModal image={Images.dataVis.line.fitbit.original} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Fitbit original line chart"/>
                        <b style={{ color: projectColors[1] }}>Fitbit</b> (top only)<br/>
                        <span style={{ fontSize: '14px' }}>
                            from <a href="https://www.androidcentral.com/how-track-sleep-fitbit" target="_blank" rel="noreferrer" style={{ color: projectColors[1] }}>Android Central</a>
                        </span>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item mt={3} xs={12}>
                        <ul style={{ listStyle: 'none'}}>
                            <Typography variant="h5" color={COLORS.MINT} mb={2}>
                                Easy to See (◉‿◉)
                            </Typography>
                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                <b style={{color: COLORS.MINT}}>General shapes of chart were easy to see</b>, except for Fitbit line chart
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Myopia participant could read all visualizations perfectly without corrective lenses, but presbyopia participants struggled to see some visual elements listed below
                            </li>

                            <Typography variant="h5" color={projectColors[2]} mt={4} mb={2}>
                                Difficult to See (≖_≖)
                            </Typography>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Presbyopia participants generally found the <b style={{color: projectColors[2]}}>text labels</b> most difficult to read
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Participants found that the <b style={{color: projectColors[2]}}>lines of Fitbit line chart are too close together</b>
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                <b style={{color: projectColors[2]}}>Some colors on Fitbit line chart</b> seemed to blend with each other or into the background because there is <b style={{color: projectColors[2]}}>not enough contrast</b>. P3 thought only 2 colors were used for the data series when there were 4.
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                <b style={{color: projectColors[2]}}>Horizontal guidelines</b> that are supposed to help identify y-values were difficult to see
                            </li>
                        </ul>
                    </Grid>

                    <Grid item xs={12} mb={6}>
                        <Typography variant="h4" color={projectColors[1]}>
                            Original Bar Charts
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={7} display="flex" justifyContent="center" textAlign="center">
                    <Grid item sm={3} xs={8}>
                        <ImageModal image={Images.dataVis.bar.lumosity.original} borderRadius="10" padding="1px" alt="Lumosity original bar chart"/>
                        <b style={{ color: projectColors[1] }}>Lumosity</b>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <ImageModal image={Images.dataVis.bar.fitbit.original} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Fitbit original bar chart"/>
                        <b style={{ color: projectColors[1] }}>Fitbit </b> (top only)<br/>
                        <span style={{ fontSize: '14px' }}>
                            from <a href="https://www.androidcentral.com/how-track-sleep-fitbit" target="_blank" rel="noreferrer" style={{ color: projectColors[1] }}>Android Central</a>
                        </span>
                    </Grid>
                    <Grid item sm={3} xs={8}>
                        <ImageModal image={Images.dataVis.bar.fitbitStacked.original} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Fitbit original stacked bar chart"/>
                        <b style={{ color: projectColors[1] }}>Fitbit</b><br/>
                        stacked bar<br/>
                        (top only)<br/>
                        <span style={{ fontSize: '14px' }}>
                            from <a href="https://www.androidcentral.com/how-track-sleep-fitbit" target="_blank" rel="noreferrer" style={{ color: projectColors[1] }}>Android Central</a>
                        </span>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item mt={6} xs={12}>
                        <p>
                            Like with the line charts, presbyopia participants generally found it quite difficult to read text labels, while the myopia participant could easy see everything without using corrective lenses.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <ul style={{ listStyle: "none" }}>
                            <Typography variant="h5" color={COLORS.MINT} mb={2}>
                                Easy to See (◉‿◉)
                            </Typography>
                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                All participants could <b style={{color: COLORS.MINT}}>identify distinct bars and see the difference in their heights</b>
                            </li>

                            <Typography variant="h5" color={projectColors[2]} mt={4} mb={2}>
                                Difficult to See (≖_≖)
                            </Typography>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Like with Fitbit line chart, presbyopia participants had <b style={{color: projectColors[2]}}>difficulties seeing the data series colors</b> used on Fitbit stacked bar chart (right)
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                P3 had a <b style={{color: projectColors[2]}}>difficult time seeing the yellow triangle</b> on Lumosity chart (left)
                            </li>
                        </ul>
                    </Grid>

                    <Grid item mb={6} xs={12}>
                        <Typography variant="h4" color={projectColors[1]}>
                            Using Simulators
                        </Typography>
                        <p>
                            I also applied the visual impairment simulators, mainly the <a href="https://play.google.com/store/apps/details?id=com.novartis.visionsimulator" target="_blank" rel="noreferrer" style={{ color: projectColors[1]}}>ViaOpta</a> 
                            &nbsp;<b style={{color: projectColors[1]}}>cataracts simulator</b>, to see how else the chart designs could be made more accessible.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={7} textAlign="center">
                    <Grid item sm={6} xs={12}>
                        <Box display="flex" sx={{textAlign:"center" }}>
                            <ImageModal image={Images.dataVis.simulator.original.fitbit} alt="Fitbit original stacked bar chart filtered through ViaOpta severe cataracts simulator" borderRadius="10" padding="1px" width="50%" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }}/>
                        </Box>
                        <b style={{ color: projectColors[1]}}>Severe Cataracts</b> simulation of<br/>
                        <b style={{ color: projectColors[1]}}>Fitbit</b> stacked bar chart (top only)<br/>
                        <span style={{ fontSize: '14px' }}>
                            Original image from <a href="https://www.androidcentral.com/how-track-sleep-fitbit" target="_blank" rel="noreferrer" style={{ color: projectColors[1] }}>Android Central</a>
                        </span>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box display="flex" sx={{textAlign:"center" }}>
                            <ImageModal image={Images.dataVis.simulator.original.lumosity} alt="Lumosity original line chart filtered through ViaOpta severe cataracts simulator" borderRadius="10" padding="1px" width="50%" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }}/>
                        </Box>
                        <b style={{ color: projectColors[1]}}>Severe Cataracts</b> simulation of<br/>
                        <b style={{ color: projectColors[1]}}>Lumosity</b> line chart<br/>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item mt={6} xs={12}>
                        <ul style={{ listStyle: "none" }}>
                        <Typography variant="h5" color={COLORS.MINT}  mt={4} mb={2}>
                            Easy to See (◉‿◉)
                        </Typography>

                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Can tell the <b style={{ color: COLORS.MINT }}>difference between light and dark colors</b>
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Some high contrast text is still <b style={{ color: COLORS.MINT }}>readable if located at the center of field of vision</b>
                            </li>

                            <Typography variant="h5" color={projectColors[2]} mt={4} mb={2}>
                                Difficult to See (≖_≖)
                            </Typography>

                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Colors with the <b style={{ color: projectColors[2] }}>same brightness level look the same</b>
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Images and text at the <b style={{ color: projectColors[2] }}>edges of field of vision look more blurry</b>
                            </li>
                        </ul>
                    </Grid>

                    <Grid item mt={6} xs={12} textAlign="left">
                        <Typography variant="h4" color={projectColors[1]}>
                            Analysis of Interactive Elements
                        </Typography>
                        <p>
                            Because the test participants could not interact with the app themselves, I analyzed the interactivity of their data visualization screens.
                        </p>
                    </Grid>

                    <Grid item xs={12} mb={3} textAlign="left">
                        <Typography variant="h5" color={projectColors[1]}>
                            Medisafe
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={7} display="flex" justifyContent="center">
                    <Grid item sm={6} xs={9} mb={-5}>
                        <ImageModal image={Images.dataVis.interaction.medisafe1} borderRadius="0" alt="Medisafe original line chart with data point label and zoomed y-axis"/>
                    </Grid>

                    <Grid item sm={6} xs={9} mb={-5}>
                        <ImageModal image={Images.dataVis.interaction.medisafe2} borderRadius="0" width="90%" alt="Medisafe original line chart with data point label and zoomed x-axis"/>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} mt={6}>
                        <ul style={{ listStyle: "none" }}>
                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Can <b style={{ color: COLORS.MINT }}>"zoom" x- and y-axes</b> using 2-finger stretch
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Can see <b style={{ color: COLORS.MINT }}>y-axis value label by tapping</b> on each data point
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Screen <b style={{ color: projectColors[2] }}>cannot flip</b> to landscape
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                <b style={{ color: projectColors[2] }}>No full-screen mode</b>
                            </li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Lumosity
                        </Typography>
                        <ul style={{ listStyle: "none" }}>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Data visualization is <b style={{ color: projectColors[2] }}>not interactable</b>
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                Screen <b style={{ color: projectColors[2] }}>cannot flip</b> to landscape
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                <b style={{ color: projectColors[2] }}>No full-screen mode</b>
                            </li>
                        </ul>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Fitbit
                        </Typography>
                        <br/>
                        I could not use the app myself because I had no access to Fitbit watch, but according to my online research:
                        <ul style={{ listStyle: "none" }}>
                            <li style={ liIcon }>
                                <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                <b style={{ color: COLORS.MINT }}>Full-screen and landscape</b> views are supposedly available
                            </li>
                            <li style={ liIcon }>
                                <SvgIcon component={CloseRounded} sx={{ color: projectColors[2], fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                                However some Fitbit users online claimed they
                                &nbsp;<a href="https://community.fitbit.com/t5/iOS-App/Sleep-graphs-won-t-turn-landscape-after-quot-upgraded-quot-to-premium/td-p/4769533" style={{color: projectColors[2] }}>had difficulties accessing it</a> (as of 2021)</li>
                        </ul>
                    </Grid>
                </Grid>
            </ProjectBody>

            <ProjectHeading color={projectColors[2]} id="redesign">
                Redesigns
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3} display="flex" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <p>
                            Based on the findings of initial testing, these are the steps I took to make the data visualization screens more accessible for the target users:
                        </p>
                        <ul>
                            <li>
                                Make all <b style={{ color: projectColors[2] }}>text labels bigger</b>
                            </li>
                            <li>
                                Make <b style={{ color: projectColors[2] }}>every other x- and y-axes label bigger</b> due to limited space
                            </li>
                            <li>
                                Show <b style={{ color: projectColors[2] }}>label/caption of each data point's values</b> upon tapping it
                            </li>
                            <li>
                                Increase <b style={{ color: projectColors[2] }}>color contrast</b> between foreground and background
                            </li>
                            <li>
                                Add <b style={{ color: projectColors[2] }}>negative space</b> between adjacent data areas to help distinguish them
                            </li>
                            <li>
                                Make <b style={{ color: projectColors[2] }}>grids and guidelines</b> more visible to help user estimate data values
                            </li>
                        </ul>
                    </Grid>
                    <Grid item mt={6} mb={3} xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Medisafe Line Chart
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={7} display="flex" justifyContent="center">

                    <Grid item sm={3.6} xs={7} textAlign={{sm: 'left', xs: "center"}}>
                        <ImageModal image={Images.dataVis.line.medisafe.portrait} borderRadius="15" padding="0" alt="Redesigned Medisafe line chart on portrait mode"/>
                        Portrait
                    </Grid>

                    <Grid item sm={6} xs={11}  textAlign={{sm: 'left', xs: "center"}}>
                        <ImageModal image={Images.dataVis.line.medisafe.landscape} borderRadius="15" padding="0" alt="Redesigned Medisafe line chart on landscape mode"/>
                        Landscape<br/><br/>
                        <ImageModal image={Images.dataVis.line.medisafe.full} borderRadius="15" padding="0" alt="Redesigned Medisafe line chart on full-screen mode"/>
                        Full-screen
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item my={6} xs={12}>
                        Initially, I redesigned the portrait and landscape views of the Medisafe app. However, soon it became clear to me 
                        that it is <b style={{ color: projectColors[2] }}>most helpful for users to be able to see all visualizations on full-screen/maximum posible screen size</b>. Thus I proceeded with designing 
                        all of the other visualizations on full-screen mode.
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            All Redesigned Screens
                        </Typography>
                        <br/>
                        <ImageModal image={Images.dataVis.all} alt="Screenshot of all redesigned data visualization screens" />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <LinkButton
                            id="redesign-figma"
                            href="https://www.figma.com/file/xJHkte4KfpMQAJJduMsSFd/Data-Vis-for-Blurred-Vision?node-id=218%3A3"
                            bg={projectColors[2]}
                            text="Open in Figma"
                        />
                    </Grid>
                    <Grid item mt={6} xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Clickable Redesigned Screens
                        </Typography>
                        <p>
                            Although I did not have the chance to make the screens interactable in-time for the second usability testing, 
                            below is how I envisioned and explained to test participants that the redesigned screens would be clickable/tappable in their own native apps.
                        </p>
                        <Box display="flex" justifyContent="center" my={4}>
                            { (isTiny || isXS) && 
                                <>
                                    <LinkButton
                                        id="clickable-tiny"
                                        href="https://www.figma.com/proto/xJHkte4KfpMQAJJduMsSFd/Data-Vis-for-Blurred-Vision?node-id=219%3A1826&scaling=scale-down&page-id=218%3A3&starting-point-node-id=219%3A1826&show-proto-sidebar=1"
                                        bg={projectColors[2]}
                                        text="Open Prototypes in a New Tab"
                                    />
                                </>
                            }

                            { isSM && <iframe title="Data Vis for Blurred Vision Redesigned (Clickable) - small screen" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="550" height="415" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FxJHkte4KfpMQAJJduMsSFd%2FData-Vis-for-Blurred-Vision%3Fnode-id%3D219%253A1826%26scaling%3Dscale-down%26page-id%3D218%253A3%26starting-point-node-id%3D219%253A1826%26show-proto-sidebar%3D1" allowfullscreen/> }

                            { isMD && <iframe title="Data Vis for Blurred Vision Redesigned (Clickable) - medium screen" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="800" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FxJHkte4KfpMQAJJduMsSFd%2FData-Vis-for-Blurred-Vision%3Fnode-id%3D219%253A1826%26scaling%3Dscale-down%26page-id%3D218%253A3%26starting-point-node-id%3D219%253A1826%26show-proto-sidebar%3D1" allowfullscreen/> }
                        </Box>
                    </Grid>
                    { (isSM || isMD) && 
                        <Grid item mb={3} xs={12} display="flex" justifyContent="center">
                            <LinkButton
                                id="clickable-med"
                                href="https://www.figma.com/proto/xJHkte4KfpMQAJJduMsSFd/Data-Vis-for-Blurred-Vision?kind=&node-id=219%3A1826&page-id=218%3A3&scaling=scale-down&show-proto-sidebar=1&starting-point-node-id=219%3A1826"
                                bg={projectColors[2]}
                                text="Open Prototypes in a New Tab"
                            />
                        </Grid>
                    }
                    
                </Grid>

            </ProjectBody>

            <ProjectHeading color={projectColors[2]} id="test-2">
                Test of Redesigned Visualizations
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3} display="flex" justifyContent="center">
                    <Grid item xs={12} mt={3}>
                        <p>
                            All of the redesigned screens were <b style={{ color: projectColors[2] }}>remotely tested again with the same 3 participants</b>, using the same method as the first test.<br/><br/>
                            Because <b style={{ color: projectColors[2] }}>participants could not interact with the designs directly on their phone, the possible interactions between screens were explained to them verbally</b>.<br/><br/>
                        </p>
                        
                        <Typography variant="h4" color={projectColors[2]} mb={1}>
                            Questions
                        </Typography>

                        <b style={{ color: projectColors[2] }}>For each chart</b>, they were asked the following questions:
                        <ul>
                            <li>What can you see on this chart? Please describe as best as you can.</li>
                            <li>Is there anything you still cannot see or read?</li>
                            <li>Which visualization elements are useful? Which are confusing/distracting?</li>
                        </ul>
                        <br/>
                        <b style={{ color: projectColors[2] }}>When applicable</b>, they were also asked questions such as:
                        <ul>
                            <li>Between Screen 1 and Screen 2, which one is easier to read?</li>
                            <li>If this app has X interaction, would you find it helpful or not?</li>
                            <li>What suggestions do you have for improving the visualization & interactions?</li>
                        </ul>
                        <br/>
                        Below are some <b style={{ color: projectColors[2] }}>highlights of my test findings</b>.
                    </Grid>
                    <Grid mt={3} item xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Lumosity Line Chart
                        </Typography>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.line.lumosity.default} alt="Redesigned Lumosity line chart, default view"/>
                    </Grid>
                    <Grid item xs={12}>
                        For this chart, I used the <b style={{ color: projectColors[2] }}>same color palette and font as the original design</b> on the Lumosity app.<br/><br/>
                        
                        The first screen is essentially a larger, more detailed version of the original Lumosity visualization. I intended this to be the <b style={{ color: projectColors[2] }}>default screen</b> before the user selects a specific data point.<br/><br/>
                        
                        However, participants found that the <b style={{ color: projectColors[2] }}>white text against the green background</b>, as well as the <b style={{ color: projectColors[2] }}>green and gray texts against the white background, to be difficult to read</b>, even with the much bigger font in the redesign.<br/><br/>

                        The participant with myopia also <b style={{ color: projectColors[2] }}>failed to notice the screen expand/shrink button</b> at the top-right corner of the screen. Thus, this element should be made larger and more obvious.
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.line.lumosity.selected} alt="Redesigned Lumosity line chart, with 1 data point selected"/>
                    </Grid>
                    <Grid item xs={12}>
                        This second screen is what would appear when the user <b style={{ color: projectColors[2] }}>taps on a specific data point on the chart</b>.<br/><br/>

                        Participants found the caption with the <b style={{ color: projectColors[2] }}>orange background much easier to read than the green ones</b> in first screen, because of the <b style={{ color: projectColors[2] }}>higher color contrast</b> against the text.<br/><br/>

                        I created these designs <b style={{ color: projectColors[2] }}>before I learned in detail about web color accessibility and</b> <a href="https://webaim.org/resources/contrastchecker/" target="_blank" rel="noreferrer" style={{ color: projectColors[2]}}>color contrast checker tool</a>. My participants (especially P3, with the most severe presbyopia) confirmed that color contrast is indeed important in increasing text readability for users with blurred vision.<br/><br/>

                        Thus, if I were to revise this data visualization design again, I would <b style={{ color: projectColors[2] }}>pick a darker shade of green for the primary color, keep the orange as secondary color, and just use black for all the gray text</b>.
                    </Grid>
                </Grid>

                <Grid container spacing={3} display="flex" justifyContent="center">
                    <Grid item mt={6} xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Fitbit Line Chart
                        </Typography>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.line.fitbit.unselected} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Redesigned Fitbit line chart, default view"/>
                    </Grid>
                    <Grid item xs={12}>
                        Participants overall reported that my redesign for the Fitbit sleep line chart is <b style={{ color: projectColors[2] }}>much more easily readable than the original chart</b>.<br/><br/>

                        The presbyopia participants, who previously could not see the data series in darker blue colors at all, now <b style={{ color: projectColors[2] }}>can see all 4 colors and identify what they represent</b>.<br/><br/>

                        However, P3 (most severe presbyopia), still has <b style={{ color: projectColors[2] }}>a bit of difficulty seeing the purple color</b> (representing 'Deep Sleep'). Again, this is where a color contrast checker tool would be useful for design revision.
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.line.fitbit.selected} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Redesigned Fitbit line chart, with 1 data segment selected"/>
                    </Grid>
                    <Grid item xs={12}>
                        All participants could easily <b style={{ color: projectColors[2] }}>understand that the second screen is a selection</b> of a particular data point in the chart.<br/><br/>

                        One small thing that can be improved, as pointed out by P3 again, is that when the y-axis text is <b style={{ color: projectColors[2] }}>highlighted in white</b> (when a data point on that value is selected), the <b style={{ color: projectColors[2] }}>text becomes more blurry</b>. Thus, this design element might be unnecessary.
                    </Grid>
                </Grid>

                <Grid container spacing={3} display="flex" justifyContent="center">
                    <Grid item mt={6} xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Fitbit Stacked Bar Chart
                        </Typography>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.bar.fitbitStacked.unselected} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Redesigned Fitbit stacked bar chart, default view"/>
                    </Grid>
                    <Grid item xs={12}>
                        Like with the Fitbit sleep line chart, my participants overall also found the stacked bar chart areas <b style={{ color: projectColors[2] }}>more readable than the original design</b>, due to the data series colors having higher contrast against the background.
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.bar.fitbitStacked.selected} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Redesigned Fitbit stacked bar chart, with 1 bar selected"/>
                    </Grid>
                    <Grid item xs={12}>
                        Above is the visualization when the Thursday ('T') <b style={{ color: projectColors[2] }}>stacked bar is selected</b>.<br/><br/>
                        
                        P1 (with myopia), who uses data visualziations for work, pointed out how the <b style={{ color: projectColors[2] }}>enlarged label of the selected bar</b> could create a visual illusion that the bar is <b style={{ color: projectColors[2] }}>taller than an unselected bar of the same height</b>.<br/><br/>

                        The stacks also create a visual design problem of how to <b style={{ color: projectColors[2] }}>fit in detailed value labels of each stack</b> in the bar. I attempted to fit the value labels next to each stack, but I think this might also create <b style={{ color: projectColors[2] }}>visual confusion</b> because the <b style={{ color: projectColors[2] }}>selected bar becomes skinnier</b> to make space for the labels.<br/><br/>

                        P1 felt that he might <b style={{ color: projectColors[2] }}>want to compare each stack/data series across the days</b> of the week. Thus, he suggested that <b style={{ color: projectColors[2] }}>more interactions & views</b> that allow users to <b style={{ color: projectColors[2] }}>dig into the details</b> of the data could be more useful than labeling the stack of each selected bar. 
                    </Grid>
                </Grid>

                <Grid container spacing={3} display="flex" justifyContent="center">
                    <Grid item mt={6} xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Using Simulator
                        </Typography>
                        <p>
                            Below are <b style={{ color: projectColors[2] }}>Severe Cataracts simulations</b> of my redesigned Fitbit line and stacked bar charts.
                        </p>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.simulator.redesigns.fitbitLine} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Redesigned Fitbit line chart, default view, filtered through ViaOpta severe cataracts simulator"/>
                    </Grid>
                    <Grid item xs={12}>
                        Despite the different colors/data series being more visible to participants with only blurred vision, when fitered through the brown-tinted vision of more severe cataracts, the <b style={{ color: projectColors[2] }}>different colors look the same due to their having about the same brightness levels</b>.<br/><br/>

                        However, because the <b style={{ color: projectColors[2] }}>y-axis is labeled and the chart has guidelines going across</b>, the user's <b style={{ color: projectColors[2] }}>ability to read the chart is not really affected</b> by the lack of color distinctions between the data series.
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.dataVis.simulator.redesigns.fitbitStacked} borderRadius="10" padding="1px" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }} alt="Redesigned Fitbit stacked bar chart, default view, filtered through ViaOpta severe cataracts simulator"/>
                    </Grid>
                    <Grid item xs={12}>
                        Unlike the line chart, when the same data series colors are used for the stacked bar chart, it <b style={{ color: projectColors[2] }}>might not be readable for a user with brown-tinted cataracts vision</b>.<br/><br/>

                        A user with severe cataracts perhaps could still see the distinct stacks because they are clearly separated with negative space, but the <b style={{ color: projectColors[2] }}>legend does not show which stack is which kind of sleep stage</b>.<br/><br/>
                        
                        The original stacked chart design has more brightness gradients between data series, but a user with cataracts might also find it hard to identify colors that do not significantly contrast the background.<br/><br/>

                        However, because the <b style={{ color: projectColors[2] }}>deeper sleep stage is always at the bottom of the stack</b>, the redesigned chart could be improved by <b style={{ color: projectColors[2] }}>changing the legend to spatially show</b> the user which <b style={{ color: projectColors[2] }}>data series</b> is at which stack position in the chart.<br/><br/>

                        Additionally, the colors could be adjusted to have <b style={{ color: projectColors[2] }}>alternating levels of brightness</b>. The bottom and top stacks ('Deep' and 'REM' sleep) could be very bright, and the middle stack ('Light' sleep) could be dark enough to be <b style={{ color: projectColors[2] }}>seen as a different shade of brown, but still has enough contrast against the background</b>.<br/><br/>
                        
                        This way, the user can more easily identify data series even if one of them is missing in the stack (such as on the Thursday stacked bar). And there <b style={{ color: projectColors[2] }}>do not need to be so many data series color gradients</b> that they are indistinguishable from each other or the background.
                    </Grid>
                </Grid>

            </ProjectBody>

            <ProjectHeading id="findings">
                Overall Findings
            </ProjectHeading>
            <ProjectBody>
                <p>
                Based on my findings in this study, the following are my <b>key takeaways</b> of how to design better data visualizations for users with blurred vision:
                
                <ul style={{ listStyle:'none' }}>
                    <li style={ liIcon }>
                        <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                        Provide easily accessible <b style={{ color: COLORS.MINT }}>full screen mode</b> for maximum enlargement of all charts & labels
                    </li>
                    <li style={ liIcon }>
                        <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                        Use <b style={{ color: COLORS.MINT }}>large text sizes</b>
                    </li>
                    <li style={ liIcon }>
                        <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                        Use <b style={{ color: COLORS.MINT }}>high color contrast</b> between foreground & background (and check with <b style={{ color: COLORS.MINT }}>color contrast tools</b>)
                    </li>
                    <li style={ liIcon }>
                        <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                        Enable <b style={{ color: COLORS.MINT }}>selection</b> of data points and text labels to <b style={{ color: COLORS.MINT }}>show/enlarge details</b>
                    </li>
                    <li style={ liIcon }>
                        <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                        <b style={{ color: COLORS.MINT }}>Separate adjacent data series areas</b> (e.g. stacked bar) with highly contrasting outlines or negative space
                    </li>
                    <li style={ liIcon }>
                        <SvgIcon component={CheckRounded} sx={{ color: COLORS.MINT, fontWeight: '700', marginRight: '10px', marginBottom: '-5px'}}/>
                        <b style={{ color: COLORS.MINT }}>Do not rely only on colors and brightness</b> to identify data series or other elements, but also use spatial relationships, labeling, and if necessary, additional interactions
                    </li>
                </ul>
                <br/>

                However, <b>more research should be done</b> to improve the data visualization design guidelines for users with blurred vision. If I had more time and resources, I think that this study could be improved with <b>more participants with a broader variety of blurred vision conditions and severity levels</b>. The redesigns could also be made <b>directly interactible for test participants on their smartphones</b>.<br/><br/>
                
                With my (now) better knowledge of the topic, I would also ensure that the tested visualizations <b>follow existing accessibility & mobile platform guidelines</b>, so that the research findings can <b>further expand from current guidelines</b>.
                </p>
            </ProjectBody>

            <ProjectHeading>
                More Lessons Learned
            </ProjectHeading>
            <ProjectBody>
                <ul>
                    <li>
                        Adding <b>more data point or axes labels</b> in one screen/view does <b>not necessarily add to the richness of information</b> in a chart. Instead, <b>using interactions</b> to display and/or filter different data views can help make the visualization more meaningful.
                    </li>
                    <li>
                        Through this study, I discovered that even <b>some widely popular commercial applications are not necessarily designed with accessibility in mind</b>, even when a significant portion of their target users tend to have certain accessibility problems. It seems like a <b>missed opportunity</b> for the app creators if they do not cater to their target users' accessibility needs.
                    </li>
                </ul>
            </ProjectBody>
            <br/><br/><br/><br/>
            <ProjectHeading style={{ display: 'flex', justifyContent: 'flex-end'}} id="bottom">
                <Button
                    href="/work"
                    display="flex" alignItems="center"
                    sx={{ padding:'15px 30px', marginLeft: '0px', borderRadius: '10px', border: `2px solid ${COLORS.LAVENDER}`,
                    boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                    '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)' } }}>
                        <Typography variant="h4" color="secondary">
                            See more of my work
                        </Typography>
                </Button>
            </ProjectHeading>

            {/* ROADMAP STICKY MENU */}

            {isWide?
                <Fade appear={false} in={showRoadmap} easing={{ enter: "linear", exit: "linear" }}>
                    <MUIAppBar position="fixed" pt={2} m={0} sx={{top: 'auto', bottom: 0, zIndex: 899, minHeight:'85px',
                        display:'flex', alignItems:'center', justifyContent: 'center', boxShadow: '0px -3px 5px 0px rgb(112, 112, 112, 0.3)'}}
                    >
                        <Grid container spacing={-5}  display="flex" justifyContent="center" alignItems="center" mx={{md:3, sm:2}} >
                            <Grid item sm={1.6}>
                                <SkinnyStartArrowButton href="#bg-research" label="Background Research" bg={projectColors[0]} />
                            </Grid>
                            <Grid item sm={1.6}>
                                <SkinnyArrowButton href="#research-tools" label="Research Tools" bg={projectColors[0]}/>
                            </Grid>
                            <Grid item sm={1.6}>
                                <SkinnyArrowButton href="#bg-interviews" label="Participant Interviews" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item sm={1.6}>
                                <SkinnyArrowButton href="#test-1" label="Usability Test 1" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item sm={1.6}>
                                <SkinnyArrowButton href="#redesign" label="Redesign Data Vis" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item sm={1.6}>
                                <SkinnyArrowButton href="#test-2" label="Usability Test 2" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item sm={1.6}>
                                <SkinnyArrowButton href="#findings" label="Overall Findings" bg={projectColors[3]}/>
                            </Grid>
                            <Grid item sm={0.8} display='flex' alignItems='center' justifyContent='flex-start' sx={{pl:4, height: '100%' }}>
                                <StyledOutlineButton fontSize="16px" padding="5px"
                                    onClick={()=>setShowRoadmap(null)}
                                >
                                    <SvgIcon component={CloseRoundedIcon} sx={{ fontSize:{ md: "22px", sm: "16px" }, color: COLORS.LAVENDER}}/>
                                </StyledOutlineButton>
                            </Grid>
                        </Grid>
                    </MUIAppBar>
                </Fade>                    
                :
                <Drawer
                    anchor="left"
                    open={showRoadmap}
                    width="100%"
                    variant="temporary"
                >
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                        sx={{ height: '100%' }}
                    >   
                        <Box display="flex" direction="row" justifyContent="center" mb={3} sx={{ width: '100%'}}>
                            <StyledOutlineButton fontSize="16px" padding="5px"
                                onClick={()=>setShowRoadmap(null)}
                            >
                                <SvgIcon component={CloseRoundedIcon} sx={{ fontSize:"22px", color: COLORS.LAVENDER}}/>
                            </StyledOutlineButton>
                        </Box>

                        <DownStartArrowButton href="#bg-research" label="Background Research" bg={projectColors[0]} style={{height: '15%'}}/>

                        <DownArrowButton href="#research-tools" label="Research Tools" bg={projectColors[0]} style={{height: '15%'}}/>

                        <DownArrowButton href="#bg-interviews" label="Participant Interviews" bg={projectColors[1]} style={{height: '15%'}}/>

                        <DownArrowButton href="#test-1" label="Usability Test 1" bg={projectColors[1]} style={{height: '15%'}}/>

                        <DownArrowButton href="#redesign" label="Redesign Data Vis" bg={projectColors[2]} style={{height: '15%'}}/>

                        <DownArrowButton href="#test-2" label="Usability Test 2" bg={projectColors[2]} style={{height: '15%'}}/>

                        <DownArrowButton href="#findings" label="Overall Findings" bg={projectColors[3]} style={{height: '15%'}}/>

                    </Box>
                </Drawer>    
            }
        </Layout>
    );
};

export default BlurredVision;