import React, { useState } from "react";
import { 
    Layout,
    ProjectTitle,
    ProjectDates,
    ProjectDescription,
    ProjectHeading,
    ProjectBody,
    ImageModal,
    LinkButton,
    SkinnyStartArrowButton,
    SkinnyArrowButton,
    StartArrowButton,
    ArrowButton,
    DownStartArrowButton,
    DownArrowButton,
    StyledButton,
    StyledOutlineButton,
    COLORS } from "core";
import {
    Fade,
    Grid,
    Box,
    Typography,
    Button,
    IconButton,
    Drawer,
    Menu
} from '@mui/material';
import { MUIAppBar } from "../Nav";
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Images, Thumbnail } from "assets";
import SvgIcon from '@mui/material/SvgIcon';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SouthIcon from '@mui/icons-material/South';


const Barterby = props => {

    const [showRoadmap, setShowRoadmap] = useState(null);

    const [anchorElMedFi, setAnchorElMedFi] = React.useState(null);
    const [showRoadmapMedFi, setShowRoadmapMedFi] = useState(null);

    const theme = useTheme();
    const isWide = useMediaQuery(theme.breakpoints.up('700'));
    const isXS = useMediaQuery(theme.breakpoints.between('470','600'));
    const isSM = useMediaQuery(theme.breakpoints.between('600','900'));
    const isMD = useMediaQuery(theme.breakpoints.up('900'));
    const isTiny = useMediaQuery(theme.breakpoints.down('470'));
    const isNotTiny = useMediaQuery(theme.breakpoints.up('470'));

    const projectColors = [
        COLORS.BLUE,'#c7e2ff', '#cbd4fb', COLORS.LAVENDER
    ];

    const skills = ['Prototyping', 'Design System', 'Figma', 'Technical Sketching', 'Ideation'];

    const positionMenuMedFi= (event: any) => {
        setAnchorElMedFi(event.currentTarget);
        setShowRoadmapMedFi(true);
    }

    return(
        <Layout>
            <Box sx={{ display:'flex', flexDirection: 'row', alignItems: 'center', position: 'fixed', top: 'auto', bottom: '10px', left: 'auto', right: '20px', zIndex: 899}}>
                <StyledButton fontSize="16px" padding="5px 10px" style={{ marginRight: "20px" }}
                    onClick={()=>setShowRoadmap(true)}
                >
                    Jump to...
                </StyledButton>
                <Box sx={{ display:'flex', flexDirection: 'column', fontSize:'22px', fontWeight: 700 }}>
                    <IconButton href="#" aria-label="scroll-to-top" color="secondary" size="small"
                        sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                        boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                        '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandLessIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton href="#bottom" aria-label="scroll-to-bottom" color="secondary" size="small"
                            sx={{ mb:0.5, borderRadius: '10px', border: 'none', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER,
                            boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                            '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)', color: COLORS.BLACK, backgroundColor: COLORS.LAVENDER } }}
                    >
                        <ExpandMoreIcon fontSize="inherit" />
                    </IconButton>
                </Box>
            </Box>

            <Box mb={4} display="flex" sx={{ width: '100%' }}>
                <Box bgcolor={ COLORS.BLUE } display="flex" alignItems="center" justifyContent="center"
                    sx={{ borderRadius: '1vw', width:'100%' }}
                >
                    <Thumbnail src={Images.barterby.thumbnail} width="95%" alt="Barterby key screens"/>
                </Box>
            </Box>
            <ProjectTitle color={projectColors[0]}>
                BARTERBY:<br/>
                <Typography variant="h2" color={projectColors[0]}>Craft Supply Barter Marketplace</Typography>
            </ProjectTitle>
            
            <ProjectBody style={{ marginTop: '20px', marginBottom: '10px' }}>
                {skills.map(skill=>{
                    return(
                        <Box display="inline-block" backgroundColor={COLORS.BLACK} px={1.5} py={0.5} mr={1.5} mb={1.5}
                            sx={{ borderRadius:'5px', border: `1px solid ${COLORS.BLUE}` }}>
                            <Typography variant="body1">
                                <span style={{ fontSize: '16px', fontWeight: '700', color: COLORS.BLUE }}>
                                    {skill}
                                </span>
                            </Typography>
                        </Box>
                    );
                })}
            </ProjectBody>

            <ProjectDates>
                Individual UI Design project<br/>
                February-April 2022 (9 weeks, part-time)<br/>
            </ProjectDates>

            <br/><br/>
            <ProjectDescription>
                <Typography variant="h4" color={projectColors[0]}>
                    Problem Space
                </Typography>

                Many craft enthusiasts' homes are cluttered with surplus materials that they do not want to throw away.<br/><br/>
                
                <br/>
                <Typography variant="h4" color={projectColors[0]}>
                    Solution
                </Typography>

                I designed an <b style={{color: projectColors[0]}}>online marketplace</b> where crafters can post, browse, and swap pre-owned supplies with one another.<br/><br/>
                
                Unlike Kijiji or Craigslist, on BARTERBY is specifically a marketplace for <b style={{color: projectColors[0]}}>craft supplies</b>, where users must <b style={{color: projectColors[0]}}>exchange items for other items</b>, and not for money.<br/><br/>
                
                It also has a <b style={{color: projectColors[0]}}>verification system</b> for scheduled in-person swaps or through <b style={{color: projectColors[0]}}>affordable shipping plans</b>.<br/><br/><br/>
                
                <Typography variant="h4" color={projectColors[0]}>
                    Constraints
                </Typography>
                <Typography variant="body1" color="primary">
                    The focus of this project is to quickly come up with high-fidelity UI design of an original digital product idea.<br/><br/>
                    Due to the project's constraints, I could not do extensive research and testing with the product's target users and spend a long time on ideation. 
                    However, I came up with my solutions through <b style={{color: projectColors[0]}}>secondary research, user assumptions, and 3 other designers' feedback</b>.
                </Typography>
            </ProjectDescription>

            <ProjectHeading color={projectColors[0]} narrow>
                My Role
            </ProjectHeading>
            <ProjectBody>
                <ul>
                    <li>Designed <b style={{color: projectColors[0]}}>digital product concept and item swap system</b> based on secondary research of problem space and existing similar platforms</li>
                    <li>Created a UI <a href="#design-system" style={{color: projectColors[0]}}>design system</a> and visual theme for <a href="#mobile" style={{color: projectColors[0]}}>Android mobile app</a> and <a href="#kiosk" style={{color: projectColors[0]}}>package drop-off kiosk</a> following Material Design Guidelines</li>
                    <li>Put together <b style={{color: projectColors[0]}}>high-fidelity storyboards and clickable prototypes</b> for the product's look & feel using Figma</li>
                </ul>
            </ProjectBody>

            <ProjectHeading color={projectColors[0]} narrow>
                Key User Assumptions
            </ProjectHeading>
            <ProjectBody>
                <ul>
                    <li>Does not have significant visual impairment, is English-speaking, and is somewhat familiar with shipping packages</li>
                    <li>Owns a smartphone with mobile internet data and has some experience with ATM, touchscreen kiosks, and/or tablet devices</li>
                    <li>Somewhat familiar with browsing and item checkout process of online shops/marketplaces such as Amazon, eBay, Craigslist, or Kijiji</li>
                </ul>
            </ProjectBody>

            <ProjectHeading color={projectColors[0]}>
                Roadmap
            </ProjectHeading>

            <ProjectBody border>
                {(isXS || isTiny )?
                    <>
                        {isTiny?
                            <Grid container spacing={0} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Grid item xs={6}>
                                    <DownStartArrowButton href="#research" label="Secondary Research" bg={projectColors[0]} />
                                </Grid>
                                <Grid item xs={6}>
                                    <DownArrowButton href="#ideation" label="Ideation" bg={projectColors[0]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DownArrowButton href="#early-prototype" label="Early Prototypes" bg={projectColors[1]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DownArrowButton href="#mobile-medfi" label="Mobile Med-Fi Prototype" bg={projectColors[1]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DownArrowButton href="#mobile-hifi" label="Mobile Hi-Fi Prototype" bg={projectColors[1]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DownArrowButton href="#kiosk-ideation" label="Kiosk Ideation" bg={projectColors[2]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DownArrowButton href="#kiosk-hifi" label="Kiosk Hi-Fi Prototype" bg={projectColors[2]}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <DownArrowButton href="#design-system" label="Design System" bg={projectColors[3]}/>
                                </Grid>
                            </Grid>
                        :
                            <Grid container spacing={0} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Grid item xs={4}>
                                    <DownStartArrowButton href="#research" label="Secondary Research" bg={projectColors[0]} />
                                </Grid>
                                <Grid item xs={4}>
                                    <DownArrowButton href="#ideation" label="Ideation" bg={projectColors[0]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <DownArrowButton href="#early-prototype" label="Early Prototypes" bg={projectColors[1]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <DownArrowButton href="#mobile-medfi" label="Mobile Med-Fi Prototype" bg={projectColors[1]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <DownArrowButton href="#mobile-hifi" label="Mobile Hi-Fi Prototype" bg={projectColors[1]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <DownArrowButton href="#kiosk-ideation" label="Kiosk Ideation" bg={projectColors[2]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <DownArrowButton href="#kiosk-hifi" label="Kiosk Hi-Fi Prototype" bg={projectColors[2]}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <DownArrowButton href="#design-system" label="Design System" bg={projectColors[3]}/>
                                </Grid>
                            </Grid>
                        }
                    </>
                    :
                    <Grid container spacing={-5}>
                        <Grid item xs={1.5}>
                            <StartArrowButton href="#research" variant="overline" mr={{md: -6, sm:-6}} label="Secondary Research" bg={projectColors[0]} />
                        </Grid>
                        <Grid item xs={1.5}>
                            <ArrowButton href="#ideation" variant="overline" mr={{md: -6, sm:-6}} label="Ideation" bg={projectColors[0]}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <ArrowButton href="#early-prototype" variant="overline" mr={{md: -6, sm:-6}} label="Early Prototypes" bg={projectColors[1]}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <ArrowButton href="#mobile-medfi" variant="overline" mr={{md: -6, sm:-6}} label="Mobile Med-Fi Prototype" bg={projectColors[1]}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <ArrowButton href="#mobile-hifi" variant="overline" mr={{md: -6, sm:-6}} label="Mobile Hi-Fi Prototype" bg={projectColors[1]}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <ArrowButton href="#kiosk-ideation" variant="overline" mr={{md: -6, sm:-6}} label="Kiosk Ideation" bg={projectColors[2]}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <ArrowButton href="#kiosk-hifi" variant="overline" mr={{md: -6, sm:-6}} label="Kiosk Hi-Fi Prototype" bg={projectColors[2]}/>
                        </Grid>
                        <Grid item xs={1.5}>
                            <ArrowButton href="#design-system" variant="overline" mr={{md: -6, sm:-6}} label="Design System" bg={projectColors[3]}/>
                        </Grid>
                    </Grid>
                }
            </ProjectBody>
            
            <ProjectHeading id="research" color={projectColors[0]}>
                Secondary Research
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <p>
                            I initially started with the problem space of <b style={{color: projectColors[0]}}>how to save & redistribute resources to reduce waste</b>
                        </p>
                        <p>
                            I also know that people around me who like to craft tend to accumulate a lot of surplus materials they do not use, even though they are not necessarily hoarders. 
                            Thus I decided to narrow down into the topic of <b style={{color: projectColors[0]}}>managing excess craft supplies</b>.
                        </p>
                        <br/>
                        <p>
                            I started doing secondary research on:
                            <ul>
                                <li>
                                    <b style={{color: projectColors[0]}}>Existing channels</b> for getting rid of used items
                                </li>
                                <li>
                                    Online discussions on <b style={{color: projectColors[0]}}>challenges in managing craft supplies</b>
                                </li>
                                <li>
                                    <b style={{color: projectColors[0]}}>Feedback on existing platforms or channels</b> for getting rid of used items
                                </li>
                            </ul>
                        </p>
                    </Grid>
                    <Grid item mb={5} xs={12}>
                        <p>
                            I organized my findings into an affinity diagram to discover my target users' pain points.
                        </p>
                        <ImageModal image={Images.barterby.affinity} alt="Screenshot of affinity diagram of secondary research data"/>
                        <p style={{ marginBottom: '0px'}}>
                            I decided to focus on addressing 4 pain points...
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            1. Giving away or reselling items can still trigger hoarding
                        </Typography>
                        
                        <ImageModal image={Images.barterby.painPoint.one} alt="Screenshot of affinity diagram, first pain point"/>
                        
                        <p>
                            I discovered that items that people put on Craigslist, Kijiji, or goodwill stores could <b style={{color: projectColors[0]}}>trigger hoarding behavior for people interested in buying or receiving free items</b>.
                        </p>
                        <p>
                            This means that if someone comes to such channels with the <b style={{color: projectColors[0]}}>intention of getting rid of their items, they might become tempted to acquire more items</b> that other people have put up there for sale or as donations.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            2. Existing online marketplace sites are too monetarily driven
                        </Typography>
                        
                        <ImageModal image={Images.barterby.painPoint.two} alt="Screenshot of affinity diagram, second pain point"/>
                        <p>
                            Existing marketplace sites like Kijiji & Craigslist usually list items in exchange for money, so they may be <b style={{color: projectColors[0]}}>filled with users whose goal is to make a profit</b>.
                        </p>
                        <p>
                            However, crafters claim that it is easier to part with their materials if they feel that <b style={{color: projectColors[0]}}>another crafter could turn them into finished project</b>. Thus, they specifically look for <b style={{color: projectColors[0]}}>communities of crafters who want to strictly trade materials</b> and not buy or sell, such as on Facebook 
                            and Subreddits <a style={{color: projectColors[0]}} href="https://www.reddit.com/r/craftexchange/">r/craftexchange</a> or <a style={{color: projectColors[0]}} href="https://www.reddit.com/r/FabricSwap/">r/fabricswap</a>.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            3. Trading and transporting items can be difficult
                        </Typography>
                        
                        <ImageModal image={Images.barterby.painPoint.three} alt="Screenshot of affinity diagram, third pain point"/>
                        
                        <p>
                            People also complained that in online marketplaces, it can be <b style={{color: projectColors[0]}}>difficult to coordinate meetings, verify deliveries, and weed out scammers</b>, even with a review system.
                        </p>
                        <p>
                            <b style={{color: projectColors[0]}}>Transporting items</b> are also difficult for large items or people living in more remote areas.
                        </p>
                    </Grid>
                    <Grid item mb={-3} xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            4. Items on general “garage sale” sites are difficult to sort through
                        </Typography>
                    </Grid>
                    <Grid item mb={-3} xs={8}>
                        <ImageModal image={Images.barterby.painPoint.four} borderRadius='15' alt="Screenshot of affinity diagram, fourth pain point"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                            Most existing sites, like Kijiji, Craigstlist, eBay, and Bunz, are marketplaces <b style={{color: projectColors[0]}}>for any item, not specifically craft supplies</b>. Many users also <b style={{color: projectColors[0]}}>list multiple unspecified items in a single posting</b>.
                        </p>
                        <p>
                            This makes it <b style={{color: projectColors[0]}}>overwhelming to sort through</b> for people interested in receiving specific items, and they might even end up taking a box of mixed items they do not really need.
                        </p>
                    </Grid>
                </Grid>
            </ProjectBody>

            <ProjectHeading id="ideation" color={projectColors[0]}>
                Ideation
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <p>
                            I came up with BARTERBY, an <b style={{color: projectColors[0]}}>online marketplace for swapping craft materials</b>, where...
                        </p>
                    </Grid>
                    <Grid item xs={12}>                
                        <Typography variant="h4" color={projectColors[0]}>
                            1. A user gives away an item in exchange for another user's item that they need
                        </Typography>
                        <p>
                            This addresses the first pain point that <b style={{color: projectColors[0]}}>giving away or reselling items on existing channels can still trigger hoarding</b>. Unlike with Kijiji or Craigslist, with BARTERBY's system, users are less encouraged to accumulate more materials than what they are giving away.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            2. Users can only exchange an item for another item, not for money
                        </Typography>
                        <p>
                            Thus, it is unlike <b style={{color: projectColors[0]}}>most other online marketplace sites where many users are monetarily driven</b>. This barter system also makes it more difficult for users to "flip" items and profit, and hopefully it is more inviting to crafters who strictly want to exchange with like-minded crafters.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            3. Exchange with a verification system and affordable shipping plans
                        </Typography>
                        <p>
                            Because people find that it is currently <b style={{color: projectColors[0]}}>difficult to arrange trades and deliver items through existing channels</b>, I decided to design features for users to more easily <b style={{color: projectColors[0]}}>coordinate, verify trades, and ship their items</b> to each other through BARTERBY.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4" color={projectColors[0]}>
                            4. Sort craft supplies with a customized categorization system
                        </Typography>
                        <p>
                            For people interested in findings craft supplies, it is currently <b style={{color: projectColors[0]}}>difficult to sort through general online marketplace sites</b> to find the exact items they need. I think that BARTERBY's <b style={{color: projectColors[0]}}>item listing and categorization system should better fit the mental model of crafters</b> specifically. However, I did not have the chance to develop this feature more in-depth during the project.
                        </p>
                    </Grid>
                </Grid>
            </ProjectBody>

            <ProjectHeading id="early-prototype" color={projectColors[1]}>
                Early Prototypes
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ImageModal image={Images.barterby.desktop} alt="Desktop screen of search results"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                            I initially designed BARTERBY as a deskop website. But then I realized that I can <b style={{ color: projectColors[1] }}>take advantage of the camera, GPS, and mobile internet of a smartphone to design the item swap verification feature</b> that differentiates BARTERBY from similar digital products.
                        </p>
                        <p>
                            Thus, I decided to focus on creating a mobile app. I took the item listing system and the visual aesthetics from the desktop screens, and began translating them into a mobile app.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <ImageModal image={Images.barterby.loFi} alt="Sketches of in-person barter mobile screens"/>
                    </Grid>
                </Grid>
                
            </ProjectBody>

            <ProjectHeading id="mobile-medfi" color={projectColors[1]}>
                Mobile Medium Fidelity Prototype
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item sm={12} xs={12}>
                        <p>
                            I created a medium-fidelity prototype for the <b style={{ color: projectColors[1] }}>task of bartering items in person</b>. In the sequence, the user (Sacha) has <b style={{ color: projectColors[1] }}>already made arrangements to barter in-person</b> with another user (Jun).
                        </p>
                        <p>
                            Sacha <b style={{ color: projectColors[1] }}>navigates from the home page</b> to a page containing the <b style={{ color: projectColors[1] }}>barter arrangement details</b>. Both users receive <b style={{ color: projectColors[1] }}>QR codes in the app to verify their barter</b>. When Sacha meets Jun in-person, she <b style={{ color: projectColors[1] }}>scans Jun's QR code</b> with her phone camera while receiving an item from him, and <b style={{ color: projectColors[1] }}>shows her QR code</b> for Jun to scan when giving him her item.
                        </p>
                        <p>
                            Because I did not have time to recruit representative users during this project, I <b style={{ color: projectColors[1] }}>tested the screens with 3 other UX/UI designers</b>.
                        </p>
                    </Grid>
                    <Grid item sm={12} xs={12} mt={2} mb={4}>
                        <Typography variant="h4" color={projectColors[1]}>
                            Task 1: Arrange to exchange barter items in-person
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={5} display="flex" justifyContent="center">
                    <Grid item sm={4} xs={10}>
                        <Typography variant="h5" color={projectColors[1]}>
                            1. Landing Page
                        </Typography>
                        <p>
                            Tap user photo
                        </p>
                        <ImageModal image={Images.barterby.medFi.one} borderRadius='0' padding='0' alt="Medium fidelity sequential storyboard of in-person barter mobile screens"/>
                    </Grid>
                    <Grid item sm={4} xs={10}>
                        <Typography variant="h5" color={projectColors[1]}>
                            2. Profile Menu
                        </Typography>
                        <p>
                            Tap "Your Transactions"
                        </p>
                        <ImageModal image={Images.barterby.medFi.two} borderRadius='0' padding='0' alt="Medium fidelity sequential storyboard of in-person barter mobile screens"/>
                    </Grid>
                    <Grid item sm={4} xs={10}>
                        <Typography variant="h5" color={projectColors[1]}>
                            3. Your Transactions
                        </Typography>
                        <p>
                            Tap "Wooden buttons"
                        </p>
                        <ImageModal image={Images.barterby.medFi.three} borderRadius='0' padding='0' alt="Medium fidelity sequential storyboard of in-person barter mobile screens"/>
                    </Grid>
                    <Grid item sm={4} xs={10}>
                        <Typography variant="h5" color={projectColors[1]}>
                            4. Receive Item
                        </Typography>
                        <p>
                            Tap "Swap Items"
                        </p>
                        <ImageModal image={Images.barterby.medFi.four} borderRadius='0' padding='0' alt="Medium fidelity sequential storyboard of in-person barter mobile screens"/>
                    </Grid>
                    <Grid item sm={4} xs={10}>
                        <Typography variant="h5" color={projectColors[1]}>
                            5. Scan QR Code
                        </Typography>
                        <p>
                            Tap "Hand Your Item"
                        </p>
                        <ImageModal image={Images.barterby.medFi.five} borderRadius='0' padding='0' alt="Medium fidelity sequential storyboard of in-person barter mobile screens"/>
                    </Grid>
                    <Grid item sm={4} xs={10}>
                        <Typography variant="h5" color={projectColors[1]}>
                            6. Show QR Code
                        </Typography>
                        <p>
                            Let barter partner scan QR
                        </p>
                        <ImageModal image={Images.barterby.medFi.six} borderRadius='0' padding='0' alt="Medium fidelity sequential storyboard of in-person barter mobile screens"/>
                    </Grid>
                    {/* <Grid item sm={12} xs={12}>
                        <ImageModal image={Images.barterby.four} alt="Medium fidelity sequential storyboard of in-person barter mobile screens"/>
                    </Grid> */}
                </Grid>

            </ProjectBody>

            <ProjectHeading color={projectColors[1]} id="mobile-medfi-feedback">
                Integration of Feedback
            </ProjectHeading>
            <ProjectBody border>

                <p>
                    I integrated the feedback for the medium-fidelity prototype into my high-fidelity prototype.
                </p>
                <br/><br/>

                <Typography variant="h4" color={projectColors[1]}>
                    1. Home Page
                </Typography>

                <Typography variant="h5" color={projectColors[1]} mt={3} mb={3}>
                    BEFORE
                </Typography>

                <Grid container spacing={{sm: 5, xs: 3}} mb={7} alignItems="flex-start">
                    <Grid item sm={4} xs={6}>
                        <ImageModal image={Images.barterby.integration.home.before} borderRadius='0' padding='0' alt="Home Page before feedback"/>
                    </Grid>
                        
                        
                    <Grid item sm={8} xs={6} mt={5}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Feedback
                        </Typography>
                        <ul>
                            <li>
                                Cannot quickly scroll to discover more recommended items, screen is dominated with large headings
                            </li>
                            <li>
                                The Search function is difficult to find because it is hidden inside hamburger menu
                            </li>
                        </ul>
                    </Grid>
                </Grid>

                <Box width="100%" textAlign="center">
                    <SvgIcon component={SouthIcon} sx={{ fontSize: '100px', color: projectColors[1]}}/>
                </Box>

                <Typography variant="h5" color={projectColors[1]} mt={2}>
                    AFTER
                </Typography>
                <Grid container spacing={{sm: 5, xs: 3}} mb={8} alignItems="center">
                    <Grid item sm={4} xs={6}>
                        <ImageModal image={Images.barterby.integration.home.afterOne} borderRadius='0' padding='0' alt="Home Page after feedback"/>
                        {(isXS || isTiny) &&
                        <>
                            <br/>
                            <ImageModal image={Images.barterby.integration.home.afterTwo} borderRadius='0' padding='0' alt="Home Page after feedback"/>
                            <p style={{ textAlign: "center", marginTop: "-4px"}}><b style={{color: projectColors[1]}}>Search results page</b></p>
                        </>
                        }
                    </Grid>

                    {!(isXS || isTiny) &&
                        <Grid item sm={4} xs={6} mt={4}>
                            <ImageModal image={Images.barterby.integration.home.afterTwo} borderRadius='0' padding='0' alt="Home Page after feedback"/>
                            <p style={{ textAlign: "center", marginTop: "-4px"}}><b style={{color: projectColors[1]}}>Search results page</b></p>
                        </Grid>
                    }
                                        
                        
                    <Grid item sm={4} xs={6} mt={4}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Edits
                        </Typography>
                        <ul>
                            <li>
                                Change item listing layout to stack vertically, to fit more new information in a single screen
                            </li>
                            <li>
                                The prompt “Load more” is clarified to “Scroll for more”
                            </li>
                            <li>
                                Split menu into header and footer menus, with the Search function on the header as a magnifying glass icon
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                
                <br/>
                <Typography variant="h4" color={projectColors[1]}>
                    2. Menus
                </Typography>

                <Typography variant="h5" color={projectColors[1]} mt={3} mb={3}>
                    BEFORE
                </Typography>
                
                <Grid container spacing={{sm: 5, xs: 3}} mb={5} alignItems="center">
                    <Grid item sm={4} xs={6}>
                        <ImageModal image={Images.barterby.integration.profileMenu.before} borderRadius='0' padding='0' alt="Menu before feedback"/>
                        {(isXS || isTiny) &&
                        <>
                            <br/>
                            <ImageModal image={Images.barterby.integration.barters.before} borderRadius='0' padding='0' alt="Current barters before feedback"/>
                        </>
                        }
                    </Grid>

                    {!(isXS || isTiny) &&
                        <Grid item sm={4} xs={6}>
                            <ImageModal image={Images.barterby.integration.barters.before} borderRadius='0' padding='0' alt="Current barters before feedback"/>
                        </Grid>
                    }
                        
                    <Grid item sm={4} xs={6} mt={5}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Feedback
                        </Typography>
                        <ul>
                            <li>
                                Too many options listed under user profile menu (upon clicking profile picture)
                            </li>
                            <li>
                                Menu item text is too bold, making page look too busy
                            </li>
                            <li>
                                Trade or negotiation status is unclear
                            </li>
                            <li>
                                Layout of barter transactions takes up too much space
                            </li>
                        </ul>
                    </Grid>
                </Grid>

                <Box width="100%" textAlign="center">
                    <SvgIcon component={SouthIcon} sx={{ fontSize: '100px', color: projectColors[1]}}/>
                </Box>

                <Typography variant="h5" color={projectColors[1]} mt={2}>
                    AFTER
                </Typography>

                <Grid container spacing={{sm: 5, xs: 3}} mb={8} alignItems="center">
                    <Grid item sm={4} xs={6} mt={5}>
                        <ImageModal image={Images.barterby.integration.profileMenu.after} borderRadius='0' padding='0' alt="User menu after feedback"/>
                    </Grid>
                        
                        
                    <Grid item sm={8} xs={6} mt={5}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Edits
                        </Typography>
                        <ul>
                            <li>
                                Reduce number of options in menu by splitting some of them into the footer menu
                            </li>
                            <li>
                                Use entire page instead of “sidebar” as menu, and make menu item text smaller
                            </li>
                        </ul>
                    </Grid>
                    <Grid item sm={4} xs={6}>
                        <ImageModal image={Images.barterby.integration.barters.afterOne} borderRadius='0' padding='0' alt="Current barters after feedback"/>
                        {(isXS || isTiny) &&
                        <>
                            <br/>
                            <ImageModal image={Images.barterby.integration.barters.afterTwo} borderRadius='0' padding='0' alt="Message inbox after feedback"/>
                        </>
                        }
                    </Grid>
                    
                    {!(isXS || isTiny) && 
                        <Grid item sm={4} xs={6}>
                            <ImageModal image={Images.barterby.integration.barters.afterTwo} borderRadius='0' padding='0' alt="Message inbox after feedback"/>
                        </Grid>
                    }
                    
                    <Grid item sm={4} xs={6}>
                        <ul>
                            <li>
                                Split list of trades/negotiations and list of messages into separate sections in app
                            </li>
                            <li>
                                Show both items in each barter thumbnail, and show user/potential trade partners in each message thumbnail
                            </li>
                        </ul>
                    </Grid>

                </Grid>


                <Typography variant="h4" color={projectColors[1]}>
                    3. Barter Details & In-Person Swap
                </Typography>

                <Typography variant="h5" color={projectColors[1]} mt={3} mb={3}>
                    BEFORE
                </Typography>

                <Grid container spacing={{sm: 5, xs: 3}} mb={5} alignItems="flex-start">
                    <Grid item sm={4} xs={6}>
                        <ImageModal image={Images.barterby.integration.details.before} borderRadius='0' padding='0'alt="Barter details before feedback"/>
                        {(isXS || isTiny) &&
                        <>
                            <br/>
                            <ImageModal image={Images.barterby.integration.QR.beforeOne} borderRadius='0' padding='0' alt="QR code page before feedback"/>
                            <br/>
                            <ImageModal image={Images.barterby.integration.QR.beforeTwo} borderRadius='0' padding='0' alt="QR scanner page before feedback"/>
                        </>
                        }
                    
                    </Grid>

                    {!(isXS || isTiny) && 
                        <Grid item sm={4} xs={6}>
                            <ImageModal image={Images.barterby.integration.QR.beforeOne} borderRadius='0' padding='0' alt="QR code page before feedback"/>
                            <ImageModal image={Images.barterby.integration.QR.beforeTwo} borderRadius='0' padding='0' alt="QR scanner page before feedback"/>
                        </Grid>
                    }
                        
                    <Grid item sm={4} xs={6} mt={5}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Feedback
                        </Typography>
                        <ul>
                            <li>
                                The progress steps are confusing because it seems like the user must send their item first, and unclear whether that step is completed
                            </li>
                            <li>
                                When the trade is supposed to take place is unknown
                            </li>
                            <li>
                                Does not know how far the location is in relation to user
                            </li>
                            <li>
                                It is not obvious that users can switch between showing QR code to deliver and scanning QR code to pickup item because the switch button is at the bottom of page
                            </li>
                        </ul>
                    </Grid>
                </Grid>

                <Box width="100%" textAlign="center">
                    <SvgIcon component={SouthIcon} sx={{ fontSize: '100px', color: projectColors[1]}}/>
                </Box>

                <Typography variant="h5" color={projectColors[1]} mt={5} mb={3}>
                    AFTER
                </Typography>

                <Grid container spacing={{sm: 5, xs: 3}} mb={10} alignItems="flex-start">
                    <Grid item sm={4} xs={6}>
                        <ImageModal image={Images.barterby.integration.details.after} borderRadius='0' padding='0' alt="Barter details after feedback"/>
                        {(isXS || isTiny) &&
                        <>
                            <br/>
                            <ImageModal image={Images.barterby.integration.QR.afterOne} borderRadius='0' padding='0' alt="QR code page after feedback"/>
                            <br/>
                            <ImageModal image={Images.barterby.integration.QR.afterTwo} borderRadius='0' padding='0' alt="QR scanner page after feedback"/>
                        </>
                        }
                    </Grid>

                    {!(isXS || isTiny) &&
                        <Grid item sm={4} xs={6}>
                            <ImageModal image={Images.barterby.integration.QR.afterOne} borderRadius='0' padding='0' alt="QR code page after feedback"/>
                            <ImageModal image={Images.barterby.integration.QR.afterTwo} borderRadius='0' padding='0' alt="QR scanner page after feedback"/>
                        </Grid>
                    }
                    
                        
                        
                    <Grid item sm={4} xs={6} mt={5}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Edits
                        </Typography>
                        <ul>
                            <li>
                                Separate checkout sequence and in-person trade steps, and put steps progress only in checkout
                            </li>
                            <li>
                                Add feature to schedule in-person barter, and show distance of meeting location from user using phone GPS
                            </li>
                            <li>
                                Let user call barter partner near meeting time to help them find each other (with user consent)
                            </li>
                            <li>
                                Put selectable tabs at the top of the page to quickly switch between Deliver (show QR code) and Pickup (scan QR code)
                            </li>
                        </ul>
                    </Grid>
                </Grid>


            </ProjectBody>

            <ProjectHeading id="mobile-hifi"color={projectColors[1]}>
                Mobile High Fidelity Prototype
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <p>
                            After editing the screens above based on feedback, I also added screens to address more of the pain points I discovered through my research.
                        </p>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant="h4" color={projectColors[1]}>
                            Coordinate In-Person Barter
                        </Typography>
                        <p>
                            This feature aims to solve the <b style={{ color: projectColors[1]}}>difficulties users face in exchanging items through Kijiji or Craigslist</b>, such as interested buyers being <b style={{ color: projectColors[1]}}>ambiguous about when they will pick up items or entirely failing to show up</b>. I think that agreeing to barter arragements in a checkout process, picking up a specific time slot to meet, then verifying the swap <b style={{ color: projectColors[1]}}>feel more "official" and could discourage users from breaking agreements</b>. The verification system could also function to <b style={{ color: projectColors[1]}}>flag users that fail to follow agreed arrangements</b>.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={5} mt={{ sm: 3, xs:0 }} display="flex" justifyContent={{sm:"flex-start", xs: "center"}}>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                1. Select to Deliver Item In-Person
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.inPerson.one} borderRadius='0' padding='0' alt="Select in-person barter"/>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                2. Select Delivery Time and Date
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.inPerson.two} borderRadius='0' padding='0' alt="Select time to meet"/>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                3. Review In-Person Barter Arrangements
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.inPerson.three} borderRadius='0' padding='0' alt="Select time to meet"/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={1}>
                    <Grid item xs={12}>
                        <p>
                            After two users have agreed to exchange items, they are taken through a <b style={{ color: projectColors[1]}}>checkout sequence</b> to arrange how they will do it. If they choose to <b style={{ color: projectColors[1]}}>barter in-person</b>, they can <b style={{ color: projectColors[1]}}>select a location and a time slot on a calendar</b> when they are available to deliver and/or receive items. These details will be shown on the <b style={{ color: projectColors[1]}}>Barter Details page</b>, and the app will <b style={{ color: projectColors[1]}}>notify the user</b> when they have an in-person barter coming up.
                        </p>
                        <p>
                            However, to present users with a <b style={{ color: projectColors[1]}}>manageable number of options</b> of item delivery and collection methods and times, I think that the user should be able to <b style={{ color: projectColors[1]}}>pre-select</b> some of these options. This can be done when they <b style={{ color: projectColors[1]}}>initially list their item</b> or <b style={{ color: projectColors[1]}}>when they approve</b> the other user to swap for their item.
                        </p>
                    </Grid>
                    {/* (insert image) */}
                    <Grid item xs={12}>
                        <p>
                            I have added a preview of this feature by showing in the listing page <b style={{ color: projectColors[1]}}>whether an item is available for shipping and/or pickup, and from what area</b>. But this is a feature that I could work more on in-depth if I were to spend more time on this project.
                        </p>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant="h4" color={projectColors[1]}>
                            Affordable Shipping Options
                        </Typography>
                        <p>
                            Besides letting users barter in-person, I think that it is also important to give them the option to ship items to each other at an affordable price. This will address the <b style={{ color: projectColors[1]}}>pain point in trading items for users who cannot easily transport items in-person</b>, such as due to item size, their remote location, or lack of transportation means. In addition, services like <b style={{ color: projectColors[1]}}>Canada Post or UPS can be quite expensive, but business shipping plans tend to have lower costs</b>. Such options are usually available on online shopping sites, but I think it would be a good idea for BARTERBY to offer them to bartering users.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={5} mt={{ sm: 3, xs:0 }} display="flex" justifyContent={{sm:"flex-start", xs: "center"}}>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                1. Request Barter Partner to Ship Item
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.shipping.two} borderRadius='0' padding='0' alt="Select to receive item by shipping"/>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                2. Select Shipping Method
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.shipping.three} borderRadius='0' padding='0' alt="Estimate shipping cost"/>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                3. Enter Shipping Payment Method
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.shipping.four} borderRadius='0' padding='0' alt="Select payment method"/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={2}>
                    <Grid item xs={12}>
                        <p>
                            The option to ship item is presented next to in-person barter during the checkout sequence. I think it makes more sense for the <b style={{ color: projectColors[1]}}>user to pay for the shipping of the item that they will be receiving</b> than the one they are sending out. The user can <b style={{ color: projectColors[1]}}>preview shipping cost and time based the item's weight and category</b>, and then <b style={{ color: projectColors[1]}}>enter a payment method</b>. The exact cost will only be calculated and charged when the package shipping label has been printed. But I think that this entire sequence still <b style={{ color: projectColors[1]}}>needs to be tested with representative users</b> to see if they find the method fair and convenient for their needs.
                        </p>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                        <Typography variant="h4" color={projectColors[1]}>
                            Listings & Categorizations for Craft Materials
                        </Typography>
                        <p>
                            To address the <b style={{ color: projectColors[1]}}>difficulty users currently face with sorting and finding an exact item they need</b> on sites like Craigslist and Kijiji, I want BARTERBY's item listing and categorization system to be tailored for craft materials specifically.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={5} mt={{ sm: 3, xs:0 }} display="flex" justifyContent={{sm:"flex-start", xs: "center"}}>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                Listing page breaks down item attributes
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.organize.two} borderRadius='0' padding='0' alt="Item listing"/>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[1]} mb={1}>
                                Can select craft category in search
                            </Typography>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.features.organize.one} borderRadius='0' padding='0' alt="Item categories"/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={2}>
                    <Grid item xs={12}>
                        <p>
                            So far, I have done this by including <b style={{ color: projectColors[1]}}>craft material details that I anticipate crafters to care about</b>, such as quantitity, size, color, and material. I have also translated a bit of the <b style={{ color: projectColors[1]}}>categorization system I designed on the desktop site</b> into the mobile app.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <ImageModal width="100%" image={Images.barterby.features.organize.three} borderRadius='0' alt="Desktop categorization system"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                            However, I did not have the chance to work on this feature in-depth, thus is another area that I could do more testing and design on if I had the chance. I especially want to <b style={{ color: projectColors[1]}}>include participants who do crafting in areas I am not familiar with</b>, such as woodwork, metalwork, or pottery.
                        </p>
                    </Grid>

                    <Grid item mt={2} xs={12}>
                        <Typography variant="h5" color={projectColors[1]}>
                            Task 1: Arrange to exchange barter items in-person
                        </Typography>
                        <p>
                            Below is the abridged high-fidelity sequential storyboard of the barter arrangement and in-person exchange.
                        </p>

                    </Grid>
                </Grid>
                <Grid container spacing={5} mt={{ sm: 3, xs:0 }} display="flex" justifyContent={{sm:"flex-start", xs: "center"}}>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h4" color={projectColors[1]}>
                                1. Message Inbox
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Open message for "Wooden buttons"
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.inPerson.one} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of in-person barter mobile screens"/>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h4" color={projectColors[1]}>
                                2. Message Thread
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Check message and proceed with Barter
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.inPerson.two} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of in-person barter mobile screens"/>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h4" color={projectColors[1]}>
                                3. Checkout
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '100px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Arrange in-person barter using checkout sequence
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.inPerson.three} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of in-person barter mobile screens"/>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h4" color={projectColors[1]}>
                                4. Barters List
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Select item to barter today to open details
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.inPerson.four} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of in-person barter mobile screens"/>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h4" color={projectColors[1]}>
                                5. Show QR Code
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Swap items in-person and scan each other's QR
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.inPerson.five} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of in-person barter mobile screens"/>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h4" color={projectColors[1]}>
                                6. Confirmation
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                After completing swap, users can review each other
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.inPerson.six} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of in-person barter mobile screens"/>
                        </Box>
                    </Grid>
                    
                </Grid>

                <Grid container spacing={3} mt={6}>
                    <Grid item mb={6} xs={12} display="flex" justifyContent="center">
                        <LinkButton
                            id="mobile-sequential"
                            href="https://www.figma.com/file/z4JpFgJSTWBLpPG1e8IvIJ/?node-id=608%3A4241"
                            bg={projectColors[1]}
                            text="Open Full Sequential Storyboard"
                        />
                    </Grid>

                    <Grid item sm={12} xs={12}>
                        <Box id="mobile-clickable" display="flex" justifyContent="center">
                            {isTiny && <iframe title="Mobile clickable prototype - tiny screen" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="300" height="550" src="https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/proto/z4JpFgJSTWBLpPG1e8IvIJ/BARTERBY-Mobile-%26-Kiosk-05%2F22?node-id=613%3A9415&scaling=scale-down&page-id=429%3A1747&starting-point-node-id=613%3A9415" allowFullScreen /> }
                            
                            {isNotTiny && <iframe title="Mobile clickable prototype" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="450" height="700" src="https://www.figma.com/embed?embed_host=share&url=https://www.figma.com/proto/z4JpFgJSTWBLpPG1e8IvIJ/BARTERBY-Mobile-%26-Kiosk-05%2F22?node-id=613%3A9415&scaling=scale-down&page-id=429%3A1747&starting-point-node-id=613%3A9415" allowFullScreen /> }
                        </Box>
                    </Grid>
                    
                    <Grid item mt={2} xs={12}>
                        <p>
                            I also created a prototype of the mobile app onboarding sequence for first-time users.
                        </p>
                    </Grid>
                    
                    <Grid item sm={12} xs={12} mb={6} display="flex" justifyContent="center">
                        <LinkButton
                            href="https://www.figma.com/proto/z4JpFgJSTWBLpPG1e8IvIJ/BARTERBY-Mobile-%26-Kiosk-05%2F22?node-id=613%3A9902&scaling=scale-down&page-id=429%3A1747&starting-point-node-id=613%3A9912"
                            bg={projectColors[1]}
                            text="Open Clickable Prototype for App Onboarding"
                        />
                    </Grid>

                </Grid>
            </ProjectBody>

            <ProjectHeading id="kiosk-ideation" color={projectColors[2]}>
                Kiosk Ideation
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item mt={3} xs={12}>
                        <p>
                            Since I was already focused on addressing the <b style={{ color: projectColors[2] }}>pain point of transporting barter items</b>, I decided to expand BARTERBY by designing a <b style={{ color: projectColors[2] }}>shipping drop-off kiosk</b>. Kiosks are already quite common with shipping services like USPS and Purolator, because people sometimes <b style={{ color: projectColors[2] }}>need to ship out items outside of business hours</b>. However they are not perfect and I discovered some user feedback online.
                        </p>

                        <ImageModal image={Images.barterby.painPoint.five} alt="Screenshot of affinity diagram, user feedback in existing shipping drop-off kiosks"/>
                        
                        <p>
                            For example, a user complained about the limited payment options on USPS kiosks. I tried to make BARTERBY's kiosk <b style={{ color: projectColors[2] }}>simpler by letting users go through the entire checkout sequence for shipping items and payment on the mobile app</b>, and can choose whether to drop-off the package at a post office or kiosk in the last minute. This way BARTERBY kiosk is a touchpoint available 24/7 just for printing shipping labels and dropping off packages.
                        </p>
                    </Grid>

                    <Grid item id="task2" xs={12} mt={3}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Task 2: Arrange to exchange barter items by shipping
                        </Typography>

                        <p>
                            Below is the mobile sequence that preceeds a user dropping off their barter items at a kiosk. It takes advantage of the <b style={{ color: projectColors[2] }}>smartphone's portability to connect with the package drop-off kiosk via QR code</b>.<br/><br/>
                        </p>
                    </Grid>
                        {/* <ImageModal width="100%" image={Images.barterby.sixS} alt="Abridged high fidelity sequential storyboard of shipping mobile screens"/> */}
                    
                </Grid>
                <Grid container spacing={5} display="flex" justifyContent={{sm:"flex-start", xs: "center"}}>
                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                1. Checkout
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Select to send item by shipping
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.ship.one} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of shipping mobile screens"/>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                2. Add Payment Method
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                If receiving item by shipping too, add payment method
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.ship.two} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of shipping mobile screens"/>
                        </Box>
                    </Grid>

                    <Grid item sm={4} xs={7}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                3. Print Shipping Label
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '110px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Scan QR at kiosk to print label and ship item
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.hiFi.ship.three} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of shipping mobile screens"/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={2}>
                    <Grid item mt={3} xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Physical Kiosk
                        </Typography>
                        <p>
                            As I was designing the task above, I also started designed the physical package drop-off kiosk.<br/><br/>
                        </p>

                        <ImageModal image={Images.barterby.kiosk.physical.sketch}  alt="Physical kiosk early sketch"/>
                    </Grid>
                    

                    <Grid mb={3} item sm={12} xs={12}>
                        <p>
                            I estimated the kiosk screen to be 25cm x 18cm (approx. the size of iPad Pro 11").<br/><br/>

                            <b style={{ color: projectColors[2]}}>I tested size, height, and angle of the kiosk screen</b> on a desk. The tablet is set up around 1m off the ground and tilted up at 45-50 degrees.<br/><br/>
                            
                            The test is to ensure that prototype font and buttons are large enough when viewed at actual size and eye distance.
                        </p>
                    </Grid>
                    
                </Grid>
                    
                <Grid container spacing={{ md:5, xs:3 }} display="flex" justifyContent="center" alignItems="center">
                    <Grid item md={4.5} sm={4.5} xs={12}>
                        <ImageModal image={Images.barterby.kiosk.physical.test.two} borderRadius='10' padding='1%' alt="Photo of physical kiosk height setup in real life" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }}/>
                    </Grid>
                    <Grid item md={7.5} sm={7.5} xs={12}>
                        <ImageModal image={Images.barterby.kiosk.physical.test.one} borderRadius='15' padding='0.5%' alt="Photo of physical kiosk screen size in real life" style={{ backgroundColor: `${COLORS.OFF_WHITE}` }}/>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} >
                        <p>
                            After testing, I <b style={{ color: projectColors[2]}}>finalized the physical kiosk sketch</b>. The sketch shows some rough proportions and measurements.<br/><br/>

                            Compared to the draft sketch, the QR scanner design has been changed. The credit card reader has been removed, because shipping payment is set up in the mobile checkout sequence.
                        </p>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <ImageModal image={Images.barterby.kiosk.physical.final} alt="Physical kiosk revised sketch"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                            I still want to keep working on this physical kiosk design if I had more time, such as by <b style={{ color: projectColors[2]}}>recruiting participants to test it</b>.
                        </p>
                        <p>
                            I also want to make the kiosk <b style={{ color: projectColors[2]}}>more secure and private</b>. I found comments online stating that many shipping drop-off kiosks end up <b style={{ color: projectColors[2]}}>not being available 24/7, because the room they're placed in being locked at night due to the possibility of theft and vandalism</b>. 
                            Thus, a possible solution to this might be to <b style={{ color: projectColors[2]}}>give users a digital key access to the kiosk room</b>, like how some ATM rooms can only be opened by swiping a debit card to unlock the door. However, I need to do <b style={{ color: projectColors[2]}}>more research and test</b> possible solutions.
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <p>                           
                            While designing the physical kiosk, I created low-fidelity sketches for the kiosk's touchscreen in connection to the hardware available at the kiosk.
                        </p>
                        <ImageModal image={Images.barterby.kiosk.loFi}  alt="Kiosk screen sketches"/>
                    </Grid>
                </Grid>
            </ProjectBody>

            <ProjectHeading id="kiosk-hifi" color={projectColors[2]}>
                Kiosk Screens High-Fidelity Prototype
            </ProjectHeading>

            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item mt={3} xs={12}>
                        <Typography variant="h4" color={projectColors[2]}>
                            Task 3: Send item to barter partner using kiosk
                        </Typography>
                        <p>
                            I translated the sketches into a <b style={{ color: projectColors[2] }}>high-fidelity prototype</b> for sending an item using the package drop-off kiosk.
                        </p>
                        <p>
                            Because the user may be unfamiliar with the tools at the physical kiosk, I <b style={{ color: projectColors[2] }}>created vector illustrations</b> to help users more quickly understand how to use it.
                        </p>
                    </Grid>
                </Grid>
                <Grid container spacing={5} mt={3}  display="flex" justifyContent={{sm:"flex-start", xs: "center"}}>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                1. Main Menu
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '80px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Select "Print Shipping Label"
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.kiosk.hiFi.one} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of kiosk screens"/>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                2. Scan QR Code
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '80px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Scan QR in mobile app to retrieve item details
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.kiosk.hiFi.two} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of kiosk screens"/>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                3. Package Size
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '80px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Enter package dimensions and weight
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.kiosk.hiFi.three} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of kiosk screens"/>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                4. Print Shipping Label
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '80px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Kiosk prints out a label to stick on package
                            </p>
                        </Box>
                        <Box>
                        <ImageModal width="100%" image={Images.barterby.kiosk.hiFi.four} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of kiosk screens"/>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                5. Drop-Off Package
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '80px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                Kiosk door opens for package drop-off
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.kiosk.hiFi.five} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of kiosk screens"/>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box sx={{ height: { sm: '50px', xs: 'fit-content' } }} display="flex" alignItems="flex-end">
                            <Typography variant="h5" color={projectColors[2]}>
                                6. Confirmation
                            </Typography>
                        </Box>
                        <Box sx={{ height: { sm: '80px', xs: 'fit-content' } }} display="flex" alignItems="flex-start">
                            <p>
                                After completing, user can rate kiosk service
                            </p>
                        </Box>
                        <Box>
                            <ImageModal width="100%" image={Images.barterby.kiosk.hiFi.six} borderRadius='0' padding='0' alt="Abridged high fidelity sequential storyboard of kiosk screens"/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={6}>
                    <Grid item mb={6} xs={12} display="flex" justifyContent="center">
                        <LinkButton
                            id="kiosk-sequential"
                            href="https://www.figma.com/file/z4JpFgJSTWBLpPG1e8IvIJ/?node-id=29%3A2"
                            bg={projectColors[2]}
                            text="Open Full Sequential Storyboard"
                        />
                    </Grid>
                
                    <Grid item xs={12} mb={6}>
                        <Box id="kiosk-clickable" display="flex" justifyContent="center">
                            { isTiny && <iframe title="Kiosk clickable prototype - tiny screen" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="300" height="250" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fz4JpFgJSTWBLpPG1e8IvIJ%2FBARTERBY-Mobile-%2526-Kiosk-05%252F22%3Fnode-id%3D402%253A169%26scaling%3Dscale-down%26page-id%3D29%253A2%26starting-point-node-id%3D402%253A169" allowfullscreen />}

                            { isXS && <iframe title="Kiosk clickable prototype - extra small screen" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="400" height="300" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fz4JpFgJSTWBLpPG1e8IvIJ%2FBARTERBY-Mobile-%2526-Kiosk-05%252F22%3Fnode-id%3D402%253A169%26scaling%3Dscale-down%26page-id%3D29%253A2%26starting-point-node-id%3D402%253A169" allowfullscreen />}
                            
                            { isSM && <iframe title="Kiosk clickable prototype - small screen" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="550" height="415" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fz4JpFgJSTWBLpPG1e8IvIJ%2FBARTERBY-Mobile-%2526-Kiosk-05%252F22%3Fnode-id%3D402%253A169%26scaling%3Dscale-down%26page-id%3D29%253A2%26starting-point-node-id%3D402%253A169" allowfullscreen />}
                            
                            { isMD && <iframe title="Kiosk clickable prototype - medium screen" style={{ border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: '10px' }} width="800" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fz4JpFgJSTWBLpPG1e8IvIJ%2FBARTERBY-Mobile-%2526-Kiosk-05%252F22%3Fnode-id%3D402%253A169%26scaling%3Dscale-down%26page-id%3D29%253A2%26starting-point-node-id%3D402%253A169" allowfullscreen />}
                        </Box>
                    </Grid>
                </Grid>
            </ProjectBody>

            <ProjectHeading id="design-system" color={projectColors[3]}>
                Design System
            </ProjectHeading>
            <ProjectBody border>
                <Grid container spacing={3}>
                    <Grid item mt={3} xs={12}>
                        <p>
                            As I was creating the high fidelity <a href="#mobile-hifi" style={{color: projectColors[3]}}>mobile</a> and <a href="#kiosk-hifi" style={{color: projectColors[3]}}>kiosk</a> prototypes, I also built a design system consisting of a <b>color palette, layouts, typography styles, and interactive components</b>.
                        </p>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <ImageModal image={Images.barterby.designSystem} alt="Abridged Design System"/>
                    </Grid>
                    <Grid item xs={12}>
                        <p>
                            This is the abridged version of my design system. To see the <b>full version</b>, visit the link below.
                        </p>
                    </Grid>
                    <Grid item xs={12} mb={2} display="flex" justifyContent="center">
                        <LinkButton
                            id="design-system-full"
                            href="https://www.figma.com/file/z4JpFgJSTWBLpPG1e8IvIJ/BARTERBY-Mobile-%26-Kiosk-05%2F22?node-id=104%3A94"
                            text="Open Full Design System in Figma"
                        />
                    </Grid>
                </Grid>
            </ProjectBody>

            <ProjectHeading>
                Lessons Learned
            </ProjectHeading>
            <ProjectBody>
                <ul>
                    <li>
                        My initial approach to the mobile app design was similar to currently common website designs for mobile screens.
                        However, through the medium-fidelity prototype review session, I learned that 
                        for a mobile application with relatively small screen size, using <b>multiple 
                        clearly categorized, highly visible menus</b> makes it easier for users to quickly find the function/page they need.
                    </li>
                    
                    <li>
                        Even when there is a lack of time & resources for a formal usability testing, it is still useful to 
                        &nbsp;<a target="_blank" rel="noreferrer" href="https://www.nngroup.com/articles/why-you-only-need-to-test-with-5-users/">show early screen designs to a few people</a>
                        &nbsp;to gather feedback and identify possible major problems in the system. 
                        However, if I found more time to work on this project again, I want to <b>recruit actual craft enthusiasts</b>, because they might have different opinions than the UX/UI designers who gave me feedback on my prototype.
                    </li>
                    
                    <li>
                        To make screens look less cluttered, it is good to break down steps in a task into multiple screens. 
                        However users easily feel lost while going through a long sequences of screens. Therefore it is useful 
                        to strategically <b>label each current screen/step</b>, as well as <b>inform users what the previous & next screens in the sequence are</b>.
                    </li>

                    <li>
                        Teaching users about how to use a new system requires a lot of instructions. 
                        Instructions can be more quickly and easily understood when <b>images that help illustrate</b> the tasks are included. 
                    </li>
                </ul>
            </ProjectBody>
            <br/><br/><br/><br/>
            <ProjectHeading style={{ display: 'flex', justifyContent: 'flex-end'}} id="bottom">
                <Button
                    href="/work"
                    display="flex" alignItems="center"
                    sx={{ padding:'15px 30px', marginLeft: '0px', borderRadius: '10px', border: `2px solid ${COLORS.LAVENDER}`,
                    boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                    '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)' } }}>
                        <Typography variant="h4" color="secondary">
                            See more of my work
                        </Typography>
                </Button>
            </ProjectHeading>
            

            {/* ROADMAP STICKY MENU */}

            {isWide?
                <Fade appear={false} in={showRoadmap} easing={{ enter: "linear", exit: "linear" }}>
                    <MUIAppBar position="fixed" pt={2} m={0} sx={{top: 'auto', bottom: 0, zIndex: 899, minHeight:'85px',
                        display:'flex', alignItems:'center', justifyContent: 'center', boxShadow: '0px -3px 5px 0px rgb(112, 112, 112, 0.3)'}}
                    >

                        <Grid container spacing={-5} display="flex" justifyContent="center" alignItems="center" mx={{md:3, sm:2}}>
                            <Grid item xs={1.4}>
                                <SkinnyStartArrowButton href="#research"  ml={0} mr={{md: -6, sm:-6}} label="Secondary Research" bg={projectColors[0]} />
                            </Grid>
                            <Grid item xs={1.4}>
                                <SkinnyArrowButton href="#ideation" mr={{md: -6, sm:-6}} label="Ideation" bg={projectColors[0]}/>
                            </Grid>
                            <Grid item xs={1.4}>
                                <SkinnyArrowButton href="#early-prototype" mr={{md: -6, sm:-6}} label="Early Prototypes" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item xs={1.4}>
                                <SkinnyArrowButton mr={{md: -6, sm:-6}} label="Mobile Med-Fi" bg={projectColors[1]}
                                    onClick={positionMenuMedFi}
                                />
                                <Menu
                                        id="users-menu"
                                        anchorEl={anchorElMedFi}
                                        open={showRoadmapMedFi}
                                        transformOrigin={{
                                            vertical: 140,
                                            horizontal: -10
                                        }}
                                        onClose={()=>setShowRoadmapMedFi(null)}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: "row"}}>
                                            <SkinnyArrowButton href="#mobile-medfi" label="Section Top" bg={projectColors[1]} style={{ width: '150px' }}/>
                                            <SkinnyArrowButton href="#mobile-medfi-feedback" label="Integrate Feedback" bg={projectColors[1]} style={{ width: '150px', marginRight: 0 }}/>
                                        </Box>
                                    </Menu>
                            </Grid>
                            <Grid item xs={1.4}>
                                <SkinnyArrowButton href="#mobile-hifi" mr={{md: -6, sm:-6}} label="Mobile Hi-Fi" bg={projectColors[1]}/>
                            </Grid>
                            <Grid item xs={1.4}>
                                <SkinnyArrowButton href="#kiosk-ideation" mr={{md: -6, sm:-6}} label="Kiosk Ideation" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item xs={1.4}>
                                <SkinnyArrowButton href="#kiosk-hifi" mr={{md: -6, sm:-6}} label="Kiosk Hi-Fi" bg={projectColors[2]}/>
                            </Grid>
                            <Grid item xs={1.4}>
                                <SkinnyArrowButton href="#design-system" mr={{md: -6, sm:-6}} label="Design System" bg={projectColors[3]}/>
                            </Grid>
                            <Grid item xs={0.8} display='flex' alignItems='center' justifyContent='flex-start' sx={{pl:4, height: '100%' }} >
                                <StyledOutlineButton fontSize="16px" padding="5px"
                                onClick={()=>setShowRoadmap(null)}
                                >
                                    <SvgIcon component={CloseRoundedIcon} sx={{ fontSize:"22px", color: COLORS.LAVENDER}}/>
                                </StyledOutlineButton>
                            </Grid>
                        </Grid>
                    </MUIAppBar>
                </Fade>                    
                :
                <Drawer
                    anchor="left"
                    open={showRoadmap}
                    width="100%"
                    variant="temporary"
                >                 
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                        sx={{ height: '100%' }}
                    >
                        <Box display="flex" direction="row" justifyContent="center" mb={3} sx={{ width: '100%'}}>
                            <StyledOutlineButton fontSize="16px" padding="5px"
                                onClick={()=>setShowRoadmap(null)}
                            >
                                <SvgIcon component={CloseRoundedIcon} sx={{ fontSize:"22px", color: COLORS.LAVENDER}}/>
                            </StyledOutlineButton>
                        </Box>

                        <DownStartArrowButton href="#research" variant="overline" label="Secondary Research" bg={projectColors[0]} style={{height: '12.5%'}}/>

                        <DownArrowButton href="#ideation" variant="overline" label="Ideation" bg={projectColors[0]} style={{height: '12.5%'}}/>

                        <DownArrowButton href="#early-prototype" variant="overline" label="Early Prototypes" bg={projectColors[1]} style={{height: '12.5%'}}/>

                        <DownArrowButton href="#mobile-medfi" variant="overline" label="Mobile Med-Fi Prototype" bg={projectColors[1]} style={{height: '12.5%'}}/>

                        <DownArrowButton href="#mobile-hifi" variant="overline" label="Mobile Hi-Fi Prototype" bg={projectColors[1]} style={{height: '12.5%'}}/>

                        <DownArrowButton href="#kiosk-ideation" variant="overline" label="Kiosk Ideation" bg={projectColors[2]} style={{height: '12.5%'}}/>

                        <DownArrowButton href="#kiosk-hifi" variant="overline" label="Kiosk Hi-Fi Prototype" bg={projectColors[2]} style={{height: '12.5%'}}/>

                        <DownArrowButton href="#design-system" variant="overline" label="Design System" bg={projectColors[3]} style={{height: '12.5%'}}/>
                    </Box>
                </Drawer>    
            }
        </Layout>
    );
};

export default Barterby;