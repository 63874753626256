import styled from "styled-components";
import { styled as styledMUI } from "@mui/system";
import { COLORS } from "core";
import {
    Box,
    Button,
    Typography,
    IconButton,
} from '@mui/material';


export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    color: ${COLORS.BLACK};
    background: ${({ bg }) => (bg ?? COLORS.LAVENDER)};
    padding: ${({ padding }) => (padding ?? "15px 30px")};
    border-radius: 10px;
    border: none;
    box-shadow: 0px 0px 5px ${COLORS.OFF_WHITE};
    font-family: 'Quicksand', sans-serif;
    font-size: ${({ fontSize }) => (fontSize ?? "22px")};
    font-weight: 700;
    line-height: ${({ lineHeight }) => (lineHeight ?? "32px")};
    transition: transform 0.15s ease-in-out;
    &:hover {
        transform: scale3d(1.1, 1.1, 1);
        cursor: pointer;
    };
    &:active {
        transform: scale3d(1.1, 1.1, 1);
    };
`

export const StyledOutlineButton = styled.button`
    display: flex;
    align-items: center;
    color: ${({ textColor }) => (textColor ?? COLORS.LAVENDER)};
    background: ${ COLORS.BLACK };
    padding: ${({ padding }) => (padding ?? "15px 30px")};
    border-radius: 10px;
    border: 2px solid ${({ textColor }) => (textColor ?? COLORS.LAVENDER)};
    box-shadow: 0px 0px 5px ${COLORS.OFF_WHITE};
    font-family: 'Quicksand', sans-serif;
    font-size: ${({ fontSize }) => (fontSize ?? "22px")};
    font-weight: 700;
    line-height: ${({ lineHeight }) => (lineHeight ?? "32px")};
    transition: transform 0.15s ease-in-out;
    &:hover {
        transform: scale3d(1.1, 1.1, 1);
        cursor: pointer;
    };
    &:active {
        transform: scale3d(1.1, 1.1, 1);
    };
`

export const SkinnyButton = props => (
    <Box component={Button} {...props} sx={{ backgroundColor: props.bg,
        objectFit: props.objectFit??'fill', height: props.height??'80%', width: props.width??'100%',
        mx:{md: -1, sm: -1 }, mt: 0.8,
        transition: 'transform 0.15s ease-in-out',
        '&:hover': { backgroundColor: props.bg, transform: 'scale3d(1.1, 1.1, 1)', cursor: 'pointer'},
        '&:active': { backgroundColor: props.bg, transform: 'scale3d(1.1, 1.1, 1)'}
    }}>
        <Typography variant={props.variant??"overline"} color={COLORS.BLACK} sx={{textAlign: 'center', zIndex: 100 }}>
            {props.label}
        </Typography>
    </Box>
)

export const SkinnyStartArrowButton = props => (
    <Box component={Button} {...props} sx={{
        ml:{md: 0, sm: 0 }, mr:{md: -4, sm: -4},
        transition: 'transform 0.15s ease-in-out', transform: 'translate(-5px, 0)',
        '&:hover': { transform: 'scale3d(1.1, 1.1, 1)', cursor: 'pointer'},
        '&:active': { transform: 'scale3d(1.1, 1.1, 1)'}
    }}>
        <Typography variant={props.variant??"overline"} color={COLORS.BLACK} sx={{position: 'absolute', textAlign: 'center', zIndex: 100, pr: props.pr??4 , pl: props.pl??2}}>
            {props.label}
        </Typography>

        <svg id="arrow-button-icon" viewBox="0 0 275 80" xmlns="http://www.w3.org/2000/svg"
            style={{ height: '100%', width:'100%', objectFit: 'scale-down'}}>
            <path d="M0 5C0 2.23858 2.23858 0 5 0H223.246C224.381 0 225.483 0.386404 226.37 1.09566L270.12 36.0957C272.622 38.0973 272.622 41.9027 270.12 43.9043L226.37 78.9043C225.483 79.6136 224.381 80 223.246 80H5C2.23858 80 0 77.7614 0 75L0 40L0 5Z"
                fill={props.bg} />
        </svg>
    </Box>
)

export const SkinnyArrowButton = props => (
    <Box component={Button} {...props} sx={{
        ml:{md: 0, sm: 0 }, mr:{md: -4, sm: -4 },
        transition: 'transform 0.15s ease-in-out', '&:hover': { transform: 'scale3d(1.1, 1.1, 1)', cursor: 'pointer'},
        '&:active': { transform: 'scale3d(1.1, 1.1, 1)'}
    }}>
        <Typography variant={props.variant??"overline"} color={COLORS.BLACK} sx={{position: 'absolute', textAlign: 'center', zIndex: 100, pr: props.pr??4 , pl: props.pl??4}}>
            {props.label}
        </Typography>

        <svg id="arrow-button-icon" viewBox="0 0 275 80" xmlns="http://www.w3.org/2000/svg"
            style={{ height: '100%', width:'100%', objectFit: 'scale-down'}}>
            <path d="M2.13043 8.90435C-1.56026 5.95179 0.527516 0 5.2539 0H214.246C215.381 0 216.483 0.386404 217.37 1.09566L261.12 36.0957C263.622 38.0973 263.622 41.9027 261.12 43.9043L217.37 78.9043C216.483 79.6136 215.381 80 214.246 80H5.2539C0.527514 80 -1.56026 74.0482 2.13043 71.0957L36.1196 43.9043C38.6216 41.9027 38.6216 38.0973 36.1196 36.0957L2.13043 8.90435Z"
                fill={props.bg} />
        </svg>
        
    </Box>
)

export const StartArrowButton = props => (
    <Box component={Button} {...props} sx={{
        ml: props.ml??{md: 0, sm: 0 }, mr: props.mr??{md: -4, sm: -4},
        transition: 'transform 0.15s ease-in-out', transform: 'translate(-5px, 0)',
        '&:hover': { transform: 'scale3d(1.1, 1.1, 1)', cursor: 'pointer'},
        '&:active': { transform: 'scale3d(1.1, 1.1, 1)'}
    }}>
        <Typography variant={props.variant??"caption"} color={COLORS.BLACK} sx={{position: 'absolute', textAlign: 'center', zIndex: 100, pr: props.pr??4 , pl: props.pl??2}}>
            {props.label}
        </Typography>

        <svg id="arrow-button-icon" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg"
            style={{ height: '100%', width:'100%', objectFit: 'scale-down'}}>
            <path d="M0 10C0 4.47715 4.47715 0 10 0H218.82C222.607 0 226.07 2.14002 227.764 5.52786L297.764 145.528C299.172 148.343 299.172 151.657 297.764 154.472L227.764 294.472C226.07 297.86 222.607 300 218.82 300H10C4.47715 300 0 295.523 0 290L0 150L0 10Z"
                fill={props.bg} />
        </svg>
    </Box>
)

export const ArrowButton = props => (
    <Box component={Button} {...props} sx={{
        ml: props.ml??{md: 0, sm: 0 }, mr: props.mr??{md: -4, sm: -4 },
        transition: 'transform 0.15s ease-in-out', '&:hover': { transform: 'scale3d(1.1, 1.1, 1)', cursor: 'pointer'},
        '&:active': { transform: 'scale3d(1.1, 1.1, 1)'}
    }}>
        <Typography variant={props.variant??"caption"} color={COLORS.BLACK} sx={{position: 'absolute', textAlign: 'center', zIndex: 100, pr: props.pr??4 , pl: props.pl??5}}>
            {props.label}
        </Typography>

        <svg id="arrow-button-icon" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg"
            style={{ height: props.height??'100%', width: props.width??'100%', objectFit: props.fit??'scale-down'}}>
            <path d="M1.23607 14.4721C-2.08843 7.82313 2.74652 0 10.1803 0H212.82C216.607 0 220.07 2.14002 221.764 5.52786L291.764 145.528C293.172 148.343 293.172 151.657 291.764 154.472L221.764 294.472C220.07 297.86 216.607 300 212.82 300H10.1803C2.74653 300 -2.08844 292.177 1.23607 285.528L66.7639 154.472C68.1716 151.657 68.1716 148.343 66.7639 145.528L1.23607 14.4721Z"
                fill={props.bg} />
        </svg>
        
    </Box>
)

export const DownStartArrowButton = props => (
    <Box component={Button} {...props} sx={{
        mt:0, mb:{xs: -3},
        transition: 'transform 0.15s ease-in-out', '&:hover': { transform: 'scale3d(1.1, 1.1, 1)', cursor: 'pointer'},
        '&:active': { transform: 'scale3d(1.1, 1.1, 1)'}
    }}>
        <Typography variant={props.variant??"caption"} color={COLORS.BLACK} sx={{position: 'absolute', textAlign: 'center', zIndex: 100, px:2, pb: props.pb??4 , pt: props.pt??2}}>
            {props.label}
        </Typography>

        <svg id="arrow-button-icon" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg"
            style={{ height: '100%', width:'100%', objectFit: 'scale-down'}}>
            <path d="M290 0C295.523 0 300 4.47715 300 10V218.82C300 222.607 297.86 226.07 294.472 227.764L154.472 297.764C151.657 299.172 148.343 299.172 145.528 297.764L5.52787 227.764C2.14003 226.07 0 222.607 0 218.82V10C0 4.47715 4.47715 0 10 0L150 0L290 0Z"
                fill={props.bg} />
        </svg>
        
    </Box>
)

export const DownArrowButton = props => (
    <Box component={Button} {...props} sx={{
        mt:0, mb:{xs: -3},
        transition: 'transform 0.15s ease-in-out', '&:hover': { transform: 'scale3d(1.1, 1.1, 1)', cursor: 'pointer'},
        '&:active': { transform: 'scale3d(1.1, 1.1, 1)'}
    }}>
        <Typography variant={props.variant??"caption"} color={COLORS.BLACK} sx={{position: 'absolute', textAlign: 'center', zIndex: 100, px:2, pb: props.pb??4 , pt: props.pt??5}}>
            {props.label}
        </Typography>

        <svg id="arrow-button-icon" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg"
            style={{ height: '100%', width:'100%', objectFit: 'scale-down'}}>
            <path d="M285.528 1.23607C292.177 -2.08843 300 2.74652 300 10.1803V212.82C300 216.607 297.86 220.07 294.472 221.764L154.472 291.764C151.657 293.172 148.343 293.172 145.528 291.764L5.52787 221.764C2.14003 220.07 0 216.607 0 212.82V10.1803C0 2.74653 7.82313 -2.08844 14.4721 1.23607L145.528 66.7639C148.343 68.1716 151.657 68.1716 154.472 66.7639L285.528 1.23607Z"
                fill={props.bg} />
        </svg>
        
    </Box>
)

export const LinkButton = (props) => (
    <a
        style= {{textDecoration: 'none'}}
        target={props.target ?? "_blank"}
        rel={props.rel ?? "noreferrer"}
        {...props}
    >
        <StyledButton {...props}>
            {props.text}
        </StyledButton>
    </a>
)

export const ThumbnailButton = styledMUI(IconButton)({
    textAlign: "left",
    transition: "transform 0.15s ease-in-out",
    "&:hover":{
      transform: "scale3d(1.1, 1.1, 1)",
    },
    "&:active":{
      transform: "scale3d(1.1, 1.1, 1)",
    }
})