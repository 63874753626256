import React from "react";
import {
    Container,
    Grid,
    Box,
    Typography,
    SvgIcon
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { COLORS } from "core";

const Footer = props => {
    return(
        <Box width="100%" mt={20} pt={8} pb={6} sx={{backgroundColor: COLORS.DARK_GRAY }}>
            <Container>
                <Typography variant="body2" color="primary">
                    <Grid container>
                        <Grid item xs={12} mb={4} justifyContent="center" textAlign="center">
                            <Typography variant="body" color="primary">
                                Let's connect!
                            </Typography>
                        </Grid>
                    </Grid>
                </Typography>

                <Typography variant="h6" color="primary"
                    sx={{a:{color: COLORS.OFF_WHITE, textDecoration: 'none',
                    '&:hover':{color: COLORS.LAVENDER}} }}>

                    <Grid container spacing={2} direction={{xs: 'column', sm: 'row'}}>

                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', flexWrap: 'wrap',
                            transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.2, 1.2, 1)"} }}>
                            <SvgIcon component={EmailIcon} sx={{ marginRight: '15px', fontSize: '32px', color: COLORS.LAVENDER }} />
                            <a href="mailto:ksondjaja@gmail.com">
                                Email
                            </a>
                        </Grid>
                        
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', flexWrap: 'wrap',
                            transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.2, 1.2, 1)"}}}>
                            <SvgIcon component={LinkedInIcon} sx={{ marginRight: '15px', fontSize: '32px', color: COLORS.LAVENDER }} />
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/ksondjaja/">
                                LinkedIn
                            </a>
                        </Grid>

                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent:'center', flexWrap: 'wrap',
                            transition: "transform 0.15s ease-in-out", '&:hover': {transform: "scale3d(1.2, 1.2, 1)"}}}>
                            <SvgIcon component={GitHubIcon} sx={{ marginRight: '15px', fontSize: '32px', color: COLORS.LAVENDER }} />
                            <a target="_blank" rel="noreferrer" href="https://github.com/ksondjaja/">
                                GitHub
                            </a>
                        </Grid>

                        <Grid item xs={12} mt={6} display="flex" justifyContent="center">
                            <Typography variant="body1" color="primary">
                                <b>© 2023 Lala Sondjaja.</b> I built this website with React and MUI.
                            </Typography>
                        </Grid>

                    </Grid>

                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;