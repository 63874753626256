import React from "react";
import { Container } from '@mui/material';
import Footer from "Footer";

export const Layout = ({children}) => (
    <>
        <Container maxWidth="md" sx={{ marginTop: '120px', paddingBottom: '50px' }}>
            {children}
        </Container>
        
        <Footer />
    </>
)