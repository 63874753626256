import React from "react";
import {
    Box,
    Button,
    Typography,
} from '@mui/material';
import { Layout, COLORS } from "core";
import { Images, Thumbnail} from "assets";

// const skills = [
//     {   title: 'Design',
//         list: [
//             'Prototyping',
//             'Surveys, interviews',
//             'Sketching',
//             'Writing',
//             'Figma',
//             'Balsamiq',
//             'Mural, Miro'
//         ]
//     },
//     {
//         title: 'Frontend Dev',
//         list: [
//             'React, JavaScript',
//             'HTML, CSS',
//             'Flask, Django',
//             'WordPress, Elementor'
//         ],
//     },
//     {
//         title: 'Data',
//         list:[
//             'Qualitative & quantitative analysis',
//             'Data visualization',
//             'Database modelling',
//             'Python',
//             'SQL, JSON',
//             'pandas, matplotlib'
//         ]
//     }
// ]

const About = props => {

    return(
        <Layout>
            <Box display="flex" sx={{ width: '100%' }}>
                <Box sx={{ width:{ sm:'75%', xs:'90%' } }}>
                    <Thumbnail src={Images.profile} alt="Profile photo"/>
                </Box>
            </Box>
            <Typography variant="h2" color="secondary" >
                About Me
            </Typography>
            <Typography variant="body2" color="primary" >
                <p style={{ marginBottom: '30px' }}>
                    I currently work part-time as a <b>UI/UX Designer</b> at&nbsp;
                    <a href="https://www.centivizer.com/" target="_blank" rel="noreferrer">Centivizer</a>, 
                    a University of Toronto-affiliated company that develops <b>health applications</b>. 
                    As a passionate advocate for design, I <b>proactively initiated efforts</b> to make my cross-functional team's products and approaches <b>more user-centric</b> and <b>accessible</b>. 
                </p>

                <p style={{ marginBottom: '30px' }}>
                    Because <b>I like building functional things</b>, I have also learned <b>web programming</b>. 
                    I <b>coded this website</b> from scratch using <b>React</b> and <b>MUI</b>. I create <b>effective, implementable</b> designs and <b>collaborate with technical roles</b> using my web development knowledge.
                </p>
            </Typography>

            <br/><br/>
            <Typography variant="h4" color="secondary" >
                More Backstory
            </Typography>
            <Typography variant="body1" color="primary" >
                <p style={{ marginBottom: '30px' }}>
                    I studied <b>UX Design in a master's program</b> at&nbsp;
                    <a href="https://ischool.utoronto.ca/" target="_blank" rel="noreferrer">University of Toronto's iSchool</a>. 
                    While at UofT, I also wanted to gain more experience in research. Thus, I ran the&nbsp;
                    <a href="https://sites.google.com/view/miscsurveycenter/reports" target="_blank" rel="noreferrer">Survey Committee at Master of Information Student Council</a> for two years. 
                    The <a href="https://ksondjaja.github.io/surveycommitteereports/MentalHealthSurveyReport2021.html" target="_blank" rel="noreferrer">2021 Mental Health Survey</a> I conducted 
                    with graduate students helped us negotiate for a higher mental health insurance coverage.
                </p>

                <p style={{ marginBottom: '30px' }}>
                    I used to work as an <b>apparel technical designer</b> at <b>Tommy Hilfiger</b>, 
                    where I ensured that clothing products <b>fitted users comfortably</b> and were <b>well-constructed</b>. 
                    Through this, I learned the importance of <b>balancing</b> usability with design vision, budget, and technical execution.
                </p>
    
                <p style={{ marginBottom: '30px' }}>
                    In my free time, I like playing bass guitar, sewing, watching sci-fi shows, and walking around the city.
                </p>
            </Typography>
            <br/><br/>
            {/* <Typography mt={4} variant="h3" color="secondary">Skills</Typography>
            <Grid container mt={3} spacing={4} direction="row" alignItems="stretch" justifyContent="center">
                {skills.map(skill=>{
                    return(<Grid item md={4} sm={7} xs={11} >
                        <SkillCard>
                            <CardContent display="flex">
                                <Typography variant="h4" color="secondary">
                                    {skill.title}
                                </Typography>
                                <Typography variant="body1" color="primary">
                                    <ul>
                                        {(skill.list).map(item=>{
                                            return(
                                                <li style={{ marginBottom: '7px'}}>{item}</li>
                                            );
                                        })}
                                    </ul>
                                    {skill.note?? <p>{skill.note}</p>}
                                </Typography>
                            </CardContent>
                        </SkillCard>
                    </Grid>
                    );
                })}
            </Grid> */}
            <Box mt={15} style={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    href="/work"
                    display="flex" alignItems="center"
                    sx={{ padding:'15px 30px', marginLeft: '0px', borderRadius: '10px', border: `2px solid ${COLORS.LAVENDER}`,
                    boxShadow:`0px 0px 10px ${COLORS.OFF_WHITE}`, transition: 'transform 0.15s ease-in-out',
                    '&:hover':{ cursor:'pointer', transform: 'scale3d(1.1, 1.1, 1)' } }}>
                        <Typography variant="h4" color="secondary">
                            See my work
                        </Typography>
                </Button>
            </Box>
        </Layout>
      );
};

export default About;
