import React,{ useState } from "react";
import bcrypt from 'bcryptjs';
import {
    Grid,
    Paper,
    Box,
    Typography,
    IconButton,
    SvgIcon
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { styled as styledMUI } from "@mui/system";
import { COLORS,
         ThumbnailButton
} from "core";
import { Images, Thumbnail } from "assets";

export const projectItems = [
    {
        category:'ux',
        access: 'private',
        image: Images.shopifyStore.thumbnail,
        alttext: 'Healthy Age Mart home page',
        projectColor: COLORS.MINT,
        title: 'Healthy Age Mart',
        role: 'UX/UI Design',
        year: '2022',
        blurb: "Designed a Shopify store to sell healthy aging products, but primarily Centivizer's original digital game, directly to end users",
        skills: ['Prototyping', 'Design System', 'Figma', 'Usability Testing', 'Interview', 'Card Sorting'],
        projectUrl: '/work/shopifystore',
        extLink: 'https://healthyagemart.com/',
        extLinkName: 'Implemented Website',
        extLinkCaption: '(under construction)'
    },
    {
        category:'ux',
        access: 'private',
        image: Images.playerDashboard.thumbnail,
        alttext: 'BrainTagger Dashboard key screens',
        projectColor: COLORS.GOLD,
        title: 'BrainTagger Dashboard',
        role: 'UX/UI Design',
        year: '2022',
        blurb:"Designed a dashboard for the end users of Centivizer's web-based health game",
        skills: ['Prototyping', 'Data Visualization', 'Design System', 'Figma', 'Usability Testing'],
        projectUrl: '/work/playerdashboard',
        extLink: 'https://report.braintagger.com/',
        extLinkName: 'Implemented Website (members only)',
        extLinkCaption: '(under construction)'
    },
    {
        category:'ux',
        access: 'public',
        image: Images.barterby.thumbnail,
        alttext: 'Barterby key screens',
        projectColor: COLORS.BLUE,
        title: 'BARTERBY: Craft Supply Barter Website',
        role: 'UI and Product Design',
        year: '2022',
        blurb:'Created a design system and high-fidelity prototypes for mobile & kiosk platforms',
        skills: ['Prototyping', 'Design System', 'Figma', 'Technical Sketching', 'Ideation'],
        projectUrl: '/work/barterby'
    },
    {   
        category: 'ux',
        access: 'public',
        image: Images.dataVis.thumbnail,
        alttext: 'Mobile screen of a redesigned data visualization',
        projectColor: COLORS.ORANGE,
        title: 'Data Vis for Users with Blurred Vision',
        role: 'UX Research and UI Design',
        year: '2021',
        blurb: 'Redesigned data visualization pages of popular mobile health apps, backed with interview and usability testing',
        skills: ['Prototyping', 'Figma', 'Data Visualization', 'Interview', 'Testing', 'Qualitative Analysis'],
        projectUrl: '/work/blurredvision'
    },
  ];

export const WorkItem = props => {

//   const ThumbnailButton = styledMUI(IconButton)({
//       textAlign: "left",
//       transition: "transform 0.15s ease-in-out",
//       "&:hover":{
//         transform: "scale3d(1.1, 1.1, 1)",
//       },
//       "&:active":{
//         transform: "scale3d(1.1, 1.1, 1)",
//       }
//   })

  const Item = styledMUI(Paper)(({ color }) => ({
    backgroundColor: color,
    minWidth: "100%",
    minHeight: "100%",
    marginBottom: "2%",
  }));

  return(
    <Grid item sm={6} xs={12}>
        {props.projectItem.projectUrl &&

            <ThumbnailButton
                href={props.projectItem.projectUrl}
                target={props.projectItem.linkOnly? '_blank': false}
                sx={{ color: COLORS.OFF_WHITE, "&:hover": { color: props.projectItem.projectColor } }}
                >
                <Box>
                    <Item p={1} color={props.projectItem.projectColor}
                        display="flex" alignItems="center" justifyContent="center">
                            {(!bcrypt.compareSync(props.state.password, props.PASS) && props.projectItem.access==="private")?
                                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" p={2} sx={{ width: '100%'}}>
                                    <SvgIcon component={LockIcon} sx={{ fontSize: '100px', color: COLORS.BLACK }} />
                                    <Typography variant="body1" color={COLORS.BLACK}>
                                        Password-protected project
                                    </Typography>
                                </Box>
                                :
                                <Thumbnail src={props.projectItem.image} alt={props.projectItem.alttext} height="225px" borderRadius="10" style={{ cursor: 'pointer' }}/>
                            }
                        
                    </Item>
                    
                    <Typography variant="h5" color="inherit"
                    >
                        {props.projectItem.title}
                    </Typography>
                
                    <Typography variant="h6" color="inherit" sx={{ marginBottom: '7px' }}>
                        {props.projectItem.role}, {props.projectItem.year}
                    </Typography>
                    <Typography variant="subtitle1" color="primary">
                        {props.projectItem.blurb}
                    </Typography>
                    <br/>
                    {props.projectItem.extLink &&
                        <Box mb={3}>
                            <Typography variant="h6">
                                <a href={ props.projectItem.extLink } target="_blank" rel="noreferrer"
                                style={{ color: props.projectItem.projectColor }}>
                                    { props.projectItem.extLinkName }
                                </a>
                            </Typography>
                            {props.projectItem.extLinkCaption &&
                                <Typography variant="subtitle1" color="primary">
                                    { props.projectItem.extLinkCaption }
                                </Typography>
                            }
                        </Box>
                    }
                    
                        
                    
                    
                    <Box mt={1}>
                        {props.projectItem.skills && (props.projectItem.skills).map(skill=>{
                            return(
                                <Box display="inline-block" backgroundColor={COLORS.BLACK} px={1.5} py={0.5} mr={1.5} mb={1.5} sx={{ borderRadius:'5px', border: `1px solid ${props.projectItem.projectColor}` }}>
                                    <Typography variant="body1">
                                        <span style={{ fontSize: '16px', fontWeight: '700', color: props.projectItem.projectColor }}>
                                            {skill}
                                        </span>
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </ThumbnailButton>
        }
    </Grid>
  );
};
